import React, { useContext, useState } from 'react';
import { Dialog, Button, ButtonGroup, Intent } from '@blueprintjs/core';
import { AlertsContext } from '../../state/AlertsContext';
import { ModalContext } from '../../state/ModalContext';
import { useModalStyles } from '../../style/components/genericStyles';
import { DateTime } from 'luxon';
import { WindowContext } from '../../state/WindowContext';

interface Props {
  onRequestClose: () => void;
  modalIsOpen: boolean;
  logDate: string;
  associatedProjectId: string | number;
  deleteLog: (
    projectId: string | number,
    logDate: Date | string,
    logId: string
  ) => Promise<void>; // @TODO: fix typing of logDate
}

const DeleteLogModal: React.FunctionComponent<Props> = (props: Props) => {
  const alertsContext = useContext(AlertsContext);
  const modalContext = useContext(ModalContext);
  const windowContext = useContext(WindowContext);
  const modalStyles = useModalStyles();
  const { logToDelete } = modalContext!;

  const [isDeleting, setIsDeleting] = useState(false);

  const handleDeleteLog = async event => {
    event.preventDefault();
    setIsDeleting(true);
    try {
      const { logDate, associatedProjectId, deleteLog } = props;
      await deleteLog(associatedProjectId, logDate, logToDelete!.id);
      alertsContext!.addAlert('Deleted log entry', Intent.SUCCESS);
    } catch (err) {
      alertsContext!.addAlert('Error deleting log entry', Intent.DANGER);
    } finally {
      setIsDeleting(false);
      handleClose();
    }
  };

  const handleClose = () => {
    props.onRequestClose();
  };

  const readableDate = DateTime.fromISO(logToDelete!.date).toLocaleString(
    DateTime.DATETIME_MED_WITH_WEEKDAY
  );

  return (
    <Dialog
      isOpen={props.modalIsOpen}
      onClose={props.onRequestClose}
      title={logToDelete && `Delete log for ${readableDate}`}
      canOutsideClickClose={false}
      style={windowContext?.isMobile ? { width: '50vh' } : {}}
    >
      <div className={`bp4-dialog-body ${modalStyles.body}`}>
        <p>Are you sure you want to delete this log?</p>
      </div>
      <div className={`bp4-dialog-footer ${modalStyles.footer}`}>
        <ButtonGroup>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={handleDeleteLog}
            intent={Intent.DANGER}
            loading={isDeleting}
          >
            Do it!
          </Button>
        </ButtonGroup>
      </div>
    </Dialog>
  );
};

export { DeleteLogModal };
