import { IApiSession, ISession } from './Session';
import { IApiUnitOfLabour, IUnitOfLabour } from './UnitOfLabour';

export enum HabitCadenceUnit {
  MINUTE = 'MINUTE',
  HOUR = 'HOUR',
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  YEAR = 'YEAR',
}

export enum HabitType {
  HABIT = 'HABIT',
  UNIT = 'UNIT',
  SESSION = 'SESSION',
}

export interface IHabit {
  id: string;
  type: HabitType;
  name: string;
  startDate: string;
  endDate?: string;
  isActive: boolean;
  projectId?: string;
  cadence: {
    unit: HabitCadenceUnit;
    regularity: number;
  };
  completions: string[];
  labourTypeId?: string;
  unitTypeId?: string;
  unitTypeName?: string;
  unitsPerDay?: number;
  units?: IUnitOfLabour[];
  minutesPerDay?: number;
  sessions?: ISession[];
}

export interface IApiHabit {
  id: string;
  type: HabitType;
  name: string;
  start_date: string;
  end_date?: string;
  is_active: boolean;
  project_id?: string;
  cadence: {
    unit: HabitCadenceUnit;
    regularity: number;
  };
  completions: string[];
  labour_type_id?: string;
  unit_type_id?: string;
  unit_type_name?: string;
  units_per_day?: number;
  units?: IApiUnitOfLabour[];
  minutes_per_day?: number;
  sessions?: IApiSession[];
}

export interface IApiCreateHabitRequest {
  name: string;
  habit_type: HabitType;
  start_date?: string;
  end_date?: string;
  cadence?: {
    unit: HabitCadenceUnit;
    regularity: number;
  };
  labour_type_id?: string;
  unit_type_id?: string;
  completions?: string[];
  units_per_day?: number;
  minutes_per_day?: number;
  total_units_target?: number;
  units_completed?: number; // only applicable to unit habits associated with a task
  task_id?: string;
  is_active?: boolean;
}

export interface IApiUpdateHabitRequest {
  name: string;
  habit_type: HabitType;
  start_date?: string;
  end_date?: string;
  cadence?: {
    unit: HabitCadenceUnit;
    regularity: number;
  };
  labour_type_id?: string;
  unit_type_id?: string;
  completions?: string[];
  units_per_day?: number;
  minutes_per_day?: number;
  total_units_target?: number;
  units_completed?: number; // only applicable to unit habits associated with a task
  task_id?: string;
  is_active?: boolean;
}
