import { DateTime } from 'luxon';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  getSessionsForDate,
  getStartAndEndDatesForTrackingPeriod,
} from '../../common-src/productivity';
import { HabitType, IHabit } from '../../common-src/types/Habit';
import { ISession } from '../../common-src/types/Session';
import { getAreaChartData } from '../../func/chart-utils';
import { HabitsContext } from '../../state/HabitsContext';
import { ModalContext } from '../../state/ModalContext';
import { ProductivityContext } from '../../state/ProductivityContext';
import { SessionAndUnitContext } from '../../state/SessionAndUnitContext';
import { UserContext } from '../../state/UserContext';
import { WindowContext } from '../../state/WindowContext';
import { useLayoutStyles } from '../../style/components/layoutStyles';
import { useAllPageStyles } from '../../style/components/pageStyles';
import { useProjectPageStyles } from '../../style/pages/projectPageStyles';
import { AreaChartWithPanel } from '../common/charts/AreaChartWithPanel';
import { EditUnits } from '../ProjectPage/EditUnits';
import { ProductivityDateSelect } from '../ProjectPage/ProductivityDateSelect';
import { SessionList } from '../ProjectPage/SessionList';
import { HabitSummaryBox } from './HabitSummaryBox';
import { IHabitSummary } from '../../common-src/types/Reporting';
import { ComponentType, LoadingSkeleton } from '../common/LoadingSkeleton';

const HabitPage: React.FunctionComponent = () => {
  const habitsContext = useContext(HabitsContext);
  const productivityContext = useContext(ProductivityContext);
  const sessionAndUnitContext = useContext(SessionAndUnitContext);
  const userContext = useContext(UserContext);
  const modalContext = useContext(ModalContext);
  const windowContext = useContext(WindowContext);

  const allPageStyles = useAllPageStyles();
  const layoutStyles = useLayoutStyles();
  const projectPageStyles = useProjectPageStyles();

  const { habitId } = useParams<{ habitId: string }>();

  const [habit, setHabit] = useState<IHabit | undefined>(undefined);
  const [habitSummary, setHabitSummary] = useState<IHabitSummary | undefined>(
    undefined
  );
  const [startDate, setStartDate] = useState<DateTime>();
  const [endDate, setEndDate] = useState<DateTime>();
  const [editDate, setEditDate] = useState(DateTime.now());

  const { trackingPeriod, customTrackingPeriod } = userContext!;
  const { habits, isFetchingHabits } = habitsContext!;
  const { butlerAssessment, isFetchingButlerAssessment } = productivityContext!;
  const {
    createUnitOutputAssociatedWithDay,
    updateOrCreateUnitOutputAssociatedWithDay,
  } = sessionAndUnitContext!;
  const {
    openEditHabitModal: openEditGoalModal,
    openAddSessionModal,
    openEditSessionModal,
    closeEditSessionModal,
    openDeleteSessionModal,
    closeDeleteSessionModal,
  } = modalContext!;

  useEffect(() => {
    if (trackingPeriod) {
      const startAndEndDates = getStartAndEndDatesForTrackingPeriod(
        trackingPeriod,
        customTrackingPeriod
      );
      const start = startAndEndDates[0];
      const end = startAndEndDates[1];
      setStartDate(start);
      setEndDate(end);
    }
  }, [userContext?.trackingPeriod]);

  useEffect(() => {
    if (habits?.length && butlerAssessment?.habitsStatus?.summaries?.length) {
      const goal = habits.find(g => g.id === habitId);
      if (goal) {
        setHabit(goal);
      }
      const goalSummary = butlerAssessment?.habitsStatus.summaries.find(
        p => p.habitId === habitId
      );
      if (goalSummary) {
        setHabitSummary(goalSummary);
      }
    }
  }, [habitId, habits, butlerAssessment]);

  const handleStartDateChange = (newStartDate?: DateTime) => {
    setStartDate(newStartDate);
  };

  const handleEndDateChange = (newEndDate?: DateTime) => {
    setEndDate(newEndDate);
  };

  const handleEditDateChange = editDate => {
    setEditDate(editDate);
  };

  let chartWidth = 1200;

  if (windowContext?.currentWidth) {
    if (windowContext.isMobile) {
      chartWidth = windowContext.currentWidth - 100;
    } else if (windowContext.currentWidth > 1200) {
      chartWidth = windowContext.currentWidth - 400;
    } else if (windowContext.currentWidth > 1000) {
      chartWidth = windowContext.currentWidth - 350;
    } else if (windowContext.currentWidth > 600) {
      chartWidth = windowContext.currentWidth - 250;
    } else if (windowContext.currentWidth > 300) {
      chartWidth = windowContext.currentWidth - 200;
    }
  }
  const chartHeight = windowContext?.isMobile ? 150 : 400;

  const startOfToday = editDate.startOf('day');

  const sessionsOnEditDate = getSessionsForDate(
    habit?.sessions ?? [],
    editDate
  );

  const correspondingUnitObject = habit?.units?.find(u =>
    DateTime.fromISO(u.date).startOf('day').equals(startOfToday)
  );
  const mostUpToDateUnits = correspondingUnitObject
    ? correspondingUnitObject.units
    : 0;

  const handleOpenAddSessionModal = () => {
    openAddSessionModal({ associatedHabit: habit });
  };

  const handleOpenEditSessionModal = (session: ISession) => {
    openEditSessionModal(session);
  };

  const handleCloseEditSessionModal = () => {
    closeEditSessionModal();
  };

  const handleOpenDeleteSessionModal = (session: ISession) => {
    openDeleteSessionModal(session);
  };

  const handleCloseDeleteSessionModal = () => {
    closeDeleteSessionModal();
  };

  const areaChartData =
    startDate && endDate
      ? getAreaChartData(startDate, endDate, {
          sessions:
            habit?.type === HabitType.SESSION ? habit?.sessions : undefined,
          sessionsTarget:
            habit?.type === HabitType.SESSION ? habit?.minutesPerDay : 0,
          units: habit?.type === HabitType.UNIT ? habit?.units : undefined,
          unitsTarget: habit?.type === HabitType.UNIT ? habit?.unitsPerDay : 0,
          completions:
            habit?.type === HabitType.HABIT ? habit?.completions : undefined,
          completionDailyTarget: 0, // TODO!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
          name: habit?.unitTypeName,
        })
      : undefined;

  return (
    <div className={allPageStyles.genericPage}>
      <div>
        {isFetchingHabits || isFetchingButlerAssessment ? (
          <div>
            <LoadingSkeleton
              type={ComponentType.DashboardSummary}
              minHeight={364}
              maxHeight={364}
            />
          </div>
        ) : (
          <>
            {trackingPeriod && habit && habitSummary && (
              <HabitSummaryBox
                habit={habit}
                habitSummary={habitSummary}
                trackingPeriod={trackingPeriod}
                customTrackingPeriod={customTrackingPeriod}
                openEditGoalModal={openEditGoalModal}
              />
            )}
          </>
        )}

        {isFetchingHabits || isFetchingButlerAssessment ? (
          <div
            className={`${layoutStyles.grid_3_col} ${layoutStyles.spaced_col}`}
          >
            <LoadingSkeleton
              type={ComponentType.GoalSummary}
              minHeight={240}
              maxHeight={240}
            />
          </div>
        ) : (
          <>
            {startDate && endDate && areaChartData && (
              <AreaChartWithPanel
                areaChartData={areaChartData}
                handleEditDateChange={handleEditDateChange}
                startDate={startDate}
                endDate={endDate}
                width={chartWidth}
                height={chartHeight}
                handleStartDateChange={handleStartDateChange}
                handleEndDateChange={handleEndDateChange}
              />
            )}
            <div className={layoutStyles.grid_3_col}>
              <div
                style={{ flex: 1 }}
                className={projectPageStyles.fecundityDateSelect}
              >
                <ProductivityDateSelect
                  handleEditDateChange={handleEditDateChange}
                  initialDate={editDate}
                />
              </div>

              {habit?.type === HabitType.UNIT ? (
                <div
                  style={{ flex: 1 }}
                  className={projectPageStyles.editUnits}
                >
                  <div>
                    <EditUnits
                      date={editDate}
                      mostUpToDateUnits={mostUpToDateUnits}
                      unitOfLabour={correspondingUnitObject}
                      createUnitOutputAssociatedWithDay={
                        createUnitOutputAssociatedWithDay
                      }
                      updateOrCreateUnitOutputAssociatedWithDay={
                        updateOrCreateUnitOutputAssociatedWithDay
                      }
                      unitName={habit.unitTypeName ?? ''}
                      unitTypeId={habit.unitTypeId ?? ''}
                    />
                  </div>
                </div>
              ) : null}

              {habit?.type === HabitType.SESSION ? (
                <div
                  style={{ flex: 1 }}
                  className={projectPageStyles.sessionList}
                >
                  <div>
                    <SessionList
                      date={editDate}
                      openAddSessionModal={handleOpenAddSessionModal}
                      openEditSessionModal={handleOpenEditSessionModal}
                      closeEditSessionModal={handleCloseEditSessionModal}
                      openDeleteSessionModal={handleOpenDeleteSessionModal}
                      closeDeleteSessionModal={handleCloseDeleteSessionModal}
                      sessions={sessionsOnEditDate}
                    />
                  </div>
                </div>
              ) : null}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export { HabitPage };
