import React, { useContext } from 'react';
import { Button, ButtonGroup, Intent } from '@blueprintjs/core';
import { IProject } from '../../common-src/types/Project';
import { WhatNeedsToBeDone } from '../common/WhatNeedsToBeDone';
import { WindowContext } from '../../state/WindowContext';
import { useProjectPageStyles } from '../../style/pages/projectPageStyles';
import { TrackingPeriod } from '../../common-src/types/User';
import { ComponentType, LoadingSkeleton } from '../common/LoadingSkeleton';
import { DateTime } from 'luxon';
import { getProjectCompletionTooltipForProjectInPieChart } from '../../func/textChunks';
import { PieChartType, PieChart } from '../common/charts/PieChart';
import { renderTextChunks } from '../../func/ui-utils';
import { IProjectSummary } from '../../common-src/types/Reporting';
import { useGenericStyles } from '../../style/components/genericStyles';

interface IProps {
  project?: IProject;
  trackingPeriod?: TrackingPeriod;
  customTrackingPeriodInDays?: number;
  openEditProjectModal: () => void;
  openPomodoroModal: () => void;
  openDeleteProjectModal: () => void;
  openArchiveProjectModal: () => void;
  projectSummary?: IProjectSummary;
  isLoading: boolean;
}

const ProjectSummaryBox: React.FunctionComponent<IProps> = (props: IProps) => {
  const {
    project,
    trackingPeriod,
    customTrackingPeriodInDays,
    openEditProjectModal,
    openPomodoroModal,
    openDeleteProjectModal,
    openArchiveProjectModal,
    projectSummary,
    isLoading,
  } = props;

  const windowContext = useContext(WindowContext);

  const projectPageStyles = useProjectPageStyles();
  const genericStyles = useGenericStyles();

  const getStartDateText = (startDate: DateTime): string => {
    const today = DateTime.now().startOf('day');
    const startDateAtStartOfDay = startDate.startOf('day');
    if (startDateAtStartOfDay < today) {
      return `Started on ${startDateAtStartOfDay.toLocaleString(
        DateTime.DATE_MED
      )}`;
    } else if (startDateAtStartOfDay > today) {
      return `Starts on ${startDateAtStartOfDay.toLocaleString(
        DateTime.DATE_MED
      )}`;
    } else {
      return 'Starts today';
    }
  };

  const getEndDateText = (endDate: DateTime): string => {
    const today = DateTime.now().startOf('day');
    const endDateAtStartOfDay = endDate.startOf('day');
    if (endDateAtStartOfDay < today) {
      return `Ended on ${endDateAtStartOfDay.toLocaleString(
        DateTime.DATE_MED
      )}`;
    } else if (endDateAtStartOfDay > today) {
      return `Ends on ${endDateAtStartOfDay.toLocaleString(DateTime.DATE_MED)}`;
    } else {
      return 'Ends today';
    }
  };

  if (isLoading) {
    return <LoadingSkeleton type={ComponentType.ProjectSummary} />;
  }

  if (!project) {
    return null;
  }

  const startDateText = project.startDate
    ? getStartDateText(project.startDate)
    : '';

  const endDateText = project.endDate ? getEndDateText(project.endDate) : '';

  const shouldShowProjectTaskCompletion = Boolean(
    project.tasks.length > 0 &&
      trackingPeriod &&
      typeof project.taskCompletion !== 'undefined' &&
      typeof project.projectCompletionScore !== 'undefined'
  );

  const projectCompletionTooltip =
    typeof project.projectCompletionScore !== 'undefined' &&
    typeof project.taskCompletion !== 'undefined'
      ? getProjectCompletionTooltipForProjectInPieChart(
          project.projectCompletionScore,
          project.taskCompletion,
          project.tasks.filter(task => task.done).length,
          project.tasks.length,
          !!project.endDate ? true : false,
          project.expectedTaskCompletion,
          project.expectedNumberOfCompleteTasks
        )
      : undefined;

  const centreText =
    typeof project.taskCompletion !== 'undefined'
      ? `${Math.round(project.taskCompletion * 100)}%`
      : undefined;

  return (
    <div className={genericStyles.genericBox} id="project-summary-box">
      <div className="gp-flex-grid">
        <div className="gp-flex-grid-col-1">
          <div
            style={{ textAlign: 'left', marginBottom: 10, maxWidth: '100vh' }}
          >
            <h1 className={projectPageStyles.projectHeader}>{project.name}</h1>
            <ButtonGroup>
              <>
                {windowContext?.isMobile ? (
                  <>
                    <Button
                      icon="edit"
                      onClick={openEditProjectModal}
                      style={{ minWidth: 50 }}
                      minimal
                    />
                    <Button
                      icon="time"
                      onClick={openPomodoroModal}
                      style={{ minWidth: 50 }}
                      minimal
                    />
                    <Button
                      icon="archive"
                      onClick={openArchiveProjectModal}
                      style={{ minWidth: 50 }}
                      minimal
                    />
                    <Button
                      icon="trash"
                      onClick={openDeleteProjectModal}
                      intent={Intent.DANGER}
                      style={{ minWidth: 50 }}
                      minimal
                    />
                  </>
                ) : (
                  <>
                    <Button icon="edit" onClick={openEditProjectModal} minimal>
                      Edit
                    </Button>
                    <Button icon="time" onClick={openPomodoroModal} minimal>
                      Start session
                    </Button>
                    <Button
                      icon="archive"
                      onClick={openArchiveProjectModal}
                      minimal
                    >
                      {project.archived ? 'Unarchive' : 'Archive'}
                    </Button>
                    <Button
                      icon="trash"
                      onClick={openDeleteProjectModal}
                      intent={Intent.DANGER}
                      minimal
                    >
                      Delete project
                    </Button>
                  </>
                )}
              </>
            </ButtonGroup>
          </div>
          {trackingPeriod ? (
            <WhatNeedsToBeDone
              project={project}
              trackingPeriod={trackingPeriod}
              customTrackingPeriodInDays={customTrackingPeriodInDays}
              type="PROJECT"
              projectSummary={projectSummary}
            />
          ) : null}
          <div style={{ textAlign: 'left', marginBottom: 10 }}>
            <>
              {startDateText ? (
                <div>
                  <p>{startDateText}</p>
                </div>
              ) : null}
              {endDateText ? (
                <div>
                  <p>{endDateText}</p>
                </div>
              ) : null}
            </>
          </div>
        </div>
        <div className="gp-flex-grid-col-1 gp-flex-grid">
          {shouldShowProjectTaskCompletion ? (
            <>
              <div className="gp-flex-grid-col-1">
                <PieChart
                  tooltip={projectCompletionTooltip}
                  type={PieChartType.TARGET_AND_ACTUAL}
                  chartMetricsForPrimaryPie={[
                    {
                      id: project.id,
                      label: 'Complete tasks',
                      value: project.taskCompletion!, // exclam is safe because of shouldShowProjectTaskCompletion
                    },
                    {
                      id: `${project.id}_anti`,
                      label: 'Incomplete tasks',
                      value: 1 - project.taskCompletion!, // exclam is safe because of shouldShowProjectTaskCompletion
                      colour: 'rgb(255 255 255 / .1)',
                    },
                  ]}
                  chartMetricsForSecondaryPie={[
                    {
                      id: project.id,
                      label: 'Project completion',
                      value: project.expectedTaskCompletion!, // exclam is safe because of shouldShowProjectTaskCompletion
                    },
                    {
                      id: `${project.id}_anti`,
                      label: 'Project incomplete',
                      value: 1 - project.expectedTaskCompletion!, // exclam is safe because of shouldShowProjectTaskCompletion
                      colour: 'rgb(255 255 255 / .1)',
                    },
                  ]}
                  trackingPeriod={trackingPeriod!}
                  customTrackingPeriodInDays={customTrackingPeriodInDays}
                  width={300}
                  height={200}
                  margin={
                    windowContext?.isMobile
                      ? { top: 0, right: 0, bottom: 0, left: 0 }
                      : { top: 20, right: 20, bottom: 20, left: 20 }
                  }
                  animate={true}
                  centreText={centreText}
                />
              </div>
              <div className="gp-flex-grid-col-1" style={{ textAlign: 'left' }}>
                {renderTextChunks(projectCompletionTooltip!)}
              </div>
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export { ProjectSummaryBox };
