import { createUseStyles } from 'react-jss';
import { chosenTheme } from '../styleHooks';

export const useDatetimePickerStyles = createUseStyles({
  datetimePicker: {
    width: '100%',
    padding: 11,
    color: chosenTheme.mainFontColour,
    fontSize: 14,
    height: 40,
  },
});
