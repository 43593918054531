import React from 'react';
import Pie, { ProvidedProps, PieArcDatum } from '@visx/shape/lib/shapes/Pie';
import { Group } from '@visx/group';
import { Text } from '@visx/text';
import { animated, useTransition, interpolate } from '@react-spring/web';
import { TrackingPeriod } from '../../../common-src/types/User';
import { ITextChunk } from '../../../common-src/types/UiTypes';
import {
  useTooltip,
  useTooltipInPortal,
  Tooltip,
  TooltipWithBounds,
} from '@visx/tooltip';
import { renderTextChunks } from '../../../func/ui-utils';
import { usePieChartStyles } from '../../../style/components/pieChartStyles';

const defaultMargin = { top: 20, right: 20, bottom: 20, left: 20 };

export enum PieChartType {
  SINGLE_METRIC = 'SINGLE_METRIC',
  TARGET_AND_ACTUAL = 'TARGET_AND_ACTUAL',
}

/**
 * All the data representing one slice of the pie chart. The `value`s of all the slices should add up to 1 in a single pie chart.
 */
export interface IChartMetric {
  id: string;
  label: string;
  value: number;
  tooltip?: ITextChunk[];
  colour?: string;
}

interface IPieChartTooltipData {
  visible: boolean;
}

export type IProps = {
  type: PieChartType;
  chartMetricsForPrimaryPie: IChartMetric[];
  chartMetricsForSecondaryPie?: IChartMetric[];
  trackingPeriod: TrackingPeriod;
  customTrackingPeriodInDays?: number;
  width?: number;
  height?: number;
  margin?: typeof defaultMargin;
  animate?: boolean;
  centreText?: string;
  tooltip?: ITextChunk[];
};

const PieChart: React.FunctionComponent<IProps> = (props: IProps) => {
  const {
    type,
    chartMetricsForPrimaryPie,
    chartMetricsForSecondaryPie,
    trackingPeriod,
    customTrackingPeriodInDays,
    width = 400,
    height = 400,
    margin = defaultMargin,
    animate = true,
    centreText,
    tooltip,
  } = props;

  const { containerRef, containerBounds } = useTooltipInPortal({
    scroll: true,
    detectBounds: true,
  });

  const pieChartStyles = usePieChartStyles();

  const {
    tooltipData,
    tooltipLeft = 0,
    tooltipTop = 0,
    showTooltip,
    hideTooltip,
  } = useTooltip<IPieChartTooltipData>();

  if (width < 10) return null;

  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;
  const outerPieRadius = Math.min(innerWidth, innerHeight) / 2;
  const innerPieRadius = outerPieRadius / 2;
  const centerY = innerHeight / 2;
  const centerX = innerWidth / 2;
  const outerDoughnutThickness = 35;
  const innerDoughnutThickness = 20;

  const value = (d: IChartMetric) => d.value;

  const getPieChunkColour = (chartMetric: IChartMetric): string => {
    if (chartMetric.value < 0.2) {
      return 'rgb(255 255 255 / .2)';
    } else if (chartMetric.value < 0.4) {
      return 'rgb(255 255 255 / .25)';
    } else if (chartMetric.value < 0.6) {
      return 'rgb(255 255 255 / .3)';
    } else if (chartMetric.value < 0.8) {
      return 'rgb(255 255 255 / .5)';
    } else {
      return 'rgb(255 255 255 / .7)';
    }
  };

  const getKey = (arc: PieArcDatum<IChartMetric>): string => {
    return `${arc.data.label}`;
  };

  const getBackgroundGradientForSingleMetric = (
    chartMetric: IChartMetric
  ): JSX.Element => {
    const gradientId = `pie-chart-gradient-${chartMetric.id}`;
    const value = Math.max(0, Math.min(1, chartMetric.value)); // Ensure value is between 0 and 1
    const red = Math.round(255 * (1 - value));
    const blue = Math.round(255 * value);
    const colour = `rgb(${red}, 0, ${blue})`;

    return (
      <svg width="0" height="0">
        <defs>
          <linearGradient id={gradientId} x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="0%" style={{ stopColor: colour, stopOpacity: 1 }} />
            <stop
              offset="100%"
              style={{ stopColor: colour, stopOpacity: 0.5 }}
            />
          </linearGradient>
        </defs>
      </svg>
    );
  };

  const handlePointerMove = (event: React.PointerEvent<HTMLDivElement>) => {
    const containerX =
      ('clientX' in event ? event.clientX : 0) - containerBounds.left;
    const containerY =
      ('clientY' in event ? event.clientY : 0) - containerBounds.top;
    if (containerX < 0 || containerY < 0) {
      hideTooltip();
    } else {
      showTooltip({
        tooltipData: { visible: true },
        tooltipLeft: containerX,
        tooltipTop: containerY,
      });
    }
  };

  if (type === PieChartType.SINGLE_METRIC) {
    const backgroundGradient = getBackgroundGradientForSingleMetric(
      chartMetricsForPrimaryPie[0]
    );
    const gradientId = `pie-chart-gradient-${chartMetricsForPrimaryPie[0].id}`;
    const showTooltip = tooltipData?.visible && !!tooltip;
    return (
      <div className={pieChartStyles.pieChartContainer}>
        <div
          style={{ position: 'relative', width, height }}
          onPointerMove={handlePointerMove}
          onPointerEnter={handlePointerMove}
          onPointerLeave={hideTooltip}
          ref={containerRef}
        >
          {showTooltip ? (
            <TooltipWithBounds
              key={Math.random()}
              left={tooltipLeft}
              top={tooltipTop}
              applyPositionStyle={true}
              // style={tooltipStyles}
            >
              {/* {tooltipData}
          <br />
          <br />
          <strong>left</strong> {tooltipLeft?.toFixed(0)}px&nbsp;&nbsp;
          <strong>top</strong> {tooltipTop?.toFixed(0)}px */}
              {renderTextChunks(tooltip)}
            </TooltipWithBounds>
          ) : null}
          <svg width={width} height={height}>
            {backgroundGradient}
            {/* <LinearGradient
              x1={0}
              x2={500}
              y1={0}
              y2={500}
              id={gradientId}
              from="#a18cd1"
              to="#fbc2eb"
            /> */}
            <rect
              rx={14}
              width={width}
              height={height}
              fill={`url('#${gradientId}')`}
            />
            <Group top={centerY + margin.top} left={centerX + margin.left}>
              <Pie
                data={chartMetricsForPrimaryPie}
                pieValue={value}
                outerRadius={outerPieRadius}
                innerRadius={outerPieRadius - outerDoughnutThickness}
                cornerRadius={3}
                padAngle={0.005}
              >
                {pie => (
                  <AnimatedPie<IChartMetric>
                    {...pie}
                    animate={animate}
                    getKey={getKey}
                    getColour={arc => getPieChunkColour(arc.data)}
                    label=""
                  />
                )}
              </Pie>
            </Group>
            <Text
              x={width / 2}
              y={height / 2}
              width={width}
              verticalAnchor="middle"
              textAnchor="middle"
              fontSize={72}
              fill="white"
            >
              {centreText}
            </Text>
          </svg>
        </div>
      </div>
    );
  } else if (type === PieChartType.TARGET_AND_ACTUAL) {
    const backgroundGradient = getBackgroundGradientForSingleMetric(
      chartMetricsForPrimaryPie[0]
    );
    const gradientId = `pie-chart-gradient-${chartMetricsForPrimaryPie[0].id}`;
    const showTooltip = tooltipData?.visible && !!tooltip;
    return (
      <div className={pieChartStyles.pieChartContainer}>
        <div
          style={{ position: 'relative', width, height }}
          onPointerMove={handlePointerMove}
          onPointerEnter={handlePointerMove}
          onPointerLeave={hideTooltip}
          ref={containerRef}
        >
          {showTooltip ? (
            <TooltipWithBounds
              key={Math.random()}
              left={tooltipLeft}
              top={tooltipTop}
              applyPositionStyle={true}
            >
              {renderTextChunks(tooltip)}
            </TooltipWithBounds>
          ) : null}
          <svg width={width} height={height}>
            {backgroundGradient}
            <rect
              rx={14}
              width={width}
              height={height}
              fill={`url('#${gradientId}')`}
            />
            <Group top={centerY + margin.top} left={centerX + margin.left}>
              <Pie
                data={chartMetricsForPrimaryPie}
                pieValue={value}
                outerRadius={outerPieRadius}
                innerRadius={outerPieRadius - outerDoughnutThickness}
                cornerRadius={3}
                padAngle={0.005}
              >
                {pie => (
                  <AnimatedPie<IChartMetric>
                    {...pie}
                    animate={animate}
                    getKey={getKey}
                    getColour={arc => getPieChunkColour(arc.data)}
                    label=""
                  />
                )}
              </Pie>
              <Pie
                data={chartMetricsForSecondaryPie}
                pieValue={value}
                outerRadius={innerPieRadius}
                innerRadius={innerPieRadius - innerDoughnutThickness}
                cornerRadius={3}
                padAngle={0.005}
              >
                {pie => (
                  <AnimatedPie<IChartMetric>
                    {...pie}
                    animate={animate}
                    getKey={getKey}
                    getColour={arc => getPieChunkColour(arc.data)}
                    label=""
                  />
                )}
              </Pie>
            </Group>
            <Text
              x={width / 2}
              y={height / 2}
              width={width}
              verticalAnchor="middle"
              textAnchor="middle"
              fontSize={72}
              fill="white"
            >
              {centreText}
            </Text>
          </svg>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

// react-spring transition definitions
type AnimatedStyles = { startAngle: number; endAngle: number; opacity: number };

const fromLeaveTransition = ({ endAngle }: PieArcDatum<any>) => ({
  // enter from 360° if end angle is > 180°
  startAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
  endAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
  opacity: 0,
});
const enterUpdateTransition = ({ startAngle, endAngle }: PieArcDatum<any>) => ({
  startAngle,
  endAngle,
  opacity: 1,
});

type AnimatedPieProps<Datum> = ProvidedProps<Datum> & {
  animate?: boolean;
  getKey: (d: PieArcDatum<Datum>) => string;
  getColour: (d: PieArcDatum<Datum>) => string;
  onClickDatum?: (d: PieArcDatum<Datum>) => void;
  delay?: number;
  label?: string;
};

function AnimatedPie<Datum>({
  animate,
  arcs,
  path,
  getKey,
  getColour,
  onClickDatum,
  label,
}: AnimatedPieProps<Datum>) {
  const transitions = useTransition<PieArcDatum<Datum>, AnimatedStyles>(arcs, {
    from: animate ? fromLeaveTransition : enterUpdateTransition,
    enter: enterUpdateTransition,
    update: enterUpdateTransition,
    leave: animate ? fromLeaveTransition : enterUpdateTransition,
    keys: getKey,
  });

  return transitions((props, arc, { key }) => {
    const [centroidX, centroidY] = path.centroid(arc);
    const hasSpaceForLabel = arc.endAngle - arc.startAngle >= 0.1;
    const chartMetric = arc.data as IChartMetric;
    const fillColour = chartMetric?.colour
      ? chartMetric?.colour
      : getColour(arc);

    return (
      <g key={key}>
        <animated.path
          // compute interpolated path d attribute from intermediate angle values
          d={interpolate(
            [props.startAngle, props.endAngle],
            (startAngle, endAngle) =>
              path({
                ...arc,
                startAngle,
                endAngle,
              })
          )}
          fill={fillColour}
          onClick={onClickDatum ? () => onClickDatum(arc) : undefined}
          onTouchStart={onClickDatum ? () => onClickDatum(arc) : undefined}
        />
        {hasSpaceForLabel && !!label && (
          <animated.g style={{ opacity: props.opacity }}>
            <text
              fill="white"
              x={centroidX}
              y={centroidY}
              dy=".33em"
              fontSize={9}
              textAnchor="middle"
              pointerEvents="none"
            >
              {label}
            </text>
          </animated.g>
        )}
      </g>
    );
  });
}

export { PieChart };
