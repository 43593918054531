import { HabitCadenceUnit } from './Habit';
import { IApiSession, ISession } from './Session';
import { ISubtask } from './Subtask';

export enum TaskType {
  STANDARD = 'STANDARD',
  HABIT = 'HABIT',
}

export interface IHabitConsistencyData {
  consistency: number;
  targetCadence: {
    unit: HabitCadenceUnit;
    regularity: number;
  };
  actualCadence: {
    unit: HabitCadenceUnit;
    regularity: number;
  };
}

export interface ITask {
  // general
  type: TaskType;
  id: string;
  projectId?: string;
  deleted?: boolean;
  name: string;

  // completion
  done: boolean;
  completionDateTime?: string | null;

  // dates and reminders
  dueDate?: string | null;
  startDate?: string | null;
  reminderTime?: string | null;

  // notes
  notes?: string;

  // phases (TODO: is this needed?)
  phase?: string;

  // checklists and subtasks
  containsChecklist: boolean;
  subtasks?: ISubtask[];
  isTrackingUnits: boolean;
  unitName?: string | null;
  unitTarget?: number | null;
  unitsCompleted?: number | null;

  // tags
  tagIds?: string[] | null;

  // habits
  habit?: {
    cadence: {
      unit: HabitCadenceUnit;
      regularity: number;
    };
    completions: string[];
    startDate: string;
    endDate?: string;
  } | null;

  // estimates
  estimatedMinutes?: number;

  // sessions
  sessions?: ISession[];
  totalMinutesTracked?: number;
}

export function isTask(item: any): item is ITask {
  return typeof item.id === 'string' && typeof item.name === 'string';
}

export interface IApiTask {
  id: string;
  project_id: string;
  deleted?: boolean;
  name: string;
  done: boolean;
  completion_datetime?: string | null;
  due_date?: string | null;
  start_date?: string | null;
  reminder_time?: string | null;
  notes: string | null;
  contains_checklist: boolean;
  subtasks?: ISubtask[];
  subtask_ids?: string[];
  is_tracking_units: boolean;
  unit_name?: string | null;
  unit_target?: number | null;
  units_completed?: number | null;
  tag_ids?: string[];
  estimated_minutes?: number;
  sessions?: IApiSession[];
  total_minutes_tracked?: number;
}

export interface IApiCreateTaskRequest {
  name: string;
  project_id?: string;
}

export interface IApiUpdateTaskRequest {
  project_id?: string;
  deleted?: boolean;
  name: string;

  // completion
  done: boolean;
  completion_datetime?: string | null;

  // dates and reminders
  due_date?: string | null;
  start_date?: string | null;
  reminder_time?: string | null;

  // notes
  notes?: string;

  // checklists and subtasks
  is_tracking_units: boolean;
  subtasks?: ISubtask[];
  unit_name?: string;
  unit_target?: number;
  units_completed?: number;

  // tags
  tag_ids?: string[] | null;

  // estimates
  estimated_minutes?: number;
}

export interface IApiGetTasksResponse {
  tasks: IApiTask[];
}
