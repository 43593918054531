import React, { useContext, useState, useEffect } from 'react';
import { UserContext } from '../../state/UserContext';
import { ProjectsListContext } from '../../state/ProjectsListContext';
import { TasksContext } from '../../state/TasksContext';
import { TagsContext } from '../../state/TagsContext';
import { ITask } from '../../common-src/types/Task';
import { EditTaskModal } from '../modals/EditTaskModal';
import { ITag } from '../../common-src/types/Tag';
import { HabitType, IHabit } from '../../common-src/types/Habit';
import { useLocation } from 'react-router-dom';
import { AddPhaseModal } from '../modals/AddPhaseModal';
import { useAllPageStyles } from '../../style/components/pageStyles';
import { useLayoutStyles } from '../../style/components/layoutStyles';
import { ToDoList } from '../common/ToDoList/ToDoList';
import { handleGenericStringIdsDragDrop } from '../../func/task-ui-utils';
import { HabitsContext } from '../../state/HabitsContext';
import { ProductivityContext } from '../../state/ProductivityContext';
import { ComponentType, LoadingSkeleton } from '../common/LoadingSkeleton';
import { DashboardSummary } from './DashboardSummary';
import { IStatusOfAllHabits } from '../../common-src/types/Reporting';
import { ModalContext } from '../../state/ModalContext';
import { DashboardOnboarding } from '../common/onboarding/DashboardOnboarding';

const Dashboard: React.FunctionComponent = () => {
  const habitsContext = useContext(HabitsContext);
  const projectsContext = useContext(ProjectsListContext);
  const userContext = useContext(UserContext);
  const tasksContext = useContext(TasksContext);
  const tagsContext = useContext(TagsContext);
  const productivityContext = useContext(ProductivityContext);
  const modalContext = useContext(ModalContext);

  const allPageStyles = useAllPageStyles();
  const layoutStyles = useLayoutStyles();

  const location = useLocation();

  const [todoListTaskIds, setTodoListTaskIds] = useState<string[] | null>(null);

  const [habitGoals, setHabitGoals] = useState<IHabit[] | undefined>(undefined);
  const [statusOfAllHabitsToShow, setStatusOfAllHabitsToShow] = useState<
    IStatusOfAllHabits | undefined
  >(undefined);

  const [editTaskModalIsOpen, setEditTaskModalIsOpen] = useState(false);
  const [taskBeingEdited, setTaskBeingEdited] = useState<ITask | null>(null);
  const [addPhaseModalIsOpen, setAddPhaseModalIsOpen] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    if (userContext?.todoListTaskIds) {
      setTodoListTaskIds(userContext.todoListTaskIds);
    }
  }, [userContext?.todoListTaskIds]);

  useEffect(() => {
    if (habitsContext?.habits) {
      setHabitGoals(
        habitsContext?.habits.filter(goal => goal.type === HabitType.HABIT)
      );
    }
  }, [habitsContext?.habits]);

  useEffect(() => {
    if (habitsContext?.statusOfAllHabits) {
      setStatusOfAllHabitsToShow(habitsContext?.statusOfAllHabits);
    }
  }, [habitsContext?.statusOfAllHabits]);

  if (
    !projectsContext ||
    !userContext ||
    !tasksContext ||
    !tagsContext ||
    !habitsContext ||
    !productivityContext ||
    !modalContext
  ) {
    return null;
  }

  const { habits: goals } = habitsContext;
  const { projects } = projectsContext;
  const unarchivedProjects = projects?.filter(project => !project.archived);
  const { saveTask, isFetchingTasks } = tasksContext;
  const { butlerAssessment, isFetchingButlerAssessment } = productivityContext;
  const { isFetchingUser, firstName, addPhase, updateTodoListTaskIds } =
    userContext;
  const { tags } = tagsContext;
  const { openAddSessionModal } = modalContext;

  const tagsArr: ITag[] = tags ?? [];

  const openEditTaskModal = (task: ITask) => {
    setEditTaskModalIsOpen(true);
    setTaskBeingEdited(task);
  };

  const closeEditTaskModal = () => {
    setEditTaskModalIsOpen(false);
    setTaskBeingEdited(null);
  };

  const closeAddPhaseModal = () => {
    setAddPhaseModalIsOpen(false);
  };

  const onDragEndTodoList = (result: any) => {
    if (todoListTaskIds) {
      const newTodoListTaskIds = handleGenericStringIdsDragDrop(
        result,
        todoListTaskIds
      );
      if (newTodoListTaskIds) {
        setTodoListTaskIds(newTodoListTaskIds);
        updateTodoListTaskIds(newTodoListTaskIds);
      }
    }
  };

  const overallProductivityString = 'Your summary';

  const sessionAndUnitGoals = goals?.filter(
    goal => goal.type === HabitType.SESSION || goal.type === HabitType.UNIT
  );
  const shouldShowOverallProductivityChart = Boolean(
    sessionAndUnitGoals && sessionAndUnitGoals?.length > 0
  );

  return (
    <div className={allPageStyles.genericPage}>
      {isFetchingUser ? (
        <LoadingSkeleton type={ComponentType.PageTitle} />
      ) : (
        <div>
          <h1>{firstName}’s Dashboard</h1>
        </div>
      )}

      <div
        className={`${layoutStyles.grid_2_thirds_col} ${layoutStyles.spaced_col}`}
      >
        {isFetchingButlerAssessment || !butlerAssessment ? (
          <LoadingSkeleton
            type={ComponentType.DashboardSummary}
            minHeight={440}
          />
        ) : (
          <>
            {shouldShowOverallProductivityChart ? (
              <DashboardSummary
                butlerAssessment={butlerAssessment}
                overallProductivityString={overallProductivityString}
                statusOfAllHabits={butlerAssessment.habitsStatus}
              />
            ) : null}
          </>
        )}
      </div>
      <div>
        {isFetchingTasks ? (
          <div className={layoutStyles.col_cell}>
            <LoadingSkeleton type={ComponentType.TodoList} maxWidth={700} />
          </div>
        ) : (
          <>
            {todoListTaskIds && (
              <ToDoList
                todoListTaskIds={todoListTaskIds}
                openEditTaskModal={openEditTaskModal}
                onDragEnd={onDragEndTodoList}
                openAddSessionModal={openAddSessionModal}
              />
            )}
          </>
        )}
      </div>
      <DashboardOnboarding />
      <EditTaskModal
        modalIsOpen={editTaskModalIsOpen}
        onRequestClose={closeEditTaskModal}
        task={taskBeingEdited!}
        saveTask={saveTask!}
        tags={tagsArr}
        simplifiedProjectList={unarchivedProjects ?? []}
      />
      <AddPhaseModal
        level="user"
        modalIsOpen={addPhaseModalIsOpen}
        onRequestClose={closeAddPhaseModal}
        addUserPhase={addPhase}
      />
    </div>
  );
};

export { Dashboard };
