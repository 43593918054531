import React, { useState, useContext } from 'react';
import { DateTime } from 'luxon';
import { Dialog, Button, ButtonGroup, Intent } from '@blueprintjs/core';
import { ISimplifiedProject } from '../../common-src/types/Project';
import { AlertsContext } from '../../state/AlertsContext';
import { MarkdownTextEditor } from '../common/MarkdownTextEditor';
import { LogContext } from '../../state/LogContext';
import { useModalStyles } from '../../style/components/genericStyles';
import {
  DatetimeFormat,
  DatetimePicker,
  DatetimePickerType,
} from '../common/DatetimePicker/DatetimePicker';
import { WindowContext } from '../../state/WindowContext';

interface Props {
  projects: ISimplifiedProject[];
  onRequestClose: () => void;
  modalIsOpen: boolean;
}

const AddLogModalWithinLogPage: React.FunctionComponent<Props> = (
  props: Props
) => {
  const alertsContext = useContext(AlertsContext);
  const logContext = useContext(LogContext);
  const windowContext = useContext(WindowContext);
  const modalStyles = useModalStyles();

  const { projects } = props;

  const [projectId, setProjectId] = useState<string>(projects?.[0]?.id || '');
  const [logDate, setLogDate] = useState<DateTime | undefined>(DateTime.now());
  const [logText, setLogText] = useState('');
  const [focused, setFocused] = useState<boolean>(false);

  const [isSaving, setIsSaving] = useState<boolean>(false);

  const handleProjectChange = event => {
    setProjectId(event.target.value);
  };

  const handleLogDateChange = (date?: DateTime) => {
    setLogDate(date);
  };

  const handleLogTextChange = value => {
    setLogText(value);
  };

  const handleAddLog = async event => {
    event.preventDefault();
    setIsSaving(true);

    try {
      if (projectId !== '' && logDate && logText !== '') {
        await logContext!.addLog(
          projectId.toString(),
          logDate.toISO(),
          logText
        );
        alertsContext!.addAlert('Successfully added log entry', Intent.SUCCESS);
      } else {
        alertsContext!.addAlert('Error adding log entry', Intent.DANGER);
      }
    } catch (error) {
      alertsContext!.addAlert('Error adding log entry', Intent.DANGER);
    } finally {
      setIsSaving(false);
      handleClose();
    }
  };

  const handleClose = () => {
    setProjectId('');
    setLogDate(undefined);
    setLogText('');
    props.onRequestClose();
  };

  if (!projects) return null;

  const projectsToShow: JSX.Element[] = [];

  projects.forEach(project => {
    projectsToShow.push(
      <option value={project.id} key={project.id}>
        {project.name}
      </option>
    );
  });

  return (
    <Dialog
      isOpen={props.modalIsOpen}
      onClose={props.onRequestClose}
      title="Your log"
      canOutsideClickClose={false}
      style={windowContext?.isMobile ? { width: '50vh' } : {}}
    >
      <div className={`bp4-dialog-body ${modalStyles.body}`}>
        {projects && projects.length > 0 && (
          <label className="bp4-label">
            Select project to add a log to:
            <div className="bp4-select">
              <select value={projectId} onChange={handleProjectChange}>
                {projectsToShow}
              </select>
            </div>
          </label>
        )}
        <div>
          <DatetimePicker
            id="user_log_date_picker"
            type={DatetimePickerType.datetime}
            label="Choose date:"
            placeholder="Enter the log date here"
            datetime={logDate}
            onDatetimeChange={handleLogDateChange}
            isFocused={focused}
            datetimeFormat={DatetimeFormat.DATETIME}
            canClear={false}
            isOutsideRange={_day => false}
          />
        </div>
        <label className="bp4-label">
          Enter your log here:
          <MarkdownTextEditor
            initialValue={logText}
            onTextChange={handleLogTextChange}
          />
        </label>
      </div>
      <div className={`bp4-dialog-footer ${modalStyles.footer}`}>
        <ButtonGroup>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={handleAddLog}
            intent={Intent.PRIMARY}
            loading={isSaving}
          >
            Add
          </Button>
        </ButtonGroup>
      </div>
    </Dialog>
  );
};

export { AddLogModalWithinLogPage };
