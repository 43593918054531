import React, { useContext, useState } from 'react';
import { Dialog, ButtonGroup, Button, Intent } from '@blueprintjs/core';
import { TagsContext } from '../../state/TagsContext';
import { AlertsContext } from '../../state/AlertsContext';
import { useModalStyles } from '../../style/components/genericStyles';
import { WindowContext } from '../../state/WindowContext';

interface Props {
  modalIsOpen: boolean;
  onRequestClose: () => any;
}

const AddTagModal: React.FunctionComponent<Props> = (props: Props) => {
  const tagsContext = useContext(TagsContext);
  const alertsContext = useContext(AlertsContext);
  const windowContext = useContext(WindowContext);
  const modalStyles = useModalStyles();

  const [tagName, setTagName] = useState('');
  // const [colour, setColour] = useState('grey');
  const [isSaving, setIsSaving] = useState(false);

  const handleAddTag = async event => {
    event.preventDefault();
    setIsSaving(true);

    if (tagName !== '') {
      try {
        await tagsContext!.addTag(tagName);
        alertsContext!.addAlert(`Added new tag "${tagName}"`, Intent.SUCCESS);
      } catch (err) {
        alertsContext!.addAlert(`Error adding tag "${tagName}"`, Intent.DANGER);
      } finally {
        setIsSaving(false);
        handleClose();
      }
    }
  };

  const handleTagNameChange = event => {
    setTagName(event.target.value);
  };

  const handleClose = () => {
    setTagName('');
    props.onRequestClose();
  };

  return (
    <Dialog
      isOpen={props.modalIsOpen}
      onClose={props.onRequestClose}
      title="Add new tag"
      canOutsideClickClose={false}
      style={windowContext?.isMobile ? { width: '50vh' } : {}}
    >
      <div className={`bp4-dialog-body ${modalStyles.body}`}>
        <label className="bp4-label">
          Enter the name of your new tag:
          <input
            className="bp4-input bp4-fill"
            type="text"
            onChange={handleTagNameChange}
          />
        </label>
      </div>
      <div className={`bp4-dialog-footer ${modalStyles.footer}`}>
        <ButtonGroup>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={handleAddTag}
            intent={Intent.PRIMARY}
            loading={isSaving}
          >
            Add
          </Button>
        </ButtonGroup>
      </div>
    </Dialog>
  );
};

export { AddTagModal };
