import React, { ReactNode, useEffect, useState, useContext } from 'react';
import { AuthenticationContext } from './AuthenticationContext';
import {
  IApiCreateSessionRequest,
  ISession,
  IApiUpdateSessionRequest,
} from '../common-src/types/Session';
import {
  IApiCreateUnitOfLabourRequest,
  IApiUpdateUnitOfLabourRequest,
  IUnitOfLabourWithType,
} from '../common-src/types/UnitOfLabour';
import {
  apiCreateSession,
  apiCreateUnitOfLabour,
  apiDeleteSession,
  apiGetSessions,
  apiGetUnits,
  apiUpdateSession,
  apiUpdateUnitOfLabour,
} from '../client/apiClient';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { UserContext } from './UserContext';

interface ISessionAndUnitContext {
  sessions?: ISession[];
  units?: IUnitOfLabourWithType[];
  addSession: (
    workDurationMinutes: number,
    timeOfCompletion: string,
    projectId?: string,
    taskId?: string,
    goalId?: string,
    labourTypeId?: string,
    notes?: string,
    tagIds?: string[]
  ) => Promise<void>;
  updateSession: (
    sessionId: string,
    updateSessionRequest: IApiUpdateSessionRequest
  ) => Promise<void>;
  deleteSession: (sessionId: string) => Promise<void>;
  createUnitOutputAssociatedWithDay: (
    createUnitRequest: IApiCreateUnitOfLabourRequest
  ) => Promise<void>;
  updateOrCreateUnitOutputAssociatedWithDay: (
    unitId: string,
    updateUnitRequest?: IApiUpdateUnitOfLabourRequest
  ) => Promise<void>;
}

export const SessionAndUnitContext =
  React.createContext<ISessionAndUnitContext | null>(null);

interface Props {
  children: ReactNode;
}

const SessionAndUnitContextProvider: React.FunctionComponent<Props> = (
  props: Props
) => {
  const authenticationContext = useContext(AuthenticationContext);
  const userContext = useContext(UserContext);

  const queryClient = useQueryClient();

  const {
    isLoading: isFetchingSessions,
    isError: isFetchSessionsError,
    data: sessions,
    error: fetchSessionsError,
  } = useQuery({
    queryKey: ['getSessions'],
    queryFn: async () => {
      try {
        if (
          authenticationContext &&
          authenticationContext.isUserAuthenticated
        ) {
          const accessToken = await authenticationContext.getToken();
          const sessions = await apiGetSessions(accessToken);
          return sessions;
        }
      } catch (err) {
        throw err;
      }
    },
    enabled: !!authenticationContext?.isUserAuthenticated,
  });

  const {
    isLoading: isFetchingUnits,
    isError: isFetchUnitsError,
    data: units,
    error: fetchUnitsError,
  } = useQuery({
    queryKey: ['getUnits'],
    queryFn: async () => {
      try {
        if (
          authenticationContext &&
          authenticationContext.isUserAuthenticated
        ) {
          const accessToken = await authenticationContext.getToken();
          const units = await apiGetUnits(accessToken);
          return units;
        }
      } catch (err) {
        throw err;
      }
    },
    enabled: !!authenticationContext?.isUserAuthenticated,
  });

  const addSession = async (
    workDurationMinutes: number,
    timeOfCompletion: string,
    projectId?: string,
    taskId?: string,
    goalId?: string,
    labourTypeId?: string,
    notes?: string,
    tagIds?: string[]
  ) => {
    try {
      if (authenticationContext && authenticationContext.isUserAuthenticated) {
        const accessToken = await authenticationContext.getToken();
        const createSessionRequest: IApiCreateSessionRequest = {
          date: timeOfCompletion,
          project_id: projectId,
          task_id: taskId,
          goal_id: goalId,
          labour_type_id: labourTypeId,
          deleted: false,
          minutes: +workDurationMinutes,
          notes,
          tag_ids: tagIds,
        };
        const newSession = await apiCreateSession(
          accessToken,
          createSessionRequest
        );
        queryClient.invalidateQueries({ queryKey: ['getSessions'] });
        queryClient.invalidateQueries({ queryKey: ['getHabits'] });
        queryClient.invalidateQueries({
          queryKey: ['getRawProductivityForGoals'],
        });
        queryClient.invalidateQueries({ queryKey: ['getLogs'] });
        queryClient.invalidateQueries({ queryKey: ['getTasks'] });
        queryClient.invalidateQueries({
          queryKey: ['getProjectById', projectId],
        });
        queryClient.invalidateQueries({
          queryKey: ['getButlerAssessment', projectId],
        });
        if (userContext) {
          const { trackingPeriod, customTrackingPeriod } = userContext;
          queryClient.invalidateQueries({
            queryKey: [
              'getRawProductivityForGoals',
              trackingPeriod,
              customTrackingPeriod,
            ],
          });
        }
      }
    } catch (err) {
      throw err;
    }
  };

  const updateSession = async (
    sessionId: string,
    updateSessionRequest: IApiUpdateSessionRequest
  ) => {
    try {
      if (authenticationContext && authenticationContext.isUserAuthenticated) {
        const accessToken = await authenticationContext.getToken();
        const updatedSession = await apiUpdateSession(
          accessToken,
          sessionId,
          updateSessionRequest
        );
        queryClient.invalidateQueries({ queryKey: ['getSessions'] });
        queryClient.invalidateQueries({ queryKey: ['getHabits'] });
        queryClient.invalidateQueries({
          queryKey: ['getRawProductivityForGoals'],
        });
        queryClient.invalidateQueries({ queryKey: ['getLogs'] });
        if (userContext) {
          const { trackingPeriod, customTrackingPeriod } = userContext;
          queryClient.invalidateQueries({
            queryKey: [
              'getRawProductivityForGoals',
              trackingPeriod,
              customTrackingPeriod,
            ],
          });
          queryClient.invalidateQueries({ queryKey: ['getTasks'] });
        }
      }
    } catch (err) {
      throw err;
    }
  };

  const deleteSession = async (sessionId: string) => {
    try {
      if (authenticationContext && authenticationContext.isUserAuthenticated) {
        const accessToken = await authenticationContext.getToken();
        await apiDeleteSession(accessToken, sessionId);
        queryClient.invalidateQueries({ queryKey: ['getSessions'] });
        queryClient.invalidateQueries({ queryKey: ['getHabits'] });
        queryClient.invalidateQueries({
          queryKey: ['getRawProductivityForGoals'],
        });
        queryClient.invalidateQueries({ queryKey: ['getLogs'] });
        queryClient.invalidateQueries({ queryKey: ['getTasks'] });
        if (userContext) {
          const { trackingPeriod, customTrackingPeriod } = userContext;
          queryClient.invalidateQueries({
            queryKey: [
              'getRawProductivityForGoals',
              trackingPeriod,
              customTrackingPeriod,
            ],
          });
        }
      }
    } catch (err) {
      throw err;
    }
  };

  const createUnitOutputAssociatedWithDay = async (
    createUnitRequest: IApiCreateUnitOfLabourRequest
  ) => {
    try {
      if (authenticationContext && authenticationContext.isUserAuthenticated) {
        const accessToken = await authenticationContext.getToken();
        const newUnit = await apiCreateUnitOfLabour(
          accessToken,
          createUnitRequest
        );
        queryClient.invalidateQueries({ queryKey: ['getUnits'] });
        queryClient.invalidateQueries({ queryKey: ['getHabits'] });
        queryClient.invalidateQueries({
          queryKey: ['getRawProductivityForGoals'],
        });
        queryClient.invalidateQueries({ queryKey: ['getLogs'] });
        if (userContext) {
          const { trackingPeriod, customTrackingPeriod } = userContext;
          queryClient.invalidateQueries({
            queryKey: [
              'getRawProductivityForGoals',
              trackingPeriod,
              customTrackingPeriod,
            ],
          });
        }
      }
    } catch (err) {
      throw err;
    }
  };

  const updateOrCreateUnitOutputAssociatedWithDay = async (
    unitId: string,
    updateUnitRequest?: IApiUpdateUnitOfLabourRequest
  ) => {
    try {
      if (
        unitId &&
        updateUnitRequest &&
        authenticationContext &&
        authenticationContext.isUserAuthenticated
      ) {
        const accessToken = await authenticationContext.getToken();
        const updatedUnit = await apiUpdateUnitOfLabour(
          accessToken,
          unitId,
          updateUnitRequest
        );
        queryClient.invalidateQueries({ queryKey: ['getUnits'] });
        queryClient.invalidateQueries({ queryKey: ['getHabits'] });
        queryClient.invalidateQueries({
          queryKey: ['getRawProductivityForGoals'],
        });
        queryClient.invalidateQueries({ queryKey: ['getLogs'] });
        if (userContext) {
          const { trackingPeriod, customTrackingPeriod } = userContext;
          queryClient.invalidateQueries({
            queryKey: [
              'getRawProductivityForGoals',
              trackingPeriod,
              customTrackingPeriod,
            ],
          });
        }
      }
    } catch (err) {
      throw err;
    }
  };

  const getSessionAndUnitContext = () => {
    return {
      sessions,
      units,
      addSession,
      updateSession,
      deleteSession,
      createUnitOutputAssociatedWithDay,
      updateOrCreateUnitOutputAssociatedWithDay,
    };
  };

  const sessionAndUnitContext = getSessionAndUnitContext();

  return (
    <SessionAndUnitContext.Provider value={sessionAndUnitContext}>
      {props.children}
    </SessionAndUnitContext.Provider>
  );
};

export { SessionAndUnitContextProvider };
export type { ISessionAndUnitContext };
