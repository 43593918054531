import _ from 'lodash';
import { IUnitOfLabour } from './types/UnitOfLabour';
import { ISession } from './types/Session';
import { ISubtask } from './types/Subtask';
import { ITask } from './types/Task';
import { getTrackingType } from '../func/whatNeedsToBeDoneForTask';
import { DateTime } from 'luxon';
import { TrackingPeriod } from './types/User';
import { BEGINNING_OF_TIME } from '../func/constants';
import { ITextChunk } from './types/UiTypes';
import { makeMinutesReadable } from '../func/readability-utils';
import { IHabitSummary, IStatusOfAllHabits } from './types/Reporting';
import { HabitType } from './types/Habit';
import { DateAndValueTuple } from './types/ChartTypes';

export function getSessionsForDate(
  sessions: ISession[],
  date: DateTime
): ISession[] {
  return sessions
    .filter(
      session =>
        DateTime.fromISO(session.date) >
          DateTime.fromISO(date.startOf('day').toISO()) &&
        DateTime.fromISO(session.date) <
          DateTime.fromISO(date.endOf('day').toISO())
    )
    .sort((a, b) => {
      const aDate = new Date(a.date);
      const bDate = new Date(b.date);
      return aDate.getTime() - bDate.getTime();
    });
}

export function getNumberOfDaysInRange(
  startDate: DateTime,
  endDate: DateTime
): number {
  return Math.ceil(endDate.diff(startDate, 'days').days);
}

export function getOverTextForTrackingPeriod(
  trackingPeriod: TrackingPeriod,
  customTrackingPeriod?: number
): string {
  let overText = '';
  switch (trackingPeriod) {
    case TrackingPeriod.TODAY:
      overText = 'today';
      break;
    case TrackingPeriod.LAST_7_DAYS:
      overText = 'over the last 7 days';
      break;
    case TrackingPeriod.THIS_WEEK:
      overText = 'this week';
      break;
    case TrackingPeriod.THIS_MONTH:
      overText = 'this month';
      break;
    case TrackingPeriod.THIS_QUARTER:
      overText = 'this quarter';
      break;
    case TrackingPeriod.THIS_YEAR:
      overText = 'this year';
      break;
    case TrackingPeriod.ALL_TIME:
      overText = 'since records began';
      break;
    case TrackingPeriod.CUSTOM:
      if (customTrackingPeriod) {
        overText = `over the last ${customTrackingPeriod} days`;
      } else {
        overText = '';
      }
      break;
    default:
      overText = '';
      break;
  }

  return overText;
}

// @TODO: add unit tests
export function getProductivityTooltipForHabitInPieChart(
  habitSummary: IHabitSummary,
  trackingPeriod: TrackingPeriod,
  customTrackingPeriod?: number
): ITextChunk[] {
  const { totalTime, avgDailyTime, totalUnits, avgDailyUnits, unitName } =
    habitSummary;

  const overText = getOverTextForTrackingPeriod(
    trackingPeriod,
    customTrackingPeriod
  );

  let tooltip: ITextChunk[] = [];

  if (habitSummary.habitType === HabitType.UNIT) {
    tooltip.push({ text: `Average output ${overText}:`, strong: true });

    const avgUnitOutput = avgDailyUnits || 0;
    tooltip.push({
      text: `* ${unitName}: ${avgUnitOutput.toFixed(2)} per day`,
      beginOnNewLine: true,
    });

    tooltip.push({
      text: `Total output ${overText}:`,
      beginOnNewLine: true,
      strong: true,
      precededByHorizontalLine: true,
    });
    tooltip.push({
      text: `* ${unitName}: ${(totalUnits ?? 0).toFixed(0)}`,
      beginOnNewLine: true,
    });
  } else if (habitSummary.habitType === HabitType.SESSION) {
    tooltip.push({
      text: `Average time ${overText}:`,
      beginOnNewLine: true,
      strong: true,
    });
    tooltip.push({
      text: `${makeMinutesReadable(avgDailyTime ?? 0)} per day`,
      beginOnNewLine: true,
    });
    tooltip.push({
      text: `Total time ${overText}:`,
      beginOnNewLine: true,
      strong: true,
      precededByHorizontalLine: true,
    });
    tooltip.push({
      text: `${makeMinutesReadable(totalTime ?? 0)}.`,
      beginOnNewLine: true,
    });
  } else if (habitSummary.habitType === HabitType.HABIT) {
    tooltip.push({
      text: `Consistency ${overText}:`,
      strong: true,
      beginOnNewLine: true,
    });
    tooltip.push({
      text: `You're about ${(habitSummary.habitScore * 100).toFixed(
        0
      )}% consistent in this period.`,
      beginOnNewLine: true,
    });
  }

  return tooltip;
}

// @TODO: add unit tests
export function getAverageProductivityTooltipForMultipleHabitsInPieChart(
  statusOfAllHabits: IStatusOfAllHabits,
  trackingPeriod: TrackingPeriod,
  customTrackingPeriod?: number
): ITextChunk[] {
  const { totalTime, avgDailyTime, summaries } = statusOfAllHabits;

  const overText = getOverTextForTrackingPeriod(
    trackingPeriod,
    customTrackingPeriod
  );

  let tooltip: ITextChunk[] = [];

  tooltip.push({ text: `Average output ${overText}:`, strong: true });
  summaries.forEach(summary => {
    if (summary.habitType === HabitType.UNIT) {
      const avgUnitOutput = summary.avgDailyUnits || 0;
      tooltip.push({
        text: `* ${summary.unitName}: ${avgUnitOutput.toFixed(2)} per day`,
        beginOnNewLine: true,
      });
    }
  });
  tooltip.push({
    text: `Total output ${overText}:`,
    beginOnNewLine: true,
    strong: true,
    precededByHorizontalLine: true,
  });
  summaries.forEach(summary => {
    if (summary.habitType === HabitType.UNIT) {
      const totalOutput = summary.totalUnits || 0;
      tooltip.push({
        text: `* ${summary.unitName}: ${totalOutput.toFixed(0)}`,
        beginOnNewLine: true,
      });
    }
  });
  tooltip.push({
    text: `Average time ${overText}:`,
    beginOnNewLine: true,
    precededByHorizontalLine: true,
    strong: true,
  });
  if (avgDailyTime) {
    tooltip.push({
      text: `${makeMinutesReadable(avgDailyTime)} per day`,
      beginOnNewLine: true,
    });
  }
  tooltip.push({
    text: `Total time ${overText}:`,
    beginOnNewLine: true,
    strong: true,
    precededByHorizontalLine: true,
  });
  if (totalTime) {
    tooltip.push({
      text: `${makeMinutesReadable(totalTime)}.`,
      beginOnNewLine: true,
    });
  }

  return tooltip;
}

// @TODO: add unit tests
export function getDatesInRange(start: DateTime, end: DateTime): DateTime[] {
  const startDateTime = start.startOf('day');
  const endDateTime = end.plus({ days: 1 }).startOf('day');

  let allDatesInRange: DateTime[] = [];
  const diff = Math.ceil(endDateTime.diff(startDateTime, 'days').days);
  if (diff && diff > 0) {
    for (let i = 0; i < diff; i++) {
      const dateToAdd = startDateTime.plus({ days: i });
      allDatesInRange.push(dateToAdd);
    }
  }

  return allDatesInRange;
}

export function getCompletionDateTuplesInRange(
  completions: string[],
  datesInRange: DateTime[]
): DateAndValueTuple[] {
  const completionDateTuples: DateAndValueTuple[] = datesInRange.map(date => {
    const completionsOnThisDate = completions.filter(completion =>
      DateTime.fromISO(completion).startOf('day').equals(date)
    );
    return [date, completionsOnThisDate.length];
  });

  return completionDateTuples;
}

export function getDateSessionTuplesInRange(
  sessionsInRange: ISession[],
  datesInRange: DateTime[]
): DateAndValueTuple[] {
  const rawProductivityDateTuples = sessionsInRange.map(session => ({
    rawProductivity: session.minutes,
    date: DateTime.fromISO(session.date),
  }));

  if (!rawProductivityDateTuples) {
    return [];
  }

  const dateSessionTuplesInRange: [DateTime, number][] = datesInRange.map(
    date => {
      let productivityOnThisDate;
      const sessionsOnThisDate = rawProductivityDateTuples.filter(
        prodDateTuple =>
          prodDateTuple.date.startOf('day').equals(date.startOf('day'))
      );
      if (sessionsOnThisDate.length > 0) {
        productivityOnThisDate = sessionsOnThisDate.reduce(
          (
            accumulator: number,
            currentSession: { rawProductivity: number; date: DateTime }
          ) => {
            return accumulator + +currentSession.rawProductivity;
          },
          0
        );
      } else productivityOnThisDate = 0;

      return [date, productivityOnThisDate];
    }
  );

  return dateSessionTuplesInRange;
}

export function getDateUnitTuplesInRange(
  unitsInRange: IUnitOfLabour[],
  datesInRange: DateTime[]
): DateAndValueTuple[] {
  const rawProductivityDateTuples = unitsInRange.map(unit => ({
    rawProductivity: unit.units,
    date: DateTime.fromISO(unit.date),
  }));

  if (!rawProductivityDateTuples) {
    return [];
  }

  const dateUnitTuplesInRange: [DateTime, number][] = datesInRange
    .slice()
    .map(date => {
      let productivityOnThisDate: number;
      const unitsOnThisDate = rawProductivityDateTuples.find(prodDateTuple =>
        prodDateTuple.date.startOf('day').equals(date.startOf('day'))
      );
      productivityOnThisDate = unitsOnThisDate
        ? unitsOnThisDate.rawProductivity
        : 0;

      return [date, productivityOnThisDate];
    });

  return dateUnitTuplesInRange;
}

export function getTaskCompletionForMultipleProjects() {}

export function getTaskCompletionForProject() {}

/**
 * Returns the start and end dates for the provided tracking period as a tuple. Note that the end date is always today.
 * @param trackingPeriod
 * @param customTrackingPeriodInDays - only used if `trackingPeriod` is `CUSTOM`
 */
export function getStartAndEndDatesForTrackingPeriod(
  trackingPeriod: TrackingPeriod,
  customTrackingPeriodInDays?: number
): [DateTime, DateTime] {
  let startDate: DateTime;
  const endDate = DateTime.now().endOf('day');

  switch (trackingPeriod) {
    case TrackingPeriod.CUSTOM:
      startDate = DateTime.now()
        .startOf('day')
        .minus({ days: customTrackingPeriodInDays });
      break;
    case TrackingPeriod.TODAY:
      startDate = DateTime.now().startOf('day');
      break;
    case TrackingPeriod.LAST_7_DAYS:
      startDate = DateTime.now().minus({ days: 6 }).startOf('day');
      break;
    case TrackingPeriod.THIS_WEEK:
      startDate = DateTime.now().startOf('week');
      break;
    case TrackingPeriod.THIS_MONTH:
      startDate = DateTime.now().startOf('month');
      break;
    case TrackingPeriod.THIS_QUARTER:
      startDate = DateTime.now().startOf('quarter');
      break;
    case TrackingPeriod.THIS_YEAR:
      startDate = DateTime.now().startOf('year');
      break;
    case TrackingPeriod.ALL_TIME:
      startDate = BEGINNING_OF_TIME;
      break;
    default:
      startDate = DateTime.now().startOf('day').minus({ days: 6 });
      break;
  }

  return [startDate, endDate];
}

/**
 * Gets the checklist completion as a proportion of 1.
 * @param subtasks
 */
function getChecklistCompletion(subtasks: ISubtask[]): number {
  if (subtasks && subtasks.length) {
    const totalTasks = subtasks.length;
    const totalCompleteTasks = subtasks.filter(sub => sub.done).length;
    return totalCompleteTasks / totalTasks;
  } else {
    return 0;
  }
}

/**
 * Gets the target completion for the present moment as a proportion of 1.
 * @param startDate
 * @param dueDate
 * @TODO: add unit tests
 */
function getTargetCompletionForPresentMoment(
  startDate: DateTime,
  dueDate: DateTime
): number {
  const endOfDayToday = DateTime.now().endOf('day');
  let amountUserShouldHaveDone;
  const diffBetweenStartDateAndEndOfDayToday = getNumberOfDaysInRange(
    startDate,
    endOfDayToday
  );
  const diffBetweenStartDateAndDueDate = getNumberOfDaysInRange(
    startDate,
    dueDate.startOf('day')
  );
  if (endOfDayToday < startDate) {
    amountUserShouldHaveDone = 0;
  } else if (endOfDayToday > dueDate) {
    amountUserShouldHaveDone = 1;
  } else if (
    diffBetweenStartDateAndEndOfDayToday &&
    diffBetweenStartDateAndEndOfDayToday > 0 &&
    diffBetweenStartDateAndDueDate &&
    diffBetweenStartDateAndDueDate > 0
  ) {
    const daysBetweenStartDateAndEndOfDayToday =
      diffBetweenStartDateAndEndOfDayToday + 1;
    const daysInRange = diffBetweenStartDateAndDueDate + 1;
    amountUserShouldHaveDone =
      daysBetweenStartDateAndEndOfDayToday / daysInRange;
  }

  return amountUserShouldHaveDone;
}

/**
 * Gets the completion of the task's units as a proportion of 1.
 * @param unitsCompleted
 * @param unitTarget
 */
function getTaskUnitCompletion(
  unitsCompleted: number,
  unitTarget: number
): number {
  if (!unitsCompleted || !unitTarget) {
    return 0;
  }
  if (unitTarget === 0) {
    return unitsCompleted;
  }
  return unitsCompleted / unitTarget;
}

/**
 * Gets the overall completion for a task, as a proportion of 1.
 * @param task
 * @param trackingType
 */
function getCompletionForTrackingType(
  task: ITask,
  trackingType: 'CHECKLIST_ONLY' | 'UNITS_ONLY' | 'BOTH' | 'NONE'
): number {
  let completion: number;

  if (trackingType === 'CHECKLIST_ONLY') {
    completion = getChecklistCompletion(task.subtasks!);
  } else if (trackingType === 'UNITS_ONLY') {
    completion = getTaskUnitCompletion(task.unitsCompleted!, task.unitTarget!);
  } else if (trackingType === 'BOTH') {
    completion =
      (getChecklistCompletion(task.subtasks!) +
        getTaskUnitCompletion(task.unitsCompleted!, task.unitTarget!)) /
      2;
  } else {
    completion = 0;
  }

  return completion;
}

function getOverallTaskCompletionAndTarget(tasks: ITask[]): {
  overallTaskCompletion: number;
  overallTargetCompletion: number;
} {
  const trackedTasks: ITask[] = tasks.filter(
    (task: ITask) => task!.dueDate && task!.startDate && !task.done
  );
  const taskCompletions: number[] = [];
  const taskTargets: number[] = [];

  trackedTasks.forEach((task: ITask) => {
    const trackingType = getTrackingType(task);
    const taskCompletion = getCompletionForTrackingType(task, trackingType);
    taskCompletions.push(taskCompletion);

    const targetCompletion = getTargetCompletionForPresentMoment(
      DateTime.fromISO(task.startDate!),
      DateTime.fromISO(task.dueDate!)
    );
    taskTargets.push(targetCompletion);
  });

  const overallTaskCompletion =
    taskCompletions.reduce((previousVal, currentVal) => {
      return previousVal + currentVal;
    }) / taskCompletions.length;

  const overallTargetCompletion =
    taskTargets.reduce((previousVal, currentVal) => {
      return previousVal + currentVal;
    }) / taskTargets.length;

  return { overallTaskCompletion, overallTargetCompletion };
}

const getOverallProductivityString = (
  trackingPeriod: TrackingPeriod,
  customTrackingPeriodInDays?: number
): string => {
  let trackingString = '';
  switch (trackingPeriod) {
    case TrackingPeriod.CUSTOM:
      if (customTrackingPeriodInDays) {
        trackingString = `Your productivity over the last ${customTrackingPeriodInDays} days`;
      } else {
        trackingString = '';
      }
      break;
    case TrackingPeriod.TODAY:
      trackingString = "Today's productivity";
      break;
    case TrackingPeriod.LAST_7_DAYS:
      trackingString = 'Your productivity over the last 7 days';
      break;
    case TrackingPeriod.THIS_WEEK:
      trackingString = 'Your productivity this week';
      break;
    case TrackingPeriod.THIS_MONTH:
      trackingString = 'Your productivity this month';
      break;
    case TrackingPeriod.THIS_QUARTER:
      trackingString = 'Your productivity this quarter';
      break;
    case TrackingPeriod.THIS_YEAR:
      trackingString = 'Your productivity this year';
      break;
    case TrackingPeriod.ALL_TIME:
      trackingString = 'Your productivity for all time';
      break;
    default:
      trackingString = '';
      break;
  }
  return trackingString;
};

export {
  getChecklistCompletion,
  getTargetCompletionForPresentMoment,
  getTaskUnitCompletion,
  getCompletionForTrackingType,
  getOverallTaskCompletionAndTarget,
  getOverallProductivityString,
};
