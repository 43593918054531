import { DateTime } from 'luxon';
import { IApiHabit, IHabit } from '../common-src/types/Habit';
import { IApiLabourType, ILabourType } from '../common-src/types/LabourType';
import { IApiLog, ILog } from '../common-src/types/Log';
import {
  IApiPhase,
  IApiProject,
  IPhase,
  IProject,
  IApiUpdatePhaseRequest,
  IApiPhaseInRequest,
  IApiSimplifiedProject,
  ISimplifiedProject,
  ISimplifiedPhase,
  IApiSimplifiedPhase,
} from '../common-src/types/Project';
import {
  IApiButlerAssessment,
  IApiHabitSummary,
  IApiHabitsAdvice,
  IApiProjectSummary,
  IApiProjectsAdvice,
  IApiStatusOfAllHabits,
  IApiStatusOfAllProjects,
  IApiStatusOfAllTasks,
  IApiTaskSummary,
  IApiTasksAdvice,
  IButlerAssessment,
  IHabitSummary,
  IHabitsAdvice,
  IProjectSummary,
  IProjectsAdvice,
  IStatusOfAllHabits,
  IStatusOfAllProjects,
  IStatusOfAllTasks,
  ITaskSummary,
  ITasksAdvice,
} from '../common-src/types/Reporting';
import { IApiSession, ISession } from '../common-src/types/Session';
import { IApiTag, ITag } from '../common-src/types/Tag';
import { IApiTask, ITask, TaskType } from '../common-src/types/Task';
import {
  IApiUnitOfLabour,
  IApiUnitOfLabourWithType,
  IApiUnitType,
  IUnitOfLabour,
  IUnitOfLabourWithType,
  IUnitType,
} from '../common-src/types/UnitOfLabour';
import { IApiUser, IUser } from '../common-src/types/User';
import {
  IApiSubscription,
  ISubscription,
} from '../common-src/types/Subscription';

export function mapApiProjectToProject(apiProject: IApiProject): IProject {
  return {
    id: apiProject.id,
    name: apiProject.name,
    notes: apiProject.notes,
    deleted: apiProject.deleted,
    archived: apiProject.archived,
    phases: apiProject.phases?.filter(phase => !!phase).map(mapApiPhaseToPhase),
    tasks: apiProject.tasks?.filter(task => !!task).map(mapApiTaskToTask),
    standardPomodoroTime: apiProject.standard_session_time,
    standardPomodoroBreak: apiProject.standard_session_break,
    targetMinutes: apiProject.minutes_per_day,
    customTarget: apiProject.units_per_day,
    trackTime: apiProject.is_tracking_sessions,
    trackUnits: apiProject.is_tracking_units,
    unitName: apiProject.unit_name,
    unitTypeId: apiProject.unit_type_id,
    goals: apiProject.goals?.filter(goal => !!goal).map(mapApiHabitToHabit),
    sessions: apiProject.sessions
      ?.filter(session => !!session)
      .map(mapApiSessionToSession),
    startDate: apiProject.start_date
      ? DateTime.fromISO(apiProject.start_date)
      : undefined,
    endDate: apiProject.end_date
      ? DateTime.fromISO(apiProject.end_date)
      : undefined,
    taskCompletion: apiProject.task_completion,
    expectedTaskCompletion: apiProject.expected_task_completion,
    expectedNumberOfCompleteTasks: apiProject.expected_number_of_complete_tasks,
    projectCompletionScore: apiProject.project_completion_score,
  };
}

export function mapApiSimplifiedProjectToSimplifiedProject(
  project: IApiSimplifiedProject
): ISimplifiedProject {
  return {
    id: project.id,
    name: project.name,
    deleted: project.deleted,
    archived: project.archived,
    remainingTaskCount: project.remaining_task_count,
    phases: project.phases
      ?.filter(phase => !!phase)
      .map(mapApiSimplifiedPhaseToSimplifiedPhase),
  };
}

export function mapApiTaskToTask(apiTask: IApiTask): ITask {
  return {
    type: TaskType.STANDARD,
    id: apiTask.id,
    projectId: apiTask.project_id,
    deleted: apiTask.deleted,
    name: apiTask.name,
    done: apiTask.done,
    completionDateTime: apiTask.completion_datetime,
    dueDate: apiTask.due_date,
    startDate: apiTask.start_date,
    reminderTime: apiTask.reminder_time,
    notes: apiTask.notes ?? '',
    containsChecklist: apiTask.contains_checklist,
    subtasks: apiTask.subtasks,
    isTrackingUnits: apiTask.is_tracking_units,
    unitName: apiTask.unit_name,
    unitTarget: apiTask.unit_target,
    unitsCompleted: apiTask.units_completed,
    tagIds: apiTask.tag_ids,
    estimatedMinutes: apiTask.estimated_minutes,
    sessions: apiTask.sessions?.map(mapApiSessionToSession),
    totalMinutesTracked: apiTask.total_minutes_tracked,
  };
}

export function mapApiPhaseToPhase(apiPhase: IApiPhase): IPhase {
  return {
    id: apiPhase.id,
    name: apiPhase.name,
    tasks: apiPhase.tasks?.filter(task => !!task).map(mapApiTaskToTask) ?? [],
    estimatedTimeToCompleteAllTasks:
      apiPhase.estimated_time_to_complete_all_tasks,
  };
}

export function mapApiSimplifiedPhaseToSimplifiedPhase(
  apiPhase: IApiSimplifiedPhase
): ISimplifiedPhase {
  return {
    id: apiPhase.id,
    name: apiPhase.name,
    orderedTaskIds: apiPhase.ordered_task_ids,
  };
}

export function mapPhaseToUpdatePhaseRequest(
  phase: IPhase
): IApiUpdatePhaseRequest {
  return {
    id: phase.id,
    name: phase.name,
    ordered_task_ids: phase.tasks.map(task => task.id),
  };
}

export function mapPhaseToPhaseInRequest(phase: IPhase): IApiPhaseInRequest {
  return {
    id: phase.id,
    name: phase.name,
    ordered_task_ids: phase.tasks.map(task => task.id),
  };
}

export function mapApiUnitOfLabourToUnitOfLabour(
  apiUnitOfLabour: IApiUnitOfLabour
): IUnitOfLabour {
  return {
    id: apiUnitOfLabour.id,
    date: apiUnitOfLabour.date,
    units: apiUnitOfLabour.units,
    projectId: apiUnitOfLabour.project_id ?? '',
  };
}

export function mapApiUnitOfLabourWithTypeToUnitOfLabourWithType(
  unit: IApiUnitOfLabourWithType
): IUnitOfLabourWithType {
  return {
    id: unit.id,
    date: unit.date,
    units: unit.units,
    unitTypeId: unit.unit_type_id,
    unitTypeName: unit.unit_type_name,
    projectId: unit.project_id,
  };
}

export function mapApiUnitTypeToUnitType(apiUnitType: IApiUnitType): IUnitType {
  return {
    id: apiUnitType.id,
    name: apiUnitType.name,
  };
}

export function mapApiLogToLog(apiLog: IApiLog): ILog {
  return {
    id: apiLog.id,
    date: apiLog.datetime,
    note: apiLog.note,
    type: apiLog.type,
    projectId: apiLog.project_id,
  };
}

export function mapApiSessionToSession(apiSession: IApiSession): ISession {
  return {
    id: apiSession.id,
    date: apiSession.datetime,
    deleted: apiSession.deleted,
    minutes: apiSession.minutes,
    notes: apiSession.notes,
    projectId: apiSession.project_id,
    taskId: apiSession.task_id,
    goalId: apiSession.goal_id,
    labourTypeId: apiSession.labour_type_id,
    tagIds: apiSession.tag_ids,
  };
}

export function mapApiHabitToHabit(apiHabit: IApiHabit): IHabit {
  return {
    id: apiHabit.id,
    type: apiHabit.type,
    name: apiHabit.name,
    startDate: apiHabit.start_date,
    endDate: apiHabit.end_date,
    isActive: apiHabit.is_active,
    projectId: apiHabit.project_id,
    cadence: apiHabit.cadence,
    completions: apiHabit.completions,
    labourTypeId: apiHabit.labour_type_id,
    unitTypeId: apiHabit.unit_type_id,
    unitTypeName: apiHabit.unit_type_name,
    unitsPerDay: apiHabit.units_per_day,
    units: apiHabit.units?.map(mapApiUnitOfLabourToUnitOfLabour),
    minutesPerDay: apiHabit.minutes_per_day,
    sessions: apiHabit.sessions?.map(mapApiSessionToSession),
  };
}

export function mapApiTagToTag(apiTag: IApiTag): ITag {
  return {
    name: apiTag.name,
    id: apiTag.id,
    colour: apiTag.colour,
  };
}

export function api2domain__StatusOfAllTasks(
  apiTasksStatus: IApiStatusOfAllTasks
): IStatusOfAllTasks {
  return {
    score: apiTasksStatus.score,
    summaries: apiTasksStatus.summaries.map(api2domain__TaskSummary),
    numberOfTasksDueTodayIncomplete:
      apiTasksStatus.number_of_tasks_due_today_incomplete,
    numberOfTasksDueTodayComplete:
      apiTasksStatus.number_of_tasks_due_today_complete,
    numberOfOverdueTasksIncomplete:
      apiTasksStatus.number_of_overdue_tasks_incomplete,
    numberOfOverdueTasksComplete:
      apiTasksStatus.number_of_overdue_tasks_complete,
  };
}

export function api2domain__StatusOfAllProjects(
  apiProjectsStatus: IApiStatusOfAllProjects
): IStatusOfAllProjects {
  return {
    score: apiProjectsStatus.score,
    summaries: apiProjectsStatus.summaries?.map(api2domain__ProjectSummary),
  };
}

export function api2domain__StatusOfAllHabits(
  apiHabitsStatus: IApiStatusOfAllHabits
): IStatusOfAllHabits {
  return {
    score: apiHabitsStatus.score,
    summaries: apiHabitsStatus.summaries?.map(api2domain__HabitSummary),
    totalTime: apiHabitsStatus.total_time,
    totalTimeTarget: apiHabitsStatus.total_time_target,
    avgDailyTime: apiHabitsStatus.avg_daily_time,
    catchupMinutes: apiHabitsStatus.catchup_minutes,
    startDate: apiHabitsStatus.start_date
      ? DateTime.fromISO(apiHabitsStatus.start_date)
      : undefined,
    endDate: apiHabitsStatus.end_date
      ? DateTime.fromISO(apiHabitsStatus.end_date)
      : undefined,
  };
}

export function api2domain__ButlerAssessment(
  apiAssessment: IApiButlerAssessment
): IButlerAssessment {
  return {
    tasksStatus: api2domain__StatusOfAllTasks(apiAssessment.tasks_status),
    projectsStatus: api2domain__StatusOfAllProjects(
      apiAssessment.projects_status
    ),
    habitsStatus: api2domain__StatusOfAllHabits(apiAssessment.habits_status),
    tasksAdvice: api2domain__GenericAdvice(apiAssessment.tasks_advice),
    projectsAdvice: api2domain__GenericAdvice(apiAssessment.projects_advice),
    habitsAdvice: api2domain__GenericAdvice(apiAssessment.habits_advice),
    overallScore: apiAssessment.overall_score,
    startDate: DateTime.fromISO(apiAssessment.start_date),
    endDate: DateTime.fromISO(apiAssessment.end_date),
    totalTime: apiAssessment.total_time,
    avgDailyTime: apiAssessment.avg_daily_time,
  };
}

function api2domain__GenericAdvice(
  apiAdvice: IApiProjectsAdvice | IApiTasksAdvice | IApiHabitsAdvice
): IProjectsAdvice | ITasksAdvice | IHabitsAdvice {
  return {
    workOnNext: apiAdvice.work_on_next,
  };
}

export function api2Domain__User(user: IApiUser): IUser {
  return {
    email: user.email,
    firstName: user.first_name,
    lastName: user.last_name,
    messagingTokenRef: user.messaging_token_ref,
    phoneNumber: user.phone_number,
    reminderType: user.reminder_type,
    shouldReceiveReminders: user.should_receive_reminders,
    showCompletedTasks: user.show_completed_tasks,
    tags: user.tags?.map(mapApiTagToTag),
    todoListTaskIds: user.todo_list_task_ids,
    todoPreference: user.todo_preference,
    trackingPeriod: user.tracking_period,
    isTrackingPeriodCustom: user.is_tracking_period_custom,
    customTrackingPeriod: user.custom_tracking_period,
    verified: user.verified,
    version: user.version,
    created: user.created,
    updated: user.updated,
    viewedOnboarding: user.viewed_onboarding,
    viewedDashboardOnboarding: user.viewed_dashboard_onboarding,
    viewedTasksPageOnboarding: user.viewed_tasks_page_onboarding,
    viewedGoalsPageOnboarding: user.viewed_goals_page_onboarding,
    viewedHabitsPageOnboarding: user.viewed_habits_page_onboarding,
    viewedLogPageOnboarding: user.viewed_log_page_onboarding,
    viewedSessionsPageOnboarding: user.viewed_sessions_page_onboarding,
    viewedProjectPageOnboarding: user.viewed_project_page_onboarding,
    timezone: user.timezone,
    pushSubscription: user.push_subscription
      ? user.push_subscription.map(api2Domain__Subscription)
      : undefined,
  };
}

export function api2Domain__Subscription(
  subscription: IApiSubscription
): ISubscription {
  return {
    endpoint: subscription.endpoint,
    expirationTime: subscription.expiration_time,
    keys: {
      p256dh: subscription.keys.p256dh,
      auth: subscription.keys.auth,
    },
  };
}

export function api2domain__ProjectSummary(
  summary: IApiProjectSummary
): IProjectSummary {
  return {
    projectId: summary.project_id,
    projectName: summary.project_name,
    projectScore: summary.project_score,
    taskCompletion: summary.task_completion,
    isTracked: summary.is_tracked,
  };
}

export function api2domain__TaskSummary(
  summary: IApiTaskSummary
): ITaskSummary {
  return {
    taskId: summary.task_id,
    taskName: summary.task_name,
    taskScore: summary.task_score,
    task: mapApiTaskToTask(summary.task),
  };
}

export function api2domain__HabitSummary(
  habitSummary: IApiHabitSummary
): IHabitSummary {
  return {
    habitId: habitSummary.habit_id,
    habitName: habitSummary.habit_name,
    habitScore: habitSummary.habit_score,
    habitType: habitSummary.habit_type,
    units: habitSummary.units?.map(mapApiUnitOfLabourToUnitOfLabour),
    sessions: habitSummary.sessions?.map(mapApiSessionToSession),
    targetMinutes: habitSummary.target_minutes,
    customTarget: habitSummary.custom_target,
    unitName: habitSummary.unit_name,
    unitTypeId: habitSummary.unit_type_id,
    avgDailyTime: habitSummary.avg_daily_time,
    avgDailyUnits: habitSummary.avg_daily_units,
    totalTimeTarget: habitSummary.total_time_target,
    totalTime: habitSummary.total_time,
    totalUnits: habitSummary.total_units,
    catchupMinutes: habitSummary.catchup_minutes,
    catchupUnits: habitSummary.catchup_units,
  };
}

export function mapApiLabourTypeToLabourType(
  labourType: IApiLabourType
): ILabourType {
  return {
    id: labourType.id,
    name: labourType.name,
  };
}
