import React, { useState, useContext, useEffect } from 'react';
import { Dialog, Button, ButtonGroup, Intent } from '@blueprintjs/core';
import { AlertsContext } from '../../state/AlertsContext';
import { useModalStyles } from '../../style/components/genericStyles';
import { WindowContext } from '../../state/WindowContext';
import { IApiCreateLabourTypeRequest } from '../../common-src/types/LabourType';

interface Props {
  onRequestClose: () => void;
  modalIsOpen: boolean;
  addLabourType: (labourType: IApiCreateLabourTypeRequest) => Promise<void>;
}

const AddLabourTypeModal: React.FunctionComponent<Props> = (props: Props) => {
  const alertsContext = useContext(AlertsContext);
  const windowContext = useContext(WindowContext);
  const modalStyles = useModalStyles();

  const [labourTypeName, setLabourTypeName] = useState<string>('');

  const [isFormValid, setIsFormValid] = useState<boolean>(false);

  const [isSaving, setIsSaving] = useState<boolean>(false);

  useEffect(() => {
    if (labourTypeName === '') {
      setIsFormValid(false);
    } else {
      setIsFormValid(true);
    }
  }, [labourTypeName]);

  if (!alertsContext || !windowContext) {
    return null;
  }

  const handleTextChange = event => {
    setLabourTypeName(event.target.value);
  };

  const handleAddLabourType = async event => {
    event.preventDefault();

    setIsSaving(true);

    const { addLabourType } = props;

    const name = labourTypeName;

    if (name !== '') {
      try {
        const createLabourTypeRequest: IApiCreateLabourTypeRequest = {
          name,
        };
        await addLabourType(createLabourTypeRequest);
        alertsContext.addAlert(
          `Successfully added work type "${name}"`,
          Intent.SUCCESS
        );
      } catch (error) {
        alertsContext.addAlert(
          `Failed to add work type "${name}"`,
          Intent.DANGER
        );
      } finally {
        setIsSaving(false);
        handleClose();
      }
    }
  };

  const handleClose = () => {
    setLabourTypeName('');
    props.onRequestClose();
  };

  return (
    <Dialog
      isOpen={props.modalIsOpen}
      onClose={props.onRequestClose}
      title="Add a work type"
      canOutsideClickClose={false}
      style={windowContext?.isMobile ? { width: '50vh' } : {}}
    >
      <div className={`bp4-dialog-body ${modalStyles.body}`}>
        <label className="bp4-label">
          A name for this type of work (e.g. "Design", "Development", "Testing",
          "Practising piano"):
          <input
            className="bp4-input bp4-fill"
            type="text"
            onChange={handleTextChange}
            autoFocus
          />
        </label>
      </div>
      <div className={`bp4-dialog-footer ${modalStyles.footer}`}>
        <ButtonGroup>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={handleAddLabourType}
            intent={Intent.PRIMARY}
            disabled={!isFormValid}
            loading={isSaving}
          >
            Add
          </Button>
        </ButtonGroup>
      </div>
    </Dialog>
  );
};

export { AddLabourTypeModal };
