import { Button, ButtonGroup, Dialog, Intent } from '@blueprintjs/core';
import React, { useContext, useState } from 'react';
import { IPhase } from '../../common-src/types/Project';
import { AlertsContext } from '../../state/AlertsContext';
import { useModalStyles } from '../../style/components/genericStyles';
import { ProjectContext } from '../../state/ProjectContext';
import { WindowContext } from '../../state/WindowContext';

interface IProps {
  modalIsOpen: boolean;
  onRequestClose: () => void;
  phaseToDelete: IPhase;
  currentPhases: IPhase[];
  projectId: string | number;
}

const DeletePhaseModal: React.FunctionComponent<IProps> = (props: IProps) => {
  const projectContext = useContext(ProjectContext);
  const alertsContext = useContext(AlertsContext);
  const windowContext = useContext(WindowContext);
  const modalStyles = useModalStyles();
  const { modalIsOpen, onRequestClose, phaseToDelete } = props;

  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const handleClose = () => {
    onRequestClose();
  };

  const handleDeletePhase = async event => {
    event.preventDefault();
    setIsDeleting(true);
    try {
      await projectContext?.deletePhase(phaseToDelete.id);
      alertsContext!.addAlert(
        `Deleted phase "${phaseToDelete.name}"`,
        Intent.SUCCESS
      );
    } catch (err) {
      alertsContext!.addAlert(
        `Error deleting phase "${phaseToDelete.name}"`,
        Intent.DANGER
      );
    } finally {
      setIsDeleting(false);
      handleClose();
    }
  };

  return (
    <Dialog
      isOpen={modalIsOpen}
      onClose={onRequestClose}
      title={`Delete phase "${phaseToDelete.name}"`}
      canOutsideClickClose={false}
      style={windowContext?.isMobile ? { width: '50vh' } : {}}
    >
      <div className={`bp4-dialog-body ${modalStyles.body}`}>
        <p>
          Are you sure you want to delete this phase? Any tasks in it will be
          unphased as a result.
        </p>
      </div>

      <div className={`bp4-dialog-footer ${modalStyles.footer}`}>
        <ButtonGroup>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={handleDeletePhase}
            intent={Intent.DANGER}
            loading={isDeleting}
          >
            Do it!
          </Button>
        </ButtonGroup>
      </div>
    </Dialog>
  );
};

export { DeletePhaseModal };
