import { createUseStyles } from 'react-jss';
import { ITheme } from '../common-src/types/Theme';
import {
  // experimentalTheme,
  // lizTheme,
  // plainTheme,
  defaultTheme,
  // tangerineBlueTheme,
  // eightiesTheme,
  // crayolaTheme,
} from './themes';

export const chosenTheme: ITheme = defaultTheme;

export const useMainStyles = createUseStyles({
  main: {
    backgroundColor: chosenTheme.mainBackgroundColour,
    display: 'flex',
    '& h1, h2, h3, h4, p': {
      fontFamily: "'Fira Sans', sans-serif",
      color: chosenTheme.mainFontColour,
    },
    '@media only screen and (min-width: 320px) and (max-width: 990px)': {
      '& h1': {
        fontSize: 20,
      },
    },
  },
  alignLeft: { textAlign: 'left' },
});

export const useMainViewStyles = createUseStyles({
  mainView: {
    background: chosenTheme.mainBackgroundColour,
    height: '100vh',
    paddingTop: 50,
    overflowY: 'auto',
    flex: 10,
  },
  '@media only screen and (min-width: 320px) and (max-width: 480px)': {
    mainView: {
      marginLeft: 0,
    },
  },
});
