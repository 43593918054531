import { DateTime } from 'luxon';
import React, { SyntheticEvent, useContext } from 'react';
import { WindowContext } from '../../../state/WindowContext';
import { SingleDatePicker } from 'react-dates';
import moment, { Moment } from 'moment';
import ReactDatePicker from 'react-datepicker';
import { useDatetimePickerStyles } from '../../../style/components/datetimePickerStyles';

enum DatetimePickerType {
  date = 'date',
  time = 'time',
  datetime = 'datetime',
}

enum DatetimeFormat {
  DATE = 'YYYY-MM-DD',
  TIME = 'HH:mm',
  DATETIME = 'YYYY-MM-DD HH:mm',
}

const DATE_FORMAT_FOR_REACT_DATES = 'MMM Do, YYYY';
const TIME_FORMAT_FOR_REACT_DATES = 'HH:mm';
const DATE_TIME_FORMAT_FOR_REACT_DATES = `MMM Do, YYYY ${TIME_FORMAT_FOR_REACT_DATES}`;

const DATE_FORMAT_FOR_REACT_DATEPICKER = 'MMM dd, yyyy';
const TIME_FORMAT_FOR_REACT_DATEPICKER = 'HH:mm';
const DATE_TIME_FORMAT_FOR_REACT_DATEPICKER = `MMM dd, yyyy ${TIME_FORMAT_FOR_REACT_DATEPICKER}`;

interface IDatetimePickerProps {
  id: string;
  type: DatetimePickerType;
  label: string;
  placeholder?: string;
  datetime?: DateTime;
  onDatetimeChange: (date?: DateTime) => void;
  handleFocusChange?: (arg: { focused: boolean }) => void;
  isFocused?: boolean;
  datetimeFormat: DatetimeFormat;
  canClear: boolean;
  isOutsideRange?: (day: DateTime) => boolean;
  openDirection?: 'up' | 'down';
  dateOpts?: {};
  timeOpts?: {
    timeIntervals?: number;
  };
}

const DatetimePicker: React.FunctionComponent<IDatetimePickerProps> = (
  props: IDatetimePickerProps
) => {
  const {
    id,
    type,
    label,
    placeholder,
    datetime,
    onDatetimeChange,
    handleFocusChange,
    isFocused,
    datetimeFormat,
    canClear,
    isOutsideRange,
    openDirection,
    dateOpts,
    timeOpts,
  } = props;
  const windowContext = useContext(WindowContext);
  const datetimePickerStyles = useDatetimePickerStyles();

  if (!windowContext) {
    return null;
  }

  const getRealDatetimeFormatForLib = (
    dtFormat: DatetimeFormat,
    lib: 'react-dates' | 'react-datepicker'
  ): string => {
    switch (dtFormat) {
      case DatetimeFormat.DATE:
        if (lib === 'react-dates') {
          return DATE_FORMAT_FOR_REACT_DATES;
        } else if (lib === 'react-datepicker') {
          return DATE_FORMAT_FOR_REACT_DATEPICKER;
        }
      case DatetimeFormat.TIME:
        if (lib === 'react-dates') {
          return TIME_FORMAT_FOR_REACT_DATES;
        } else if (lib === 'react-datepicker') {
          return TIME_FORMAT_FOR_REACT_DATEPICKER;
        }
      case DatetimeFormat.DATETIME:
        if (lib === 'react-dates') {
          return DATE_TIME_FORMAT_FOR_REACT_DATES;
        } else if (lib === 'react-datepicker') {
          return DATE_TIME_FORMAT_FOR_REACT_DATEPICKER;
        }
      default:
        return '';
    }
  };

  const handleDatetimeChangeForSingleDatepicker = (date: Moment | null) => {
    const newDate = date
      ? DateTime.fromISO(moment(date).toISOString())
      : undefined;
    onDatetimeChange(newDate);
  };

  const handleDatetimeChangeForReactDatepicker = (
    date: Date | null,
    event: SyntheticEvent<any, Event> | undefined
  ) => {
    const newDate = date ? DateTime.fromJSDate(date) : undefined;
    onDatetimeChange(newDate);
  };

  const isOutsideRangeForSingleDatePicker = (day: Moment): boolean => {
    const dayAsDatetime = DateTime.fromISO(moment(day).toISOString());
    if (!!isOutsideRange && isOutsideRange(dayAsDatetime)) {
      return true;
    }
    return false;
  };

  const realDatetimeFormatForReactDates = getRealDatetimeFormatForLib(
    datetimeFormat,
    'react-dates'
  );
  const realDatetimeFormatForReactDatepicker = getRealDatetimeFormatForLib(
    datetimeFormat,
    'react-datepicker'
  );

  const labelStyle = {
    marginBottom: 5,
  };

  const renderDayContents = (day: moment.Moment) => {
    const today = moment();
    const isToday = day.isSame(today, 'day');
    return (
      <div style={isToday ? { color: 'blue', fontWeight: 'bolder' } : {}}>
        {day.format('D')}
      </div>
    );
  };

  if (
    type === DatetimePickerType.date &&
    !!handleFocusChange &&
    typeof isFocused !== 'undefined'
  ) {
    return (
      <div>
        <label className="bp4-label">
          <div style={labelStyle}>{label}</div>
          {!windowContext?.isMobile ? (
            <SingleDatePicker
              date={datetime ? moment(datetime.toJSDate()) : null}
              onDateChange={handleDatetimeChangeForSingleDatepicker}
              focused={isFocused}
              onFocusChange={handleFocusChange}
              id={id}
              placeholder={placeholder}
              displayFormat={realDatetimeFormatForReactDates}
              block={true}
              numberOfMonths={1}
              firstDayOfWeek={1}
              showClearDate={canClear}
              isOutsideRange={isOutsideRangeForSingleDatePicker}
              openDirection={openDirection}
              readOnly
              renderDayContents={renderDayContents}
            />
          ) : (
            <>
              <ReactDatePicker
                selected={datetime?.toJSDate()}
                onChange={handleDatetimeChangeForReactDatepicker}
                dateFormat={realDatetimeFormatForReactDatepicker}
                placeholderText={placeholder}
                className={datetimePickerStyles.datetimePicker}
                onKeyDown={e => {
                  e.preventDefault();
                  if (e.key === 'Delete' || e.key === 'Backspace') {
                    handleDatetimeChangeForReactDatepicker(null, e);
                  }
                }}
              />
            </>
          )}
        </label>
      </div>
    );
  } else if (type === DatetimePickerType.time) {
    return (
      <>
        <label className="bp4-label">
          <div style={labelStyle}>{label}</div>
          {!windowContext?.isMobile ? (
            <>
              <ReactDatePicker
                selected={datetime?.toJSDate()}
                onChange={handleDatetimeChangeForReactDatepicker}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={timeOpts?.timeIntervals ?? 1}
                timeCaption="Time"
                dateFormat={realDatetimeFormatForReactDatepicker}
                placeholderText={placeholder}
                className={datetimePickerStyles.datetimePicker}
                onKeyDown={e => {
                  e.preventDefault();
                  if (e.key === 'Delete' || e.key === 'Backspace') {
                    handleDatetimeChangeForReactDatepicker(null, e);
                  }
                }}
              />
            </>
          ) : (
            <>
              <ReactDatePicker
                selected={datetime?.toJSDate()}
                onChange={handleDatetimeChangeForReactDatepicker}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={timeOpts?.timeIntervals ?? 1}
                timeCaption="Time"
                dateFormat={realDatetimeFormatForReactDatepicker}
                placeholderText={placeholder}
                className={datetimePickerStyles.datetimePicker}
                onKeyDown={e => {
                  e.preventDefault();
                  if (e.key === 'Delete' || e.key === 'Backspace') {
                    handleDatetimeChangeForReactDatepicker(null, e);
                  }
                }}
              />
            </>
          )}
        </label>
      </>
    );
  } else if (type === DatetimePickerType.datetime) {
    return (
      <>
        <label className="bp4-label">
          <div style={labelStyle}>{label}</div>
          {!windowContext?.isMobile ? (
            <>
              <ReactDatePicker
                selected={datetime?.toJSDate()}
                onChange={handleDatetimeChangeForReactDatepicker}
                showTimeSelect
                timeIntervals={timeOpts?.timeIntervals ?? 1}
                timeCaption="Time"
                dateFormat={realDatetimeFormatForReactDatepicker}
                placeholderText={placeholder}
                className={datetimePickerStyles.datetimePicker}
                onKeyDown={e => {
                  e.preventDefault();
                  if (e.key === 'Delete' || e.key === 'Backspace') {
                    handleDatetimeChangeForReactDatepicker(null, e);
                  }
                }}
              />
            </>
          ) : (
            <>
              <ReactDatePicker
                selected={datetime?.toJSDate()}
                onChange={handleDatetimeChangeForReactDatepicker}
                showTimeSelect
                timeIntervals={timeOpts?.timeIntervals ?? 1}
                timeCaption="Time"
                dateFormat={realDatetimeFormatForReactDatepicker}
                placeholderText={placeholder}
                className={datetimePickerStyles.datetimePicker}
                onKeyDown={e => {
                  e.preventDefault();
                  if (e.key === 'Delete' || e.key === 'Backspace') {
                    handleDatetimeChangeForReactDatepicker(null, e);
                  }
                }}
              />
            </>
          )}
        </label>
      </>
    );
  } else {
    return null;
  }
};

export { DatetimePicker, DatetimePickerType, DatetimeFormat };
