import React, { useState, useContext } from 'react';
import { Dialog, Button, ButtonGroup, Intent } from '@blueprintjs/core';
import { TagsContext } from '../../state/TagsContext';
import { ITag } from '../../common-src/types/Tag';
import { AlertsContext } from '../../state/AlertsContext';
import { useModalStyles } from '../../style/components/genericStyles';
import { WindowContext } from '../../state/WindowContext';

interface Props {
  modalIsOpen: boolean;
  onRequestClose: () => void;
  tag: ITag;
}

const EditTagModal: React.FunctionComponent<Props> = (props: Props) => {
  const tagsContext = useContext(TagsContext);
  const alertsContext = useContext(AlertsContext);
  const windowContext = useContext(WindowContext);
  const modalStyles = useModalStyles();

  const { tag } = props;
  const [name, setName] = useState(tag.name);
  const [colour, setColour] = useState(tag.colour || 'grey');

  const [isSaving, setIsSaving] = useState<boolean>(false);

  const handleTagNameChange = event => {
    setName(event.target.value);
  };

  const handleColourSelect = event => {
    setColour(event.target.value);
  };

  const handleClose = () => {
    setName('');
    setColour('');
    props.onRequestClose();
  };

  const handleEditTag = async event => {
    event.preventDefault();
    setIsSaving(true);

    if (name !== '') {
      try {
        await tagsContext!.saveTag({ name, colour, id: tag.id });
        alertsContext!.addAlert(
          `Updated the colour of the "${name} tag"`,
          Intent.SUCCESS
        );
      } catch (err) {
        alertsContext!.addAlert(`Error updating tag "${name}"`, Intent.DANGER);
      } finally {
        setIsSaving(false);
        handleClose();
      }
    }
  };

  const colours = [
    { name: 'Grey', html: 'grey' },
    { name: 'Red', html: 'red' },
    { name: 'Orange', html: 'orange' },
    { name: 'Yellow', html: 'yellow' },
    { name: 'Green', html: 'green' },
    { name: 'Blue', html: 'blue' },
    { name: 'Indigo', html: 'indigo' },
    { name: 'Violet', html: 'violet' },
    { name: 'Light salmon', html: 'lightsalmon' },
    { name: 'Medium violet red', html: 'mediumvioletred' },
    { name: 'Coral', html: 'coral' },
    { name: 'Gold', html: 'gold' },
    { name: 'Lavender', html: 'lavender' },
    { name: 'Fuchsia', html: 'fuchsia' },
    { name: 'Dark magenta', html: 'darkmagenta' },
    { name: 'Olive', html: 'olive' },
    { name: 'Light sea green', html: 'lightseagreen' },
  ];
  let coloursToShow: any[] = [];
  colours.forEach(colour => {
    coloursToShow.push(
      <option
        value={colour.html}
        key={colour.html}
        style={{ color: colour.html }}
      >
        {colour.name}
      </option>
    );
  });

  return (
    <Dialog
      isOpen={props.modalIsOpen}
      onClose={props.onRequestClose}
      title={`Edit tag "${tag.name}"`}
      canOutsideClickClose={false}
      style={windowContext?.isMobile ? { width: '50vh' } : {}}
    >
      <div className={`bp4-dialog-body ${modalStyles.body}`}>
        <label className="bp4-label">
          Tag name:
          <input
            className="bp4-input bp4-fill"
            type="text"
            onChange={handleTagNameChange}
            value={name}
          />
        </label>
        <label className="bp4-label">
          Tag colour:
          <div className="bp4-select">
            <select value={colour} onChange={handleColourSelect}>
              {coloursToShow}
            </select>
          </div>
        </label>
      </div>

      <div className={`bp4-dialog-footer ${modalStyles.footer}`}>
        <ButtonGroup>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={handleEditTag}
            intent={Intent.PRIMARY}
            loading={isSaving}
          >
            Save
          </Button>
        </ButtonGroup>
      </div>
    </Dialog>
  );
};

export { EditTagModal };
