import React, { useContext, useEffect, useState } from 'react';
import { ITask } from '../../../common-src/types/Task';
import TaskGroup from '../tasks/TaskGroup';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { TasksContext } from '../../../state/TasksContext';
import { useLayoutStyles } from '../../../style/components/layoutStyles';
import { IOpenAddSessionModalOpts } from '../../../common-src/types/UiTypes';

interface IToDoListProps {
  todoListTaskIds: (string | number)[];
  openEditTaskModal: (taskId: ITask) => void;
  openAddSessionModal: (opts?: IOpenAddSessionModalOpts) => void;
  onDragEnd: (result: any) => void;
  isDragDisabled?: boolean;
  showArchivedTasks?: boolean;
}

const ToDoList: React.FunctionComponent<IToDoListProps> = (
  props: IToDoListProps
) => {
  const {
    todoListTaskIds,
    openEditTaskModal,
    openAddSessionModal,
    onDragEnd,
    isDragDisabled,
    showArchivedTasks,
  } = props;
  const tasksContext = useContext(TasksContext);
  const { tasks } = tasksContext!;
  const layoutStyles = useLayoutStyles();

  const [todoList, setTodoList] = useState<ITask[]>([]);

  useEffect(() => {
    if (todoListTaskIds && tasks) {
      const newTodoList = todoListTaskIds
        .map(taskId => tasks.find(task => task.id === taskId))
        .filter(task => task !== undefined) as ITask[];
      setTodoList(newTodoList);
    }
  }, [todoListTaskIds, tasks]);

  return (
    <div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="user-todo-list" type="todo-list">
          {provided => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              className={layoutStyles.col_cell}
            >
              <TaskGroup
                tasks={todoList}
                level="user"
                type="todo"
                title="Your TODO list"
                openEditTaskModal={openEditTaskModal}
                openAddSessionModal={openAddSessionModal}
                emptyListMessage="Your TODO list is empty"
                isDragDisabled={isDragDisabled}
                showArchivedTasks={showArchivedTasks}
              />
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export { ToDoList };
