import React, { ReactNode, useContext } from 'react';
import { AuthenticationContext } from './AuthenticationContext';
import {
  apiCreateHabit,
  apiDeleteHabit,
  apiGetHabits,
  apiGetOverallHabitStatus,
  apiUpdateHabit,
} from '../client/apiClient';
import {
  HabitType,
  HabitCadenceUnit,
  IApiCreateHabitRequest,
  IHabit,
  IApiUpdateHabitRequest,
} from '../common-src/types/Habit';
import { IStatusOfAllHabits } from '../common-src/types/Reporting';
import { UserContext } from './UserContext';
import { getStartAndEndDatesForTrackingPeriod } from '../common-src/productivity';
import { DateTime } from 'luxon';
import { useQuery, useQueryClient } from '@tanstack/react-query';

interface IHabitsContext {
  habits: IHabit[] | undefined;
  statusOfAllHabits?: IStatusOfAllHabits;
  createCompletionHabit: (
    habitName: string,
    cadenceUnit: HabitCadenceUnit,
    habitRegularity: number,
    habitStartDate: DateTime,
    habitEndDate?: DateTime
  ) => Promise<void>;
  createHabit: (createGoalRequest: IApiCreateHabitRequest) => Promise<IHabit>;
  updateHabit: (
    goalId: string,
    updateGoalRequest: IApiUpdateHabitRequest
  ) => Promise<IHabit>;
  updateCompletionHabit: (habit: IHabit) => Promise<void>;
  deleteHabit: (habit: IHabit) => Promise<void>;
  isFetchingHabits: boolean;
  isFetchingHabitStatus: boolean;
}

export const HabitsContext = React.createContext<IHabitsContext | null>(null);

interface Props {
  children: ReactNode;
}

const HabitsContextProvider: React.FunctionComponent<Props> = (
  props: Props
) => {
  const authenticationContext = useContext(AuthenticationContext);
  const userContext = useContext(UserContext);

  const queryClient = useQueryClient();

  const { trackingPeriod, customTrackingPeriod } = userContext!;

  const {
    isLoading: isFetchingHabits,
    isError: isFetchGoalsError,
    data: habits,
    error: fetchGoalsError,
  } = useQuery({
    queryKey: ['getHabits'],
    queryFn: async () => {
      try {
        if (
          authenticationContext &&
          authenticationContext.isUserAuthenticated
        ) {
          const accessToken = await authenticationContext.getToken();
          const habits = await apiGetHabits(accessToken);
          return habits;
        }
      } catch (err) {
        throw err;
      }
    },
    enabled: !!authenticationContext?.isUserAuthenticated,
  });

  const {
    isLoading: isFetchingHabitStatus,
    isError: isFetchHabitStatusError,
    data: statusOfAllHabits,
    error: fetchHabitStatusError,
  } = useQuery({
    queryKey: [
      'apiGetOverallHabitStatus',
      trackingPeriod,
      customTrackingPeriod,
    ],
    queryFn: async () => {
      try {
        if (
          authenticationContext &&
          authenticationContext.isUserAuthenticated &&
          trackingPeriod
        ) {
          const accessToken = await authenticationContext.getToken();
          const startAndEnd = getStartAndEndDatesForTrackingPeriod(
            trackingPeriod,
            customTrackingPeriod
          );
          const start_date = startAndEnd[0].toUTC().toISO();
          const end_date = startAndEnd[1].toUTC().toISO();
          const overallHabitStatus = await apiGetOverallHabitStatus(
            accessToken,
            start_date,
            end_date
          );
          return overallHabitStatus;
        }
      } catch (err) {
        throw err;
      }
    },
    enabled: !!authenticationContext?.isUserAuthenticated && !!trackingPeriod,
  });

  const createCompletionHabit = async (
    habitName: string,
    cadenceUnit: HabitCadenceUnit,
    habitRegularity: number,
    habitStartDate: DateTime,
    habitEndDate?: DateTime
  ) => {
    try {
      if (authenticationContext && authenticationContext.isUserAuthenticated) {
        const accessToken = await authenticationContext.getToken();
        const createGoalRequest: IApiCreateHabitRequest = {
          name: habitName,
          habit_type: HabitType.HABIT,
          start_date: habitStartDate.toUTC().toISO(),
          end_date: habitEndDate ? habitEndDate?.toUTC().toISO() : '',
          cadence: {
            unit: cadenceUnit,
            regularity: habitRegularity,
          },
        };
        const habitGoal = await apiCreateHabit(createGoalRequest, accessToken);
        queryClient.invalidateQueries({ queryKey: ['getHabits'] });
        queryClient.invalidateQueries({
          queryKey: [
            'apiGetOverallHabitStatus',
            trackingPeriod,
            customTrackingPeriod,
          ],
        });
        queryClient.invalidateQueries({
          queryKey: ['getRawProductivityForGoals'],
        });
      }
    } catch (err) {
      throw err;
    }
  };

  const createHabit = async (
    createGoalRequest: IApiCreateHabitRequest
  ): Promise<IHabit> => {
    try {
      if (authenticationContext && authenticationContext.isUserAuthenticated) {
        const accessToken = await authenticationContext.getToken();
        const goal = await apiCreateHabit(createGoalRequest, accessToken);
        queryClient.invalidateQueries({ queryKey: ['getHabits'] });
        queryClient.invalidateQueries({
          queryKey: [
            'apiGetOverallHabitStatus',
            trackingPeriod,
            customTrackingPeriod,
          ],
        });
        queryClient.invalidateQueries({
          queryKey: ['getRawProductivityForGoals'],
        });
        return goal;
      } else {
        throw new Error('User is not authenticated');
      }
    } catch (err) {
      throw err;
    }
  };

  const updateHabit = async (
    goalId: string,
    updateGoalRequest: IApiUpdateHabitRequest
  ): Promise<IHabit> => {
    try {
      if (authenticationContext && authenticationContext.isUserAuthenticated) {
        const accessToken = await authenticationContext.getToken();
        const goal = await apiUpdateHabit(
          goalId,
          updateGoalRequest,
          accessToken
        );
        queryClient.invalidateQueries({ queryKey: ['getHabits'] });
        queryClient.invalidateQueries({
          queryKey: [
            'apiGetOverallHabitStatus',
            trackingPeriod,
            customTrackingPeriod,
          ],
        });
        queryClient.invalidateQueries({
          queryKey: ['getRawProductivityForGoals'],
        });
        return goal;
      } else {
        throw new Error('User is not authenticated');
      }
    } catch (err) {
      throw err;
    }
  };

  const updateCompletionHabit = async (habit: IHabit) => {
    try {
      if (authenticationContext && authenticationContext.isUserAuthenticated) {
        const accessToken = await authenticationContext.getToken();
        const updateGoalRequest: IApiUpdateHabitRequest = {
          name: habit.name,
          habit_type: HabitType.HABIT,
          start_date: habit.startDate,
          end_date: habit.endDate,
          cadence: {
            unit: habit.cadence.unit,
            regularity: habit.cadence.regularity,
          },
          completions: habit.completions,
        };
        const updatedHabit = await apiUpdateHabit(
          habit.id,
          updateGoalRequest,
          accessToken
        );
        queryClient.invalidateQueries({ queryKey: ['getHabits'] });
        queryClient.invalidateQueries({
          queryKey: [
            'apiGetOverallHabitStatus',
            trackingPeriod,
            customTrackingPeriod,
          ],
        });
        queryClient.invalidateQueries({
          queryKey: ['getRawProductivityForGoals'],
        });
      }
    } catch (err) {
      throw err;
    }
  };

  const deleteHabit = async (habit: IHabit) => {
    try {
      if (authenticationContext && authenticationContext.isUserAuthenticated) {
        const accessToken = await authenticationContext.getToken();
        await apiDeleteHabit(habit.id, accessToken);
        queryClient.invalidateQueries({ queryKey: ['getHabits'] });
        queryClient.invalidateQueries({
          queryKey: [
            'apiGetOverallHabitStatus',
            trackingPeriod,
            customTrackingPeriod,
          ],
        });
        queryClient.invalidateQueries({
          queryKey: ['getRawProductivityForGoals'],
        });
      }
    } catch (err) {
      throw err;
    }
  };

  return (
    <HabitsContext.Provider
      value={{
        habits: habits,
        statusOfAllHabits,
        createCompletionHabit,
        createHabit,
        updateHabit,
        updateCompletionHabit,
        deleteHabit,
        isFetchingHabits,
        isFetchingHabitStatus,
      }}
    >
      {props.children}
    </HabitsContext.Provider>
  );
};

export { HabitsContextProvider };
export type { IHabitsContext };
