import { IUiError } from '../common-src/types/UiTypes';

export enum ApiErrors {
  GET_CURRENT_USER_ERROR = 'get-current-user-error',
  CREATE_USER_ERROR = 'create-user-error',
  SUBSCRIBE_TO_NOTIFICATIONS_ERROR = 'subscribe-to-notifications-error',
  GET_PROJECTS_ERROR = 'get-projects-error',
  UPDATE_PROJECTS_ERROR = 'update-projects-error',
  GET_PROJECT_ERROR = 'get-project-error',
  CREATE_PROJECT_ERROR = 'create-project-error',
  UPDATE_PROJECT_ERROR = 'update-project-error',
  DELETE_PROJECT_ERROR = 'delete-project-error',
  GET_TASKS_IN_PROJECT_ERROR = 'get-tasks-in-project-error',
  GET_TASKS_ERROR = 'get-tasks-error',
  CREATE_TASK_ERROR = 'create-task-error',
  UPDATE_TASK_ERROR = 'update-task-error',
  DELETE_TASK_ERROR = 'delete-task-error',
  GET_TODO_LIST_ERROR = 'get-todo-list-error',
  UPDATE_TODO_LIST_ERROR = 'update-todo-list-error',
  GET_HABITS_ERROR = 'get-habits-error',
  CREATE_HABIT_ERROR = 'create-habit-error',
  UPDATE_HABIT_ERROR = 'update-habit-error',
  DELETE_HABIT_ERROR = 'delete-habit-error',
  GET_OVERALL_HABIT_STATUS_ERROR = 'get-overall-habit-status-error',
  GET_PHASES_ERROR = 'get-phases-error',
  CREATE_PHASE_ERROR = 'create-phase-error',
  UPDATE_PHASE_ERROR = 'update-phase-error',
  UPDATE_PROJECT_PHASES_ERROR = 'update-project-phases-error',
  UPDATE_USER_PHASES_ERROR = 'update-user-phases-error',
  DELETE_PHASE_ERROR = 'delete-phase-error',
  UPDATE_TASK_ORDER_IN_PROJECT_ERROR = 'update-task-order-in-project-error',
  GET_LOGS_ERROR = 'get-logs-error',
  CREATE_LOG_ERROR = 'create-log-error',
  UPDATE_LOG_ERROR = 'update-log-error',
  DELETE_LOG_ERROR = 'delete-log-error',
  GET_TAGS_ERROR = 'get-tags-error',
  CREATE_TAG_ERROR = 'create-tag-error',
  UPDATE_TAG_ERROR = 'update-tag-error',
  DELETE_TAG_ERROR = 'delete-tag-error',
  GET_LOGS_IN_PROJECT_ERROR = 'get-logs-in-project-error',
  GET_SESSIONS_ERROR = 'get-sessions-error',
  GET_SESSIONS_IN_PROJECT_ERROR = 'get-sessions-in-project-error',
  CREATE_SESSION_ERROR = 'create-session-error',
  UPDATE_SESSION_ERROR = 'update-session-error',
  DELETE_SESSION_ERROR = 'delete-session-error',
  GET_UNIT_TYPES_ERROR = 'get-unit-types-error',
  CREATE_UNIT_TYPE_ERROR = 'create-unit-type-error',
  UPDATE_UNIT_TYPE_ERROR = 'update-unit-type-error',
  UPDATE_UNIT_TYPES_ORDER_ERROR = 'update-unit-types-order-error',
  DELETE_UNIT_TYPE_ERROR = 'delete-unit-type-error',
  GET_UNITS_ERROR = 'get-units-error',
  GET_UNITS_IN_PROJECT_ERROR = 'get-units-in-project-error',
  CREATE_UNIT_ERROR = 'create-unit-error',
  UPDATE_UNIT_ERROR = 'update-unit-error',
  UPDATE_USER_ERROR = 'update-user-error',
  GET_BUTLER_ASSESSMENT_ERROR = 'get-butler-assessment-error',
  GET_RAW_PRODUCTIVITY_ERROR = 'get-raw-productivity-error',
  GET_RAW_PRODUCTIVITY_FOR_PROJECT_GOALS_ERROR = 'get-raw-productivity-for-project-goals-error',
  GET_RAW_PRODUCTIVITY_FOR_GOALS_ERROR = 'get-raw-productivity-for-goals-error',
  GET_LABOUR_TYPES_ERROR = 'get-labour-types-error',
  CREATE_LABOUR_TYPE_ERROR = 'create-labour-type-error',
}

export const NoUnitTypesError: IUiError = {
  id: 'no-unit-types',
  message: 'No unit types found',
  description:
    'You need to add unit types before you can add unit-based goals.',
};
