import React, { useState, useContext } from 'react';
import { DateTime } from 'luxon';
import { Dialog, Button, ButtonGroup, Intent } from '@blueprintjs/core';
import { IProject } from '../../common-src/types/Project';
import { AlertsContext } from '../../state/AlertsContext';
import { MarkdownTextEditor } from '../common/MarkdownTextEditor';
import { LogContext } from '../../state/LogContext';
import { useModalStyles } from '../../style/components/genericStyles';
import {
  DatetimeFormat,
  DatetimePicker,
  DatetimePickerType,
} from '../common/DatetimePicker/DatetimePicker';
import { WindowContext } from '../../state/WindowContext';

const dateFormat = 'MMM Do, YYYY';

interface Props {
  project: IProject;
  onRequestClose: () => void;
  modalIsOpen: boolean;
}

const AddLogModalWithinProjectPage: React.FunctionComponent<Props> = (
  props: Props
) => {
  const alertsContext = useContext(AlertsContext);
  const logContext = useContext(LogContext);
  const windowContext = useContext(WindowContext);
  const modalStyles = useModalStyles();

  const [logDate, setLogDate] = useState<DateTime | undefined>(DateTime.now());
  const [logText, setLogText] = useState('');
  const [focused, setFocused] = useState<boolean>(false);

  const [isSaving, setIsSaving] = useState<boolean>(false);

  const handleLogDateChange = (date?: DateTime) => {
    setLogDate(date);
  };

  const handleLogTextChange = value => {
    setLogText(value);
  };

  const handleAddLog = async event => {
    event.preventDefault();
    const { project } = props;

    if (logDate && logText !== '') {
      setIsSaving(true);
      try {
        await logContext!.addLog(project.id, logDate.toISO(), logText);
        alertsContext!.addAlert('Successfully added log entry', Intent.SUCCESS);
      } catch (err) {
        alertsContext!.addAlert('Error adding log entry', Intent.DANGER);
      } finally {
        setIsSaving(false);
        handleClose();
      }
    }
  };

  const handleClose = () => {
    setLogDate(undefined);
    setLogText('');
    props.onRequestClose();
  };

  if (!props.project) return null;

  return (
    <Dialog
      isOpen={props.modalIsOpen}
      onClose={props.onRequestClose}
      title="Your log"
      canOutsideClickClose={false}
      style={windowContext?.isMobile ? { width: '50vh' } : {}}
    >
      <div className={`bp4-dialog-body ${modalStyles.body}`}>
        <div>
          <DatetimePicker
            id="project_log_date_picker"
            type={DatetimePickerType.datetime}
            label="Choose date:"
            placeholder="Enter the log date here"
            datetime={logDate}
            onDatetimeChange={handleLogDateChange}
            isFocused={focused}
            datetimeFormat={DatetimeFormat.DATETIME}
            canClear={false}
            isOutsideRange={_day => false}
          />
        </div>
        <label className="bp4-label">
          Enter your log here:
          <MarkdownTextEditor
            initialValue={logText}
            onTextChange={handleLogTextChange}
          />
        </label>
      </div>
      <div className={`bp4-dialog-footer ${modalStyles.footer}`}>
        <ButtonGroup>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={handleAddLog}
            intent={Intent.PRIMARY}
            loading={isSaving}
          >
            Add
          </Button>
        </ButtonGroup>
      </div>
    </Dialog>
  );
};

export { AddLogModalWithinProjectPage };
