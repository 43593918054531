import { DateTime } from 'luxon';
import React from 'react';
import {
  DatetimeFormat,
  DatetimePicker,
  DatetimePickerType,
} from './DatetimePicker';

interface IProps {
  completionDatetime: DateTime;
  onCompletionDatetimeChange: (date?: DateTime) => void;
  isEditing: boolean;
  onClick: (event: any) => void;
  isFocused: boolean;
  handleFocusChange: (arg: { focused: boolean }) => void;
}

const EditableTextDatetimePicker: React.FunctionComponent<IProps> = (
  props: IProps
) => {
  const {
    completionDatetime,
    onCompletionDatetimeChange,
    isEditing,
    onClick,
    isFocused,
    handleFocusChange,
  } = props;
  if (!completionDatetime) {
    return null;
  }

  return (
    <>
      {isEditing ? (
        <div>
          <DatetimePicker
            id="task_duedate_picker"
            type={DatetimePickerType.datetime}
            label="Set completion time"
            placeholder="Enter the update completion time"
            datetime={completionDatetime}
            onDatetimeChange={onCompletionDatetimeChange}
            handleFocusChange={handleFocusChange}
            isFocused={isFocused}
            datetimeFormat={DatetimeFormat.DATETIME}
            canClear={false}
          />
        </div>
      ) : (
        <>
          <p onClick={onClick}>
            {`Completed on ${completionDatetime.toLocaleString(
              DateTime.DATETIME_MED_WITH_WEEKDAY
            )} `}
            <strong>(click to edit)</strong>
          </p>
        </>
      )}
    </>
  );
};

export { EditableTextDatetimePicker };
