import React, { useContext } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import { TempMobileMenuContext } from '../../../state/TempMobileMenuContext';
import { useNavbarSideStyles } from '../../../style/components/navbarStyles';

interface Props {
  isMobile?: boolean;
  to: string;
  text: string;
  numberOfIncompleteTasks?: number;
  position?: number;
  type?: 'projectlist' | 'none';
  id?: string;
}

const NavBarItem: React.FunctionComponent<Props> = (props: Props) => {
  const context = useContext(TempMobileMenuContext);
  const navbarSideStyles = useNavbarSideStyles();
  const location = useLocation();

  const isActive = location.pathname === props.to;

  const { id } = props;

  return (
    <div
      style={{
        paddingTop: 5,
        paddingBottom: 5,
        paddingLeft: 5,
        paddingRight: 5,
      }}
      id={id}
    >
      <NavLink
        to={props.to}
        onClick={props.isMobile ? context!.toggleMobileMenu : () => {}}
        style={{
          overflowX: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
        className={`bp4-menu-item bp4-popover-dismiss ${
          isActive
            ? navbarSideStyles.navbarItemActive
            : navbarSideStyles.navbarItem
        }`}
      >
        <div style={{ display: 'flex', maxWidth: '100%' }}>
          <div
            style={{
              overflowX: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              flex: 9,
            }}
          >
            {props.text}{' '}
          </div>
          {props.numberOfIncompleteTasks ? (
            <div style={{ flex: 1 }}>
              <span
                className={`bp4-tag bp4-minimal bp4-round`}
                style={{
                  marginRight: 5,
                  marginLeft: 5,
                  backgroundColor: 'rgb(245,140,130)',
                }}
              >
                <span
                  className={`bp4-text-overflow-ellipsis bp4-fill`}
                  style={{ color: 'white' }}
                >
                  {props.numberOfIncompleteTasks}
                </span>
              </span>
            </div>
          ) : null}
        </div>
      </NavLink>
    </div>
  );
};

export default NavBarItem;
