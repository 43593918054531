import { createUseStyles } from 'react-jss';
import { chosenTheme } from '../styleHooks';

export const usePieChartStyles = createUseStyles({
  pieChartContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
});
