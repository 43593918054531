import React, { useContext } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import _ from 'lodash';
import { UserContext } from '../../../state/UserContext';
import { TasksContext } from '../../../state/TasksContext';
import { ProjectsListContext } from '../../../state/ProjectsListContext';
import { TagsContext } from '../../../state/TagsContext';
import { orderTasksByDate } from '../../../func/functions';
import Task from './Task';
import { ITask } from '../../../common-src/types/Task';
import { ISimplifiedProject } from '../../../common-src/types/Project';
import { ModalContext } from '../../../state/ModalContext';
import { useMainStyles } from '../../../style/styleHooks';
import { useTaskGroupStyles } from '../../../style/components/taskGroupStyles';
import {
  IOpenAddSessionModalOpts,
  TaskContext,
} from '../../../common-src/types/UiTypes';

interface Props {
  tasks: ITask[];
  type: TaskContext;
  level: 'project' | 'user';
  emptyListMessage?: string;
  title?: string;
  openEditTaskModal: (taskId: ITask) => void;
  openAddSessionModal: (opts?: IOpenAddSessionModalOpts) => void;
  isDraggingOver?: boolean;
  isDragDisabled?: boolean;
  isKanban?: boolean;
  showArchivedTasks?: boolean;
  /**
   * If true, the component will return null if there are no tasks to show after filtering.
   */
  dontRenderIfEmpty?: boolean;
}

const TaskGroup: React.FunctionComponent<Props> = (props: Props) => {
  const userContext = useContext(UserContext);
  const tasksContext = useContext(TasksContext);
  const projectsContext = useContext(ProjectsListContext);
  const tagsContext = useContext(TagsContext);
  const modalContext = useContext(ModalContext);

  const taskGroupStyles = useTaskGroupStyles();
  const mainStyles = useMainStyles();

  const {
    title,
    tasks,
    emptyListMessage,
    type,
    level,
    openEditTaskModal,
    openAddSessionModal,
    isDragDisabled,
    showArchivedTasks,
    dontRenderIfEmpty,
  } = props;

  const getTasksToShow = (): ITask[] => {
    const { projects } = projectsContext!;
    const { showCompletedTasks } = userContext!;
    const orderedTasks = type === 'due_date' ? orderTasksByDate(tasks) : tasks;

    const projectsArr = projects ?? [];

    if (!projectsArr) return [];

    const tasksToShow = orderedTasks.filter((task, index) => {
      const associatedSimplifiedProject = projectsArr.find(
        (project: ISimplifiedProject) => project.id === task.projectId
      );
      const dontShowBecauseArchived =
        !showArchivedTasks && associatedSimplifiedProject?.archived;
      const dontShowBecauseCompleted = !showCompletedTasks && task.done;
      const shouldShowTask =
        !dontShowBecauseArchived && !dontShowBecauseCompleted;
      if (shouldShowTask) {
        return task;
      }
    });
    return tasksToShow;
  };

  const getTasksToRender = () => {
    const { openDeleteTaskModal } = modalContext!;
    const { tags } = tagsContext!;
    const { projects } = projectsContext!;
    const { saveTask } = tasksContext!;

    const projectsArr = projects ?? [];
    const tagsArr = tags ?? [];

    if (!projectsArr) return null;

    const tasksToRender = tasksToShow.map((task, index) => {
      const associatedSimplifiedProject = projectsArr.find(
        (project: ISimplifiedProject) => project.id === task.projectId
      );
      const associatedProjectName = associatedSimplifiedProject?.name;
      return (
        <div key={index}>
          {
            <Draggable
              draggableId={task.id}
              index={index}
              key={task.id}
              isDragDisabled={isDragDisabled}
            >
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                >
                  <Task
                    task={task}
                    type={type}
                    showProjectName={
                      (type === 'board' && level === 'user') ||
                      type === 'due_date' ||
                      (type === 'todo' && level === 'user')
                    }
                    archived={associatedSimplifiedProject?.archived}
                    openEditTaskModal={openEditTaskModal}
                    openAddSessionModal={openAddSessionModal}
                    allTags={tagsArr}
                    associatedProject={associatedSimplifiedProject!}
                    associatedProjectName={associatedProjectName!}
                    saveTask={saveTask!}
                    openDeleteTaskModal={openDeleteTaskModal}
                    isDragging={snapshot.isDragging}
                  />
                </div>
              )}
            </Draggable>
          }
        </div>
      );
    });
    return tasksToRender;
  };

  const tasksToShow = getTasksToShow();

  const noTasksToShow = tasksToShow === null || tasksToShow.length === 0;
  let className = taskGroupStyles.taskGroup;
  if (props.isDraggingOver) {
    className = className?.concat(` ${taskGroupStyles.taskGroupDraggingOver}`);
  }
  if (type === 'board') {
    className = className?.concat(` ${taskGroupStyles.taskGroupBoardPhase}`);
  } else if (type === 'todo') {
    className = className?.concat(` ${taskGroupStyles.taskGroupTodoList}`);
  } else if (type === 'due_date') {
    className = className?.concat(` ${taskGroupStyles.taskGroupOverview}`);
  }

  if (dontRenderIfEmpty && noTasksToShow) {
    return null;
  } else if (noTasksToShow) {
    return <div className={className} />;
  } else {
    return (
      <div className={className}>
        {title && <h2 className={mainStyles.alignLeft}>{title}</h2>}
        {tasks && tasks.length !== 0 ? getTasksToRender() : <div />}
      </div>
    );
  }
};

export default TaskGroup;
