import { Button, Menu, MenuItem, Popover, Position } from '@blueprintjs/core';
import { DateTime } from 'luxon';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { getProductivityTooltipForHabitInPieChart } from '../../common-src/productivity';
import { HabitType, IHabit } from '../../common-src/types/Habit';
import { UserContext } from '../../state/UserContext';
import { WindowContext } from '../../state/WindowContext';
import { useGenericStyles } from '../../style/components/genericStyles';
import { WhatNeedsToBeDone } from '../common/WhatNeedsToBeDone';
import { IOpenAddSessionModalOpts } from '../../common-src/types/UiTypes';
import { PieChartType, PieChart } from '../common/charts/PieChart';
import { IHabitSummary } from '../../common-src/types/Reporting';

interface IHabitSummaryProps {
  habit: IHabit;
  habitSummary?: IHabitSummary;
  startDate: DateTime;
  endDate: DateTime;
  openEditHabitModal: (goal: IHabit) => void;
  openEditCompletionHabitModal: (habit: IHabit) => void;
  openAddSessionModal: (opts?: IOpenAddSessionModalOpts) => void;
  openDeleteGoalModal: (goal: IHabit) => void;
}

const HabitSummary: React.FunctionComponent<IHabitSummaryProps> = (
  props: IHabitSummaryProps
) => {
  const userContext = useContext(UserContext);
  const windowContext = useContext(WindowContext);
  const genericStyles = useGenericStyles();

  const navigate = useNavigate();

  const { trackingPeriod, customTrackingPeriod, labourTypes } = userContext!;
  const {
    habit,
    habitSummary,
    openEditHabitModal,
    openEditCompletionHabitModal,
    openDeleteGoalModal,
    openAddSessionModal,
  } = props;

  if (!habit) {
    return <div style={{ flex: 1 }} key="no-proj" />;
  }

  const handleNavigateToGoalDetails = () => {
    navigate(`/habits/${habit.id}`);
  };

  const handleOpenEditModal = () => {
    openEditHabitModal(habit);
  };

  const handleOpenDeleteGoalModal = () => {
    const habitToDelete: IHabit = habit;
    if (!habitToDelete) {
      return;
    }
    openDeleteGoalModal(habitToDelete);
  };

  const handleOpenAddSessionModal = () => {
    openAddSessionModal({ associatedHabit: habit });
  };

  const dropdown = (
    <Menu>
      <MenuItem
        icon="info-sign"
        text="Habit details"
        onClick={handleNavigateToGoalDetails}
      />
      <MenuItem icon="cog" text="Edit habit" onClick={handleOpenEditModal} />
      {habit.type === HabitType.SESSION ? (
        <MenuItem
          icon="pulse"
          text="Add session for this habit"
          onClick={handleOpenAddSessionModal}
        />
      ) : null}
      <MenuItem
        icon="trash"
        text="Delete habit"
        onClick={handleOpenDeleteGoalModal}
      />
    </Menu>
  );

  const getStartDateText = (startDate: DateTime): string => {
    const today = DateTime.now().startOf('day');
    const startDateAtStartOfDay = startDate.startOf('day');
    if (startDateAtStartOfDay < today) {
      return `This habit started on ${startDateAtStartOfDay.toLocaleString(
        DateTime.DATE_MED
      )}`;
    } else if (startDateAtStartOfDay > today) {
      return `This habit starts on ${startDateAtStartOfDay.toLocaleString(
        DateTime.DATE_MED
      )}`;
    } else {
      return 'This habit starts today';
    }
  };

  const getEndDateText = (endDate: DateTime): string => {
    const today = DateTime.now().startOf('day');
    const endDateAtStartOfDay = endDate.startOf('day');
    if (endDateAtStartOfDay < today) {
      return `This habit ended on ${endDateAtStartOfDay.toLocaleString(
        DateTime.DATE_MED
      )}`;
    } else if (endDateAtStartOfDay > today) {
      return `This habit ends on ${endDateAtStartOfDay.toLocaleString(
        DateTime.DATE_MED
      )}`;
    } else {
      return 'This habit ends today';
    }
  };

  const associatedLabourType = labourTypes?.find(
    lt => lt.id === habit.labourTypeId
  );

  let associatedLabourTypeText = '';

  if (associatedLabourType) {
    associatedLabourTypeText = ` (associated with work type "${associatedLabourType.name}")`;
  }

  const startDateText = habit.startDate
    ? getStartDateText(DateTime.fromISO(habit.startDate))
    : '';

  const endDateText = habit.endDate
    ? getEndDateText(DateTime.fromISO(habit.endDate))
    : '';

  return (
    <div
      key={habit.id}
      style={{
        flex: 1,
        marginTop: 40,
      }}
    >
      <div className={genericStyles.genericBox}>
        <h3>
          <em>{habit.name}</em>
        </h3>
        <div>
          <Popover content={dropdown} position={Position.RIGHT_BOTTOM}>
            <Button icon="more" minimal={true} />
          </Popover>
        </div>
        {!habit.isActive ? (
          <p>
            <em>This habit is inactive</em>
          </p>
        ) : null}
        {trackingPeriod && habit.isActive && habitSummary ? (
          <PieChart
            type={PieChartType.SINGLE_METRIC}
            tooltip={getProductivityTooltipForHabitInPieChart(
              habitSummary,
              trackingPeriod,
              customTrackingPeriod
            )}
            chartMetricsForPrimaryPie={[
              {
                id: habitSummary.habitId,
                label: 'Overall work',
                value: habitSummary?.habitScore,
              },
              {
                id: `${habitSummary.habitId}_anti`,
                label: 'Overall slacking',
                value: 1 - habitSummary?.habitScore,
                colour: 'rgb(255 255 255 / .1)',
              },
            ]}
            trackingPeriod={trackingPeriod}
            customTrackingPeriodInDays={customTrackingPeriod}
            width={250}
            height={150}
            margin={
              windowContext?.isMobile
                ? { top: 0, right: 0, bottom: 0, left: 0 }
                : { top: 20, right: 20, bottom: 20, left: 20 }
            }
            animate={true}
            centreText={`${Math.round(habitSummary?.habitScore * 100)}%`}
          />
        ) : null}
        {trackingPeriod && habit.isActive && habitSummary ? (
          <WhatNeedsToBeDone
            trackingPeriod={trackingPeriod}
            customTrackingPeriodInDays={customTrackingPeriod}
            type="HABIT"
            habit={habit}
            habitSummary={habitSummary}
          />
        ) : null}
        <div>
          {startDateText ? (
            <p>
              <em>{startDateText}</em>
            </p>
          ) : null}
          {endDateText ? (
            <p>
              <em>{endDateText}</em>
            </p>
          ) : null}
          {associatedLabourTypeText && associatedLabourType ? (
            <p>
              <em>
                This habit is associated with work type{' '}
                <strong>{associatedLabourType.name}</strong>
              </em>
            </p>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export { HabitSummary };
