import React, { useState, useContext, useEffect } from 'react';
import _ from 'lodash';
import { Dialog, Checkbox, Button, Intent } from '@blueprintjs/core';
import { IProject } from '../../common-src/types/Project';
import { AlertsContext } from '../../state/AlertsContext';
import { useModalStyles } from '../../style/components/genericStyles';
import {
  DatetimeFormat,
  DatetimePicker,
  DatetimePickerType,
} from '../common/DatetimePicker/DatetimePicker';
import { DateTime } from 'luxon';
import { WindowContext } from '../../state/WindowContext';

interface Props {
  onRequestClose: () => void;
  modalIsOpen: boolean;
  project: IProject;
  updateProject: (project: IProject) => Promise<void>;
}

const EditProjectModal: React.FunctionComponent<Props> = (props: Props) => {
  const alertsContext = useContext(AlertsContext);
  const windowContext = useContext(WindowContext);
  const modalStyles = useModalStyles();

  const [projectName, setProjectName] = useState<string>();
  const [unitName, setUnitName] = useState<string>();
  const [projectStartDate, setProjectStartDate] = useState<
    DateTime | undefined
  >();
  const [projectEndDate, setProjectEndDate] = useState<DateTime | undefined>();
  const [unitTarget, setUnitTarget] = useState<number>();
  const [minutesTarget, setMinutesTarget] = useState<number>();
  const [trackUnits, setTrackUnits] = useState<boolean>();
  const [trackTime, setTrackTime] = useState<boolean>();

  const [isSaving, setIsSaving] = useState<boolean>(false);

  const [isProjectStartDateFocused, setIsProjectStartDateFocused] =
    useState(false);
  const [isProjectEndDateFocused, setIsProjectEndDateFocused] = useState(false);

  const { project, modalIsOpen, updateProject } = props;

  const handleProjectNameChange = event => {
    setProjectName(event.target.value);
  };

  const handleStartDateChange = (date?: DateTime) => {
    setProjectStartDate(date ?? undefined);
  };

  const handleEndDateChange = (date?: DateTime) => {
    setProjectEndDate(date ?? undefined);
  };

  const handleUnitNameChange = event => {
    setUnitName(event.target.value);
  };

  const handleUnitTargetChange = event => {
    setUnitTarget(+event.target.value);
  };

  const handleMinutesTargetChange = event => {
    setMinutesTarget(+event.target.value);
  };

  const handleTrackUnitsChange = event => {
    setTrackUnits(!trackUnits);
  };

  const handleTrackTimeChange = event => {
    setTrackTime(!trackTime);
  };

  const handleStartDateFocusChange = (arg: { focused: boolean }) => {
    setIsProjectStartDateFocused(arg.focused);
  };

  const handleEndDateFocusChange = (arg: { focused: boolean }) => {
    setIsProjectEndDateFocused(arg.focused);
  };

  const handleSaveProject = async event => {
    event.preventDefault();
    const { project } = props;

    if (projectName !== '') {
      setIsSaving(true);
      try {
        const updatedProject = Object.assign({}, project, {
          name: projectName,
          unitName: unitName,
          customTarget: unitTarget,
          targetMinutes: minutesTarget,
          trackUnits: trackUnits,
          trackTime: trackTime,
          startDate: projectStartDate,
          endDate: projectEndDate,
        });
        await updateProject(updatedProject);
        alertsContext!.addAlert(
          `Updated settings for "${projectName}"`,
          Intent.SUCCESS
        );
      } catch (err) {
        alertsContext!.addAlert(
          `Error updating project "${projectName}"`,
          Intent.DANGER
        );
      } finally {
        setIsSaving(false);
        handleClose();
      }
    }
  };

  const handleClose = () => {
    setProjectName(undefined);
    setUnitName(undefined);
    setUnitTarget(undefined);
    setMinutesTarget(undefined);
    setTrackUnits(undefined);
    setTrackTime(undefined);
    props.onRequestClose();
  };

  useEffect(() => {
    if (_.isNumber(props.project.id) || _.isString(props.project.id)) {
      const { project } = props;
      setProjectName(project.name);
      setProjectStartDate(project.startDate);
      setProjectEndDate(project.endDate);
      setUnitName(project.unitName);
      setUnitTarget(project.customTarget);
      setMinutesTarget(project.targetMinutes);
      setTrackUnits(project.trackUnits);
      setTrackTime(project.trackTime);
    }
  }, [props]);

  if (!project) {
    return null;
  }

  return (
    <Dialog
      isOpen={modalIsOpen}
      onClose={handleClose}
      title="Edit the project"
      canOutsideClickClose={false}
      style={windowContext?.isMobile ? { width: '50vh' } : {}}
    >
      <div className={`bp4-dialog-body ${modalStyles.body}`}>
        <div>
          <label className="bp4-label">
            Your project's name
            <input
              className="bp4-input bp4-fill"
              type="text"
              onChange={handleProjectNameChange}
              value={projectName}
            />
          </label>
        </div>
        <DatetimePicker
          id="project_start_date_picker"
          type={DatetimePickerType.date}
          label="Start date"
          placeholder="Enter the start date here (optional)"
          datetime={projectStartDate}
          onDatetimeChange={handleStartDateChange}
          handleFocusChange={handleStartDateFocusChange}
          isFocused={isProjectStartDateFocused}
          datetimeFormat={DatetimeFormat.DATE}
          canClear={true}
        />
        <DatetimePicker
          id="project_end_date_picker"
          type={DatetimePickerType.date}
          label="End date"
          placeholder="Enter the end date here (optional)"
          datetime={projectEndDate}
          onDatetimeChange={handleEndDateChange}
          handleFocusChange={handleEndDateFocusChange}
          isFocused={isProjectEndDateFocused}
          datetimeFormat={DatetimeFormat.DATE}
          canClear={true}
        />
      </div>
      <div className={`bp4-dialog-footer ${modalStyles.footer}`}>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          onClick={handleSaveProject}
          intent={Intent.PRIMARY}
          loading={isSaving}
        >
          Save changes
        </Button>
      </div>
    </Dialog>
  );
};

export { EditProjectModal };
