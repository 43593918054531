import React, { useEffect, useState, useContext } from 'react';
import { DateTime } from 'luxon';
import { Dialog, Button, Intent } from '@blueprintjs/core';

import { IProject, ISimplifiedProject } from '../../common-src/types/Project';
import { AlertsContext } from '../../state/AlertsContext';
import { WindowContext } from '../../state/WindowContext';
import { PomodoroContext } from '../../state/PomodoroContext';
import { useModalStyles } from '../../style/components/genericStyles';
import { SessionAndUnitContext } from '../../state/SessionAndUnitContext';
import { ProjectContext } from '../../state/ProjectContext';
import {
  DatetimeFormat,
  DatetimePicker,
  DatetimePickerType,
} from '../common/DatetimePicker/DatetimePicker';
import { HabitType, IHabit } from '../../common-src/types/Habit';
import { ModalContext } from '../../state/ModalContext';
import { ILabourType } from '../../common-src/types/LabourType';
import { MarkdownTextEditor } from '../common/MarkdownTextEditor';
import { ITask } from '../../common-src/types/Task';
import { TagSelector } from '../common/tags/TagSelector';
import { ITag } from '../../common-src/types/Tag';

interface Props {
  project?: IProject;
  simplifiedProjectList?: ISimplifiedProject[];
  tasks?: ITask[];
  habits?: IHabit[];
  labourTypes?: ILabourType[];
  tags: ITag[];
  modalIsOpen: boolean;
  onRequestClose: () => void;
}

const AddSessionModal: React.FunctionComponent<Props> = (props: Props) => {
  const alertsContext = useContext(AlertsContext);
  const windowContext = useContext(WindowContext);
  const pomodoroContext = useContext(PomodoroContext);
  const sessionAndUnitContext = useContext(SessionAndUnitContext);
  const projectContext = useContext(ProjectContext);
  const modalContext = useContext(ModalContext);
  const modalStyles = useModalStyles();

  const { project, simplifiedProjectList, tasks, habits, labourTypes, tags } =
    props;

  const [sessionTime, setSessionTime] = useState<DateTime | undefined>(
    DateTime.now()
  );
  const [sessionDuration, setSessionDuration] = useState(0); // @TODO: the "length of session" input should default to blank (rather than 0)
  const [sessionNotes, setSessionNotes] = useState<string>('');
  const [sessionProjectId, setSessionProjectId] = useState<string | undefined>(
    project ? project.id : undefined
  );
  const [sessionTaskId, setSessionTaskId] = useState<string | undefined>(
    undefined
  );
  const [sessionHabitId, setSessionHabitId] = useState<string | undefined>(
    undefined
  );
  const [sessionLabourTypeId, setSessionLabourTypeId] = useState<
    string | undefined
  >(undefined);
  const [sessionTagIds, setSessionTagIds] = useState<string[] | undefined>(
    undefined
  );

  const [focused, setFocused] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  useEffect(() => {
    setSessionTime(DateTime.now());
  }, [props]);

  useEffect(() => {
    if (modalContext?.habitToAddSessionTo) {
      setSessionHabitId(modalContext.habitToAddSessionTo.id);
    }
  }, [modalContext?.habitToAddSessionTo]);

  useEffect(() => {
    if (modalContext?.taskToAddSessionTo) {
      setSessionTaskId(modalContext.taskToAddSessionTo.id);
    }
  }, [modalContext?.taskToAddSessionTo]);

  useEffect(() => {
    if (modalContext?.projectToAddSessionTo) {
      setSessionProjectId(modalContext.projectToAddSessionTo.id);
    }
  }, [modalContext?.projectToAddSessionTo]);

  if (
    !alertsContext ||
    !windowContext ||
    !pomodoroContext ||
    !sessionAndUnitContext ||
    !modalContext ||
    !projectContext
  ) {
    return null;
  }

  const { habitToAddSessionTo, taskToAddSessionTo, projectToAddSessionTo } =
    modalContext;

  const handleSessionDatetimeChange = (datetime?: DateTime) => {
    setSessionTime(datetime);
  };

  const handleSessionDurationChange = event => {
    setSessionDuration(event.target.value);
  };

  const handleAddSession = async () => {
    if (sessionDuration && sessionTime) {
      setIsSaving(true);
      try {
        await sessionAndUnitContext.addSession(
          sessionDuration,
          sessionTime.toISO(),
          sessionProjectId,
          sessionTaskId,
          sessionHabitId,
          sessionLabourTypeId,
          sessionNotes,
          sessionTagIds
        );
        alertsContext.addAlert(
          `Added a ${sessionDuration} minute session to your records`,
          Intent.SUCCESS
        );
      } catch (err) {
        alertsContext.addAlert(`Error adding your session`, Intent.DANGER);
      } finally {
        setIsSaving(false);
        handleClose();
      }
    }
    // TODO: add validation
  };

  const handleNotesChange = (value: string) => {
    setSessionNotes(value);
  };

  const handleProjectChange = event => {
    setSessionProjectId(event.target.value);
  };

  const handleHabitChange = event => {
    setSessionHabitId(event.target.value);
  };

  const handleClose = () => {
    setSessionTime(undefined);
    setSessionDuration(0);
    setSessionNotes('');
    setSessionProjectId(undefined);
    setSessionTaskId(undefined);
    setSessionHabitId(undefined);
    setSessionLabourTypeId(undefined);
    setSessionTagIds(undefined);
    props.onRequestClose();
  };

  const handleTagSelect = (tag: ITag) => {
    let newTagIds = sessionTagIds?.slice();
    const tagId = tag.id;
    if (newTagIds) {
      if (!newTagIds.includes(tagId)) {
        newTagIds.push(tagId);
      } else {
        newTagIds = newTagIds.filter(id => id !== tagId);
      }
    } else {
      newTagIds = [tagId];
    }
    setSessionTagIds(newTagIds.slice());
  };

  const handleTagRemove = (tagName: string, index: number) => {
    const selectedTags = getSelectedTags();
    // @TODO: blueprint doesn't appear to have a way to remove a tag by id. Find a way around this
    const tagToRemove = selectedTags.find(tag => tag.name === tagName);
    if (tagToRemove) {
      const newListOfSelectedTags = selectedTags.filter(
        tag => tag.id !== tagToRemove.id
      );
      const newTagIds = newListOfSelectedTags.map(tag => tag.id);
      setSessionTagIds(newTagIds.slice());
    }
  };

  const getSelectedTags = (): ITag[] => {
    const { tags } = props;
    if (sessionTagIds) {
      const selectedTags = tags!.filter(
        tag => sessionTagIds && sessionTagIds.includes(tag.id)
      );
      return selectedTags || [];
    } else {
      return [];
    }
  };

  const projectsToShow: JSX.Element[] = [];

  projectsToShow.push(
    <option value="" key="None">
      None
    </option>
  );

  const sortedProjectList = simplifiedProjectList?.slice().sort((a, b) => {
    return a.name.localeCompare(b.name);
  });

  sortedProjectList?.forEach((project: ISimplifiedProject) => {
    projectsToShow.push(
      <option value={project.id} key={project.id}>
        {project.name}
      </option>
    );
  });

  const tasksToShow: JSX.Element[] = [];

  tasksToShow.push(
    <option value="" key="None">
      None
    </option>
  );

  const sortedTasks = tasks
    ?.slice()
    ?.filter(task => !task.done)
    .sort((a, b) => {
      return a.name.localeCompare(b.name);
    });

  sortedTasks?.forEach((task: ITask) => {
    if (sessionProjectId) {
      if (task.projectId === sessionProjectId) {
        tasksToShow.push(
          <option value={task.id} key={task.id}>
            {task.name}
          </option>
        );
      }
    } else {
      tasksToShow.push(
        <option value={task.id} key={task.id}>
          {task.name}
        </option>
      );
    }
  });

  const sessionHabits = habits
    ?.filter(habit => habit.type === HabitType.SESSION && habit.isActive)
    .sort((a, b) => {
      return a.name.localeCompare(b.name);
    });

  const habitsToShow: JSX.Element[] = [];

  habitsToShow.push(
    <option value="" key="None">
      None
    </option>
  );

  sessionHabits?.forEach((habit: IHabit) => {
    habitsToShow.push(
      <option value={habit.id} key={habit.id}>
        {habit.name}
      </option>
    );
  });

  const labourTypesToShow: JSX.Element[] = [];

  labourTypesToShow.push(
    <option value="" key="None">
      None
    </option>
  );

  const sortedLabourTypes = labourTypes?.sort((a, b) => {
    return a.name.localeCompare(b.name);
  });

  sortedLabourTypes?.forEach((labourType: ILabourType) => {
    labourTypesToShow.push(
      <option value={labourType.id} key={labourType.id}>
        {labourType.name}
      </option>
    );
  });

  const titleText = project
    ? `Add a session for "${project.name}"`
    : 'Add a session';

  const selectedTags = getSelectedTags();

  return (
    <Dialog
      isOpen={props.modalIsOpen}
      onClose={handleClose}
      title={titleText}
      canOutsideClickClose={false}
      style={windowContext?.isMobile ? { width: '50vh' } : {}}
    >
      <div className={`bp4-dialog-body ${modalStyles.body}`}>
        <div>
          <p>
            Choose the date and time you want to retrospectively record a
            session for, then add the length of the session (in minutes).
          </p>
        </div>
        <div>
          <label className="bp4-label">
            <DatetimePicker
              id="add_session_datetime_picker"
              type={DatetimePickerType.datetime}
              label="Choose datetime"
              datetime={sessionTime}
              onDatetimeChange={handleSessionDatetimeChange}
              isFocused={focused}
              datetimeFormat={DatetimeFormat.DATETIME}
              canClear={false}
            />
          </label>
        </div>

        <div>
          <label className="bp4-label">
            Length of session:
            <input
              className="bp4-input bp4-fill"
              type="text"
              onChange={handleSessionDurationChange}
              value={sessionDuration}
              placeholder="in minutes"
              autoFocus
            />
          </label>
        </div>

        {simplifiedProjectList && simplifiedProjectList.length > 0 && (
          <label className="bp4-label">
            Associated project
            <div className="bp4-select">
              <select
                value={sessionProjectId ?? ''}
                onChange={handleProjectChange}
                disabled={!!projectToAddSessionTo}
              >
                {projectsToShow}
              </select>
            </div>
          </label>
        )}

        {tasksToShow && tasksToShow.length > 0 ? (
          <label className="bp4-label">
            Associated task
            <div className="bp4-select">
              <select
                value={sessionTaskId ?? ''}
                onChange={event => setSessionTaskId(event.target.value)}
                disabled={!!taskToAddSessionTo}
              >
                {tasksToShow}
              </select>
            </div>
          </label>
        ) : null}

        {habits && habits.length > 0 && (
          <label className="bp4-label">
            Associated habit
            <div className="bp4-select">
              <select
                value={sessionHabitId ?? ''}
                onChange={handleHabitChange}
                disabled={!!habitToAddSessionTo}
              >
                {habitsToShow}
              </select>
            </div>
          </label>
        )}

        <div>
          <label className="bp4-label">
            Notes:
            <MarkdownTextEditor
              initialValue={sessionNotes}
              onTextChange={handleNotesChange}
              placeholder="What did you do in this session?"
            />
          </label>
        </div>

        <div className={modalStyles.fieldDiv}>
          <TagSelector
            tags={tags}
            selectedTags={selectedTags}
            onTagSelect={handleTagSelect}
            onTagRemove={handleTagRemove}
          />
        </div>
      </div>

      <div className={`bp4-dialog-footer ${modalStyles.footer}`}>
        <Button onClick={handleClose}>Close</Button>
        <Button
          onClick={handleAddSession}
          intent={Intent.PRIMARY}
          loading={isSaving}
          disabled={!sessionDuration || !sessionTime}
        >
          Add session
        </Button>
      </div>
    </Dialog>
  );
};

export { AddSessionModal };
