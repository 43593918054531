import React, { useContext, useEffect, useState } from 'react';
import {
  Dialog,
  ButtonGroup,
  Button,
  Intent,
  NumericInput,
} from '@blueprintjs/core';
import { AlertsContext } from '../../state/AlertsContext';
import { useModalStyles } from '../../style/components/genericStyles';
import { HabitsContext } from '../../state/HabitsContext';
import {
  HabitCadenceUnit,
  HabitType,
  IApiCreateHabitRequest,
} from '../../common-src/types/Habit';
import { DateTime } from 'luxon';
import {
  DatetimeFormat,
  DatetimePicker,
  DatetimePickerType,
} from '../common/DatetimePicker/DatetimePicker';
import { ISimplifiedProject } from '../../common-src/types/Project';
import { ILabourType } from '../../common-src/types/LabourType';
import { IUnitType } from '../../common-src/types/UnitOfLabour';
import { ModalErrorList } from '../common/ModalErrorList';
import { IUiError } from '../../common-src/types/UiTypes';
import { NoUnitTypesError } from '../../error/errors';
import { WindowContext } from '../../state/WindowContext';
import { start } from 'repl';

interface IProps {
  modalIsOpen: boolean;
  onRequestClose: () => any;
  simplifiedProjectList?: ISimplifiedProject[];
  labourTypes: ILabourType[];
  unitTypes: IUnitType[];
}

const AddHabitModal: React.FunctionComponent<IProps> = (props: IProps) => {
  const { simplifiedProjectList, labourTypes, unitTypes } = props;
  const habitsContext = useContext(HabitsContext);
  const alertsContext = useContext(AlertsContext);
  const windowContext = useContext(WindowContext);
  const modalStyles = useModalStyles();

  const { createHabit, createCompletionHabit } = habitsContext!;

  const [habitName, setHabitName] = useState('');
  const [habitType, setHabitType] = useState<HabitType>(HabitType.SESSION);
  const [habitStartDate, setHabitStartDate] = useState<string | undefined>();
  const [habitEndDate, setHabitEndDate] = useState<string | undefined>();
  const [habitLabourTypeId, setHabitLabourTypeId] = useState<
    string | undefined
  >();
  const [habitMinutesPerDay, setHabitMinutesPerDay] = useState<
    number | undefined
  >(0);
  const [habitUnitTypeId, setHabitUnitTypeId] = useState<string | undefined>();
  const [habitUnitsPerDay, setHabitUnitsPerDay] = useState<
    number | undefined
  >();

  // cadence and dates
  const [cadenceUnit, setCadenceUnit] = useState<HabitCadenceUnit | undefined>(
    HabitCadenceUnit.DAY
  );
  const [cadenceRegularity, setCadenceRegularity] = useState<
    number | undefined
  >(1);

  const [isSaving, setIsSaving] = useState(false);
  const [errors, setErrors] = useState<IUiError[]>([]);

  const [isHabitStartDateFocused, setIsHabitStartDateFocused] = useState(false);
  const [isHabitEndDateFocused, setIsHabitEndDateFocused] = useState(false);

  useEffect(() => {
    if (
      habitType === HabitType.UNIT &&
      (!unitTypes || unitTypes.length === 0)
    ) {
      addError(NoUnitTypesError);
    } else {
      removeErrorById(NoUnitTypesError.id);
    }
  }, [habitType]);

  const addError = (error: IUiError) => {
    if (errors.find(e => e.id === error.id)) {
      return;
    }
    setErrors([...errors, error]);
  };

  const removeErrorById = (errorId: string) => {
    setErrors(errors.filter(e => e.id !== errorId));
  };

  const handleAddHabit = async event => {
    event.preventDefault();
    setIsSaving(true);

    if (habitName !== '') {
      try {
        if (habitType === HabitType.SESSION) {
          const createHabitRequest: IApiCreateHabitRequest = {
            name: habitName,
            habit_type: habitType,
            start_date: habitStartDate,
            end_date: habitEndDate,
            minutes_per_day: habitMinutesPerDay,
            labour_type_id: habitLabourTypeId,
          };
          await createHabit(createHabitRequest);
        } else if (habitType === HabitType.UNIT) {
          const createHabitRequest: IApiCreateHabitRequest = {
            name: habitName,
            habit_type: habitType,
            start_date: habitStartDate,
            end_date: habitEndDate,
            unit_type_id: habitUnitTypeId,
            units_per_day: habitUnitsPerDay,
            labour_type_id: habitLabourTypeId,
          };
          await createHabit(createHabitRequest);
        } else if (
          habitType === HabitType.HABIT &&
          cadenceUnit &&
          cadenceRegularity &&
          habitStartDate
        ) {
          await createCompletionHabit(
            habitName,
            cadenceUnit,
            cadenceRegularity,
            DateTime.fromISO(habitStartDate),
            habitEndDate ? DateTime.fromISO(habitEndDate) : undefined
          );
        }

        alertsContext!.addAlert(
          `Added new habit "${habitName}"`,
          Intent.SUCCESS
        );
      } catch (err) {
        alertsContext!.addAlert(
          `Error adding habit "${habitName}"`,
          Intent.DANGER
        );
      } finally {
        setIsSaving(false);
        handleClose();
      }
    }
  };

  const handleHabitNameChange = event => {
    setHabitName(event.target.value);
  };

  const handleHabitTypeChange = event => {
    setHabitType(event.target.value);
  };

  const handleStartDateChange = (date?: DateTime) => {
    setHabitStartDate(date?.toISO());
  };

  const handleStartDateError = (date: Date) => {
    setHabitStartDate(undefined);
  };

  const handleStartDateFocusChange = (arg: { focused: boolean }) => {
    setIsHabitStartDateFocused(arg.focused);
  };

  const handleEndDateChange = (date?: DateTime) => {
    setHabitEndDate(date?.toISO());
  };

  const handleEndDateFocusChange = (arg: { focused: boolean }) => {
    setIsHabitEndDateFocused(arg.focused);
  };

  const handleLabourTypeChange = event => {
    setHabitLabourTypeId(event.target.value);
  };

  const handleMinutesPerDayChange = event => {
    setHabitMinutesPerDay(event.target.value);
  };

  const handleUnitTypeChange = event => {
    setHabitUnitTypeId(event.target.value);
  };

  const handleUnitsPerDayChange = event => {
    setHabitUnitsPerDay(event.target.value);
  };

  const handleRegularityChange = value => {
    setCadenceRegularity(value);
  };

  const handleCadenceUnitChange = event => {
    setCadenceUnit(event.target.value);
  };

  const handleClose = () => {
    setHabitName('');
    props.onRequestClose();
  };

  const isValidUnitHabit = () => {
    return Boolean(
      habitType === HabitType.UNIT &&
        habitUnitTypeId !== undefined &&
        habitUnitsPerDay !== undefined
    );
  };

  const isValidSessionHabit = () => {
    return Boolean(habitType === HabitType.SESSION && !!habitMinutesPerDay);
  };

  const isDisabled =
    !habitName ||
    (habitType === HabitType.UNIT && !isValidUnitHabit()) ||
    (habitType === HabitType.SESSION && !isValidSessionHabit());

  const projectsToShow: JSX.Element[] = [];

  projectsToShow.push(
    <option value="" key="None">
      None
    </option>
  );

  simplifiedProjectList?.forEach((project: ISimplifiedProject) => {
    projectsToShow.push(
      <option value={project.id} key={project.id}>
        {project.name}
      </option>
    );
  });

  const labourTypesToShow: JSX.Element[] = [];

  labourTypesToShow.push(
    <option value="" key="None">
      None
    </option>
  );

  labourTypes.forEach((labourType: ILabourType) => {
    labourTypesToShow.push(
      <option value={labourType.id} key={labourType.id}>
        {labourType.name}
      </option>
    );
  });

  const unitTypesToShow: JSX.Element[] = [];

  unitTypesToShow.push(
    <option value="" key="None">
      None
    </option>
  );

  unitTypes.forEach((unitType: IUnitType) => {
    unitTypesToShow.push(
      <option value={unitType.id} key={unitType.id}>
        {unitType.name}
      </option>
    );
  });

  return (
    <Dialog
      isOpen={props.modalIsOpen}
      onClose={props.onRequestClose}
      title="Add new habit"
      canOutsideClickClose={false}
      style={windowContext?.isMobile ? { width: '50vh' } : {}}
    >
      <div className={`bp4-dialog-body ${modalStyles.body}`}>
        <ModalErrorList errors={errors} />
        <label className="bp4-label">
          Name
          <input
            className="bp4-input bp4-fill"
            type="text"
            onChange={handleHabitNameChange}
          />
        </label>
        <label className="bp4-label">
          Habit type
          <div className="bp4-select">
            <select value={habitType} onChange={handleHabitTypeChange}>
              <option value={HabitType.SESSION} key={HabitType.SESSION}>
                Session
              </option>
              <option value={HabitType.UNIT} key={HabitType.UNIT}>
                Unit
              </option>
              <option value={HabitType.HABIT} key={HabitType.HABIT}>
                Habit
              </option>
            </select>
          </div>
        </label>
        <DatetimePicker
          id="habit_start_date_picker"
          type={DatetimePickerType.date}
          label="Start date"
          placeholder="Enter the start date here (optional)"
          datetime={
            habitStartDate ? DateTime.fromISO(habitStartDate) : undefined
          }
          onDatetimeChange={handleStartDateChange}
          handleFocusChange={handleStartDateFocusChange}
          isFocused={isHabitStartDateFocused}
          datetimeFormat={DatetimeFormat.DATE}
          canClear={false}
        />
        <DatetimePicker
          id="habit_end_date_picker"
          type={DatetimePickerType.date}
          label="End date"
          placeholder="Enter the end date here (optional)"
          datetime={habitEndDate ? DateTime.fromISO(habitEndDate) : undefined}
          onDatetimeChange={handleEndDateChange}
          handleFocusChange={handleEndDateFocusChange}
          isFocused={isHabitEndDateFocused}
          datetimeFormat={DatetimeFormat.DATE}
          canClear={false}
        />
        {/* {labourTypes && labourTypes.length > 0 && (
          <label className="bp4-label">
            Associated work type
            <div className="bp4-select">
              <select
                value={habitLabourTypeId ?? ''}
                onChange={handleLabourTypeChange}
              >
                {labourTypesToShow}
              </select>
            </div>
          </label>
        )} */}

        {habitType === HabitType.SESSION ? (
          <div>
            <label className="bp4-label">
              Choose target daily minutes:
              <input
                className="bp4-input bp4-fill"
                type="text"
                onChange={handleMinutesPerDayChange}
                value={habitMinutesPerDay}
              />
            </label>
          </div>
        ) : null}
        {habitType === HabitType.UNIT ? (
          <>
            {unitTypes && unitTypes.length > 0 ? (
              <>
                <label className="bp4-label">
                  Associated unit type
                  <div className="bp4-select">
                    <select
                      value={habitUnitTypeId ?? ''}
                      onChange={handleUnitTypeChange}
                    >
                      {unitTypesToShow}
                    </select>
                  </div>
                </label>
                <label className="bp4-label">
                  Choose target daily units:
                  <input
                    className="bp4-input bp4-fill"
                    type="text"
                    onChange={handleUnitsPerDayChange}
                    value={habitUnitsPerDay}
                  />
                </label>
              </>
            ) : null}
          </>
        ) : null}
        {habitType === HabitType.HABIT && cadenceUnit ? (
          <>
            <label className="bp4-label">
              Cadence:
              <div className="bp4-select">
                <select value={cadenceUnit} onChange={handleCadenceUnitChange}>
                  <option
                    value={HabitCadenceUnit.MINUTE}
                    key={HabitCadenceUnit.MINUTE}
                  >
                    Minute-by-minute
                  </option>
                  <option
                    value={HabitCadenceUnit.HOUR}
                    key={HabitCadenceUnit.HOUR}
                  >
                    Hourly
                  </option>
                  <option
                    value={HabitCadenceUnit.DAY}
                    key={HabitCadenceUnit.DAY}
                  >
                    Daily
                  </option>
                  <option
                    value={HabitCadenceUnit.WEEK}
                    key={HabitCadenceUnit.WEEK}
                  >
                    Weekly
                  </option>
                  <option
                    value={HabitCadenceUnit.MONTH}
                    key={HabitCadenceUnit.MONTH}
                  >
                    Monthly
                  </option>
                  <option
                    value={HabitCadenceUnit.YEAR}
                    key={HabitCadenceUnit.YEAR}
                  >
                    Annually
                  </option>
                </select>
              </div>
            </label>
            <NumericInput
              min={0}
              value={cadenceRegularity}
              fill
              onValueChange={handleRegularityChange}
            />
            <p>{`You plan on doing this task ${cadenceRegularity} time(s) per ${cadenceUnit.toLowerCase()}.`}</p>
          </>
        ) : null}
      </div>
      <div className={`bp4-dialog-footer ${modalStyles.footer}`}>
        <ButtonGroup>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={handleAddHabit}
            intent={Intent.PRIMARY}
            loading={isSaving}
            disabled={isDisabled}
          >
            Add
          </Button>
        </ButtonGroup>
      </div>
    </Dialog>
  );
};

export { AddHabitModal };
