import React, { useState, useContext, useEffect } from 'react';
import { Dialog, Button, ButtonGroup, Intent } from '@blueprintjs/core';
import { TaskType } from '../../common-src/types/Task';
import { AlertsContext } from '../../state/AlertsContext';
import { useModalStyles } from '../../style/components/genericStyles';
import { WindowContext } from '../../state/WindowContext';
import { IProject } from '../../common-src/types/Project';

interface Props {
  project?: IProject;
  onRequestClose: () => void;
  modalIsOpen: boolean;
  addStandardTask: (taskName: string, projectId: string) => Promise<void>;
}

const AddTaskModal: React.FunctionComponent<Props> = (props: Props) => {
  const alertsContext = useContext(AlertsContext);
  const windowContext = useContext(WindowContext);
  const modalStyles = useModalStyles();

  const [taskName, setTaskName] = useState<string>('');
  const [taskType, setTaskType] = useState<TaskType>(TaskType.STANDARD);
  const [isFormValid, setIsFormValid] = useState<boolean>(false);

  const [isSaving, setIsSaving] = useState<boolean>(false);

  useEffect(() => {
    if (taskType === TaskType.STANDARD) {
      if (taskName === '') {
        setIsFormValid(false);
      } else {
        setIsFormValid(true);
      }
    } else {
      setIsFormValid(false);
    }
  }, [taskName, taskType]);

  if (!alertsContext || !windowContext) {
    return null;
  }

  const handleTextChange = event => {
    setTaskName(event.target.value);
  };

  const handleAddTask = async event => {
    event.preventDefault();

    setIsSaving(true);

    const { project, addStandardTask } = props;

    const name = taskName;

    if (taskType === TaskType.STANDARD) {
      if (name !== '' && project?.id) {
        try {
          await addStandardTask(name, project.id);
          alertsContext.addAlert(
            `Successfully added task "${name}"`,
            Intent.SUCCESS
          );
        } catch (error) {
          alertsContext.addAlert(`Failed to add task "${name}"`, Intent.DANGER);
        } finally {
          setIsSaving(false);
          handleClose();
        }
      }
    }
  };

  const handleClose = () => {
    setTaskName('');
    props.onRequestClose();
  };

  return (
    <Dialog
      isOpen={props.modalIsOpen}
      onClose={props.onRequestClose}
      title="Add a task"
      canOutsideClickClose={false}
      style={windowContext?.isMobile ? { width: '50vh' } : {}}
    >
      <div className={`bp4-dialog-body ${modalStyles.body}`}>
        <label className="bp4-label">
          Task name:
          <input
            className="bp4-input bp4-fill"
            type="text"
            onChange={handleTextChange}
            autoFocus
          />
        </label>
      </div>
      <div className={`bp4-dialog-footer ${modalStyles.footer}`}>
        <ButtonGroup>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={handleAddTask}
            intent={Intent.PRIMARY}
            disabled={!isFormValid}
            loading={isSaving}
          >
            Add
          </Button>
        </ButtonGroup>
      </div>
    </Dialog>
  );
};

export { AddTaskModal };
