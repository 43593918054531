import { createUseStyles } from 'react-jss';
import { chosenTheme } from '../styleHooks';

export const useNavbarTopStyles = createUseStyles({
  navQuote: {
    color: chosenTheme.navbarTop.fontColour,
  },

  navSettings: {
    color: chosenTheme.navbarTop.fontColour,
  },

  '@media (max-width: 920px)': {
    navQuote: {
      display: 'none',
    },
  },

  navbarTop: {
    backdropFilter: 'blur(10px)',
    backgroundColor: chosenTheme.navbarTop.navbarTopBgColour,
    position: 'fixed',
  },

  navMenuButton: {
    display: 'none',
    outline: 'none',
  },

  navbarTopFont: {
    color: chosenTheme.navbarTop.fontColour,
    a: {
      color: chosenTheme.navbarTop.fontColour,
    },
  },

  navSignOut: {
    color: chosenTheme.navbarTop.fontColour,
  },

  '@media only screen and (min-width: 320px) and (max-width: 480px)': {
    navSettings: {
      display: 'none',
    },
    navSignOut: {
      display: 'none',
    },
    navMenuButton: {
      display: 'block',
    },
  },
});

export const useNavbarSideStyles = createUseStyles({
  navbar: {
    paddingTop: 55,
    paddingBottom: 30,
    height: '100vh',
    overflowY: 'auto',
    flex: 2,
    background: `linear-gradient(${chosenTheme.navbarSide.navbarSideBgColourA} 0%, 75%, ${chosenTheme.navbarSide.navbarSideBgColourB} 99%)`,
  },
  '@media only screen and (min-width: 320px) and (max-width: 480px)': {
    navbar: {
      display: 'none',
    },
  },
  navbarItem: {
    backgroundColor: chosenTheme.navbarSide.navbarSideItemBackground,
    color: chosenTheme.navbarSide.navbarSideItemColour,
    '&:hover': {
      backgroundColor: chosenTheme.navbarSide.navbarSideItemHoverBackground,
    },
  },
  navbarItemActive: {
    backgroundColor: chosenTheme.navbarSide.navbarSideItemActiveBackground,
    '&:hover': {
      backgroundColor: chosenTheme.navbarSide.navbarSideItemHoverBackground,
    },
  },
});

export const useNavbarMobileStyles = createUseStyles({
  navbarMobile: {
    background: `linear-gradient(${chosenTheme.navbarSide.navbarSideBgColourA} 0%, 75%, ${chosenTheme.navbarSide.navbarSideBgColourB} 99%)`,
  },
});
