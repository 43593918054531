import { Button, ButtonGroup, Dialog, Intent } from '@blueprintjs/core';
import React, { useContext, useState } from 'react';
import { AlertsContext } from '../../state/AlertsContext';
import { useModalStyles } from '../../style/components/genericStyles';
import { IUnitType } from '../../common-src/types/UnitOfLabour';
import { UserContext } from '../../state/UserContext';
import { WindowContext } from '../../state/WindowContext';

interface IProps {
  modalIsOpen: boolean;
  onRequestClose: () => void;
  unitTypeToDelete: IUnitType;
}

const DeleteUnitTypeModal: React.FunctionComponent<IProps> = (
  props: IProps
) => {
  const userContext = useContext(UserContext);
  const alertsContext = useContext(AlertsContext);
  const windowContext = useContext(WindowContext);
  const modalStyles = useModalStyles();
  const { modalIsOpen, onRequestClose, unitTypeToDelete } = props;

  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const handleClose = () => {
    onRequestClose();
  };

  const handleDeletePhase = async event => {
    event.preventDefault();
    setIsDeleting(true);
    try {
      await userContext?.deleteUnitType(unitTypeToDelete.id);
      alertsContext!.addAlert(
        `Deleted unit "${unitTypeToDelete.name}"`,
        Intent.SUCCESS
      );
    } catch (err) {
      alertsContext!.addAlert(
        `Error deleting unit "${unitTypeToDelete.name}"`,
        Intent.DANGER
      );
    } finally {
      setIsDeleting(false);
      handleClose();
    }
  };

  return (
    <Dialog
      isOpen={modalIsOpen}
      onClose={onRequestClose}
      title={`Delete unit "${unitTypeToDelete.name}"`}
      canOutsideClickClose={false}
      style={windowContext?.isMobile ? { width: '50vh' } : {}}
    >
      <div className={`bp4-dialog-body ${modalStyles.body}`}>
        <p>Are you sure you want to delete this unit?</p>
      </div>

      <div className={`bp4-dialog-footer ${modalStyles.footer}`}>
        <ButtonGroup>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={handleDeletePhase}
            intent={Intent.DANGER}
            loading={isDeleting}
          >
            Do it!
          </Button>
        </ButtonGroup>
      </div>
    </Dialog>
  );
};

export { DeleteUnitTypeModal };
