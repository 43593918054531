import React, { useState, useContext, useEffect } from 'react';
import _ from 'lodash';
import { EditTaskModal } from '../modals/EditTaskModal';
import { ProjectsListContext } from '../../state/ProjectsListContext';
import { TasksContext } from '../../state/TasksContext';
import { TagsContext } from '../../state/TagsContext';
import { ITask } from '../../common-src/types/Task';
import { ITag } from '../../common-src/types/Tag';
import { useLocation } from 'react-router-dom';
import { useAllPageStyles } from '../../style/components/pageStyles';
import { TaskViewPreference } from '../../common-src/types/User';
import { TaskFilter } from '../Dashboard/TaskFilter';
import { UserContext } from '../../state/UserContext';
import { TaskBoard } from '../common/TaskBoard/TaskBoard';
import {
  handleBoardDragDrop,
  handleGenericStringIdsDragDrop,
  filterTasksByTags,
} from '../../func/task-ui-utils';
import { IPhase, IApiUpdatePhaseRequest } from '../../common-src/types/Project';
import { useLayoutStyles } from '../../style/components/layoutStyles';
import { TasksWithDueDates } from '../common/TasksWithDueDates/TasksWithDueDates';
import { isArraysEqual } from '../../func/utils';
import { ToDoList } from '../common/ToDoList/ToDoList';
import { Button } from '@blueprintjs/core';
import { AddPhaseModal } from '../modals/AddPhaseModal';
import { ComponentType, LoadingSkeleton } from '../common/LoadingSkeleton';
import { mapPhaseToUpdatePhaseRequest } from '../../client/apiMapper';
import { ModalContext } from '../../state/ModalContext';
import { TasksPageOnboarding } from '../common/onboarding/TasksPageOnboarding';

const TasksPage: React.FunctionComponent = () => {
  const projectsContext = useContext(ProjectsListContext);
  const tasksContext = useContext(TasksContext);
  const tagsContext = useContext(TagsContext);
  const userContext = useContext(UserContext);
  const modalContext = useContext(ModalContext);

  const allPageStyles = useAllPageStyles();
  const layoutStyles = useLayoutStyles();

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    document?.getElementById('main-scrollable')?.scrollTo(0, 0);
  }, [location]);

  const [tasksToShow, setTasksToShow] = useState<ITask[]>([]);
  const [todoListTaskIds, setTodoListTaskIds] = useState<string[] | null>(null);
  const [filterTags, setFilterTags] = useState<ITag[]>([]);

  const [taskView, setTaskView] = useState<TaskViewPreference>(
    TaskViewPreference.DUE_DATE
  );

  const [phasesInUI, setPhasesInUI] = useState<IPhase[] | null>(null);
  const [_phaseBeingDeleted, setPhaseBeingDeleted] = useState<IPhase | null>(
    null
  );
  const [_phaseBeingEdited, setPhaseBeingEdited] = useState<IPhase | null>(
    null
  );

  const [addPhaseModalIsOpen, setAddPhaseModalIsOpen] = useState(false);
  const [_editPhaseModalIsOpen, setEditPhaseModalIsOpen] = useState(false);
  const [_deletePhaseModalIsOpen, setDeletePhaseModalIsOpen] = useState(false);
  const [editTaskModalIsOpen, setEditTaskModalIsOpen] = useState(false);
  const [taskBeingEdited, setTaskBeingEdited] = useState<ITask | null>(null);

  useEffect(() => {
    if (tasksContext?.tasks) {
      const newTasksFilteredByTags = filterTasksByTags(
        tasksContext.tasks,
        filterTags
      );
      setTasksToShow(newTasksFilteredByTags);
    }
  }, [filterTags, tasksContext?.tasks]);

  useEffect(() => {
    if (userContext?.todoListTaskIds) {
      const newTodoListTaskIds = userContext.todoListTaskIds;
      const newTodoList = newTodoListTaskIds
        .map(taskId => tasksToShow.find(task => task.id === taskId))
        .filter(task => task !== undefined) as ITask[];
      const newTodoListFilteredByTags = filterTasksByTags(
        newTodoList,
        filterTags
      );
      setTodoListTaskIds(newTodoListFilteredByTags.map(task => task.id));
    }

    // how do we update the TasksByDueDate???
  }, [filterTags, userContext?.todoListTaskIds, tasksToShow]);

  useEffect(() => {
    if (userContext?.phases) {
      const newPhases = userContext.phases.map(phase => {
        const newTasks = filterTasksByTags(phase.tasks, filterTags);
        return {
          ...phase,
          tasks: newTasks,
        };
      });
      setPhasesInUI(newPhases);
    }
  }, [filterTags, userContext?.phases, tasksToShow]);

  if (
    !projectsContext ||
    !tasksContext ||
    !tagsContext ||
    !userContext ||
    !modalContext
  ) {
    return null;
  }

  const { projects } = projectsContext;
  const unarchivedProjects = projects?.filter(project => !project.archived);
  const { tasks, saveTask, isFetchingTasks } = tasksContext;
  const { tags } = tagsContext;
  const { updatePhases, updateTodoListTaskIds, addPhase } = userContext;
  const { openAddSessionModal } = modalContext;

  const tagsArr = tags ?? [];

  const openEditTaskModal = (task: ITask) => {
    setEditTaskModalIsOpen(true);
    setTaskBeingEdited(task);
  };

  const closeEditTaskModal = () => {
    setEditTaskModalIsOpen(false);
    setTaskBeingEdited(null);
  };

  const handleTaskViewChange = event => {
    setTaskView(event.target.value);
  };

  const handleTagSelect = (tag: ITag) => {
    const newListOfFilteredTags = filterTags.slice();
    newListOfFilteredTags.push(tag);
    setFilterTags(newListOfFilteredTags);
  };

  const handleTagRemove = (tagName: string) => {
    const tagToRemove = filterTags.find(tag => tag.name === tagName);
    if (tagToRemove) {
      const newListOfFilteredTags = filterTags.filter(
        tag => tag.id !== tagToRemove.id
      );
      setFilterTags(newListOfFilteredTags);
    }
  };

  const shouldShowOverallTaskProgressBar =
    tasks?.filter(task => !task.done && task.dueDate && task.startDate)
      .length !== 0;

  const onDragEndTodoList = (result: any) => {
    if (todoListTaskIds) {
      const newTodoListTaskIds = handleGenericStringIdsDragDrop(
        result,
        todoListTaskIds
      );
      if (newTodoListTaskIds) {
        setTodoListTaskIds(newTodoListTaskIds);
        updateTodoListTaskIds(newTodoListTaskIds);
      }
    }
  };

  const onDragEndBoard = async (result: any) => {
    const movedTask: ITask | undefined = tasks?.find(
      task => task.id === result.draggableId
    );
    const movedPhase: IPhase | undefined = phasesInUI?.find(
      phase => phase.id === result.draggableId
    );
    if (phasesInUI && (movedTask || movedPhase)) {
      const newPhases = movedTask
        ? handleBoardDragDrop(result, phasesInUI, movedTask)
        : movedPhase
        ? handleBoardDragDrop(result, phasesInUI, movedPhase)
        : phasesInUI;
      if (newPhases && !isArraysEqual(newPhases, phasesInUI)) {
        setPhasesInUI(newPhases);
        const newPhasesRequest: IApiUpdatePhaseRequest[] = newPhases.map(
          mapPhaseToUpdatePhaseRequest
        );
        updatePhases(newPhasesRequest);
      }
    }
  };

  const openAddPhaseModal = () => {
    setAddPhaseModalIsOpen(true);
  };

  const closeAddPhaseModal = () => {
    setAddPhaseModalIsOpen(false);
  };

  const openEditPhaseModal = (phase: IPhase) => {
    setPhaseBeingEdited(phase);
    setEditPhaseModalIsOpen(true);
  };

  const openDeletePhaseModal = (phaseToDelete: IPhase) => {
    setPhaseBeingDeleted(phaseToDelete);
    setDeletePhaseModalIsOpen(true);
  };

  const isLoading = isFetchingTasks;
  const isDragDisabled = filterTags.length > 0;

  return (
    <div className={allPageStyles.genericPage}>
      <h1>Your tasks</h1>
      <div className={`${layoutStyles.col}`}>
        <div className={layoutStyles.col_cell}>
          <div className={layoutStyles.grid_2_thirds_col}>
            {isFetchingTasks ? (
              <div>
                <LoadingSkeleton
                  type={ComponentType.GenericBoxWithText}
                  maxWidth={1000}
                />
              </div>
            ) : (
              <TaskFilter
                taskView={taskView}
                onTaskViewChange={handleTaskViewChange}
                tagsArr={tagsArr}
                filterTags={filterTags}
                onTagSelect={handleTagSelect}
                onTagRemove={handleTagRemove}
              />
            )}
          </div>
        </div>
        <div className={layoutStyles.col_cell}>
          {isFetchingTasks ? (
            <div>
              <LoadingSkeleton type={ComponentType.TodoList} maxWidth={700} />
            </div>
          ) : (
            <>
              {taskView === TaskViewPreference.DUE_DATE ? (
                <TasksWithDueDates
                  tasks={tasksToShow}
                  openEditTaskModal={openEditTaskModal}
                  openAddSessionModal={openAddSessionModal}
                />
              ) : taskView === TaskViewPreference.BOARD ? (
                <div>
                  {phasesInUI && (
                    <>
                      <div className={layoutStyles.toolbar}>
                        <Button icon="plus" onClick={openAddPhaseModal} minimal>
                          Add phase
                        </Button>
                      </div>
                      <TaskBoard
                        level="user"
                        tasks={tasksToShow}
                        phases={phasesInUI}
                        onDragEnd={onDragEndBoard}
                        isDragDisabled={isDragDisabled}
                        openEditTaskModal={openEditTaskModal}
                        openEditPhaseModal={openEditPhaseModal}
                        openDeletePhaseModal={openDeletePhaseModal}
                        openAddSessionModal={openAddSessionModal}
                        isLoading={isLoading}
                        showArchivedTasks={false}
                      />
                    </>
                  )}
                </div>
              ) : (
                <div>
                  {todoListTaskIds && (
                    <ToDoList
                      todoListTaskIds={todoListTaskIds}
                      openEditTaskModal={openEditTaskModal}
                      openAddSessionModal={openAddSessionModal}
                      onDragEnd={onDragEndTodoList}
                      isDragDisabled={isDragDisabled}
                      showArchivedTasks={false}
                    />
                  )}
                </div>
              )}
            </>
          )}
        </div>
        <TasksPageOnboarding />
        <EditTaskModal
          modalIsOpen={editTaskModalIsOpen}
          onRequestClose={closeEditTaskModal}
          task={taskBeingEdited!}
          saveTask={saveTask!}
          tags={tagsArr}
          simplifiedProjectList={unarchivedProjects ?? []}
        />
        <AddPhaseModal
          level="user"
          modalIsOpen={addPhaseModalIsOpen}
          onRequestClose={closeAddPhaseModal}
          addUserPhase={addPhase}
        />
      </div>
    </div>
  );
};

export { TasksPage };
