import { createUseStyles } from 'react-jss';
import { chosenTheme } from '../styleHooks';

export const useModalStyles = createUseStyles({
  body: {
    textAlign: 'left',
  },
  footer: {
    textAlign: 'left',
  },
  fieldDiv: {
    paddingTop: 5,
    paddingBottom: 5,
  },
});

export const useGenericStyles = createUseStyles({
  genericBox: {
    backgroundColor: chosenTheme.genericComponentBackgroundColour,
    color: chosenTheme.genericComponentPrimaryFontColour,
    padding: '20px',
    'margin-bottom': 20,
    borderRadius: 2,
    WebkitBoxShadow: '6px 22px 39px -14px rgba(0, 0, 0, 0.15)',
    MozBoxShadow: '6px 22px 39px -14px rgba(0, 0, 0, 0.15)',
    boxShadow: '6px 22px 39px -14px rgba(0, 0, 0, 0.15)',
    '& h1, h2, p, span': {
      textAlign: 'left',
    },
    '& h1, h2, p': {
      textAlign: 'left',
      marginBottom: 2,
    },
    '& h1': {
      marginBottom: 2,
    },
    zIndex: 1,
  },
  genericBoxHeader: {
    textAlign: 'left',
  },
  genericBoxSection: {
    textAlign: 'left',
  },
});
