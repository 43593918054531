import { Button, ButtonGroup, Dialog, Intent } from '@blueprintjs/core';
import React, { useContext, useState } from 'react';
import { IPhase, ISimplifiedPhase } from '../../common-src/types/Project';
import { AlertsContext } from '../../state/AlertsContext';
import { ProjectsListContext } from '../../state/ProjectsListContext';
import { useModalStyles } from '../../style/components/genericStyles';
import {
  IApiUpdateUnitTypeRequest,
  IUnitType,
} from '../../common-src/types/UnitOfLabour';
import { UserContext } from '../../state/UserContext';
import { WindowContext } from '../../state/WindowContext';

interface IProps {
  modalIsOpen: boolean;
  onRequestClose: () => void;
  unitType: IUnitType;
}

const EditUnitTypeModal: React.FunctionComponent<IProps> = (props: IProps) => {
  const projectsContext = useContext(ProjectsListContext);
  const userContext = useContext(UserContext);
  const alertsContext = useContext(AlertsContext);
  const windowContext = useContext(WindowContext);
  const modalStyles = useModalStyles();
  const { modalIsOpen, onRequestClose, unitType } = props;

  const [unitTypeName, setUnitTypeName] = useState(unitType.name);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const handleUnitTypeNameChange = event => {
    setUnitTypeName(event.target.value);
  };

  const handleClose = () => {
    setUnitTypeName('');
    onRequestClose();
  };

  const handleUpdateUnitType = async event => {
    event.preventDefault();
    setIsSaving(true);

    const updateUnitTypeRequest: IApiUpdateUnitTypeRequest = {
      name: unitTypeName,
    };

    if (unitTypeName !== '') {
      try {
        await userContext?.updateUnitType(unitType.id, updateUnitTypeRequest);
        alertsContext!.addAlert(
          `Updated the unit name to "${unitTypeName}"`,
          Intent.SUCCESS
        );
      } catch (err) {
        alertsContext!.addAlert(
          `Error updating unit "${unitTypeName}"`,
          Intent.DANGER
        );
      } finally {
        setIsSaving(false);
        handleClose();
      }
    }
  };

  return (
    <Dialog
      isOpen={modalIsOpen}
      onClose={onRequestClose}
      title={`Edit unit "${unitType.name}"`}
      canOutsideClickClose={false}
      style={windowContext?.isMobile ? { width: '50vh' } : {}}
    >
      <div className={`bp4-dialog-body ${modalStyles.body}`}>
        <label className="bp4-label">
          Name for unit type
          <input
            className="bp4-input bp4-fill"
            type="text"
            onChange={handleUnitTypeNameChange}
            value={unitTypeName}
          />
        </label>
      </div>

      <div className={`bp4-dialog-footer ${modalStyles.footer}`}>
        <ButtonGroup>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={handleUpdateUnitType}
            intent={Intent.PRIMARY}
            loading={isSaving}
          >
            Save
          </Button>
        </ButtonGroup>
      </div>
    </Dialog>
  );
};

export { EditUnitTypeModal };
