import React from 'react';
import { makeMinutesReadable } from '../../../func/readability-utils';

interface IProps {
  estimatedMinutes: number;
  totalMinutesTracked: number;
  isTaskDone: boolean;
}

const TaskEstimates: React.FunctionComponent<IProps> = (props: IProps) => {
  const { estimatedMinutes, totalMinutesTracked, isTaskDone } = props;

  const getText = () => {
    if (isTaskDone) {
      if (totalMinutesTracked < estimatedMinutes) {
        return `Completed ${makeMinutesReadable(
          estimatedMinutes - totalMinutesTracked
        )} under estimated time`;
      } else if (totalMinutesTracked > estimatedMinutes) {
        return `Completed ${makeMinutesReadable(
          totalMinutesTracked - estimatedMinutes
        )} over estimated time`;
      } else {
        return 'Completed exactly on estimated time';
      }
    } else {
      if (totalMinutesTracked === 0) {
        return `Estimated at ${makeMinutesReadable(estimatedMinutes)}`;
      } else if (totalMinutesTracked < estimatedMinutes) {
        return `Estimated ${makeMinutesReadable(
          estimatedMinutes - totalMinutesTracked
        )} left to complete`;
      } else if (totalMinutesTracked > estimatedMinutes) {
        return `Estimated ${makeMinutesReadable(
          totalMinutesTracked - estimatedMinutes
        )} over`;
      } else {
        return 'Worked exactly the estimated time';
      }
    }
  };

  const text = getText();

  return (
    <div>
      <em>{text}</em>
    </div>
  );
};

export { TaskEstimates };
