import { DateTime } from 'luxon';
import {
  getDatesInRange,
  getDateSessionTuplesInRange,
  getDateUnitTuplesInRange,
  getCompletionDateTuplesInRange,
} from '../common-src/productivity';
import {
  DateAndValueTuple,
  IAreaChartData,
  IAreaChartMetric,
  IHabitCompletionData,
  ISessionData,
  IUnitData,
} from '../common-src/types/ChartTypes';
import { scaleLinear } from '@visx/scale';
import { ScaleLinear } from 'd3';
import * as d3 from 'd3';
import { ISession } from '../common-src/types/Session';
import { IUnitOfLabour } from '../common-src/types/UnitOfLabour';

export function getAreaChartData(
  startDate: DateTime,
  endDate: DateTime,
  opts: {
    sessions?: ISession[];
    sessionsTarget?: number;
    units?: IUnitOfLabour[];
    unitsTarget?: number;
    completions?: string[];
    completionDailyTarget?: number;
    name?: string;
    title?: string;
  }
): IAreaChartData {
  const {
    sessions,
    sessionsTarget,
    units,
    unitsTarget,
    completions,
    completionDailyTarget,
    name,
    title,
  } = opts;
  const dateSeries = getDatesInRange(startDate, endDate);
  const metrics: IAreaChartMetric[] = [];
  let sessionDateTuples: DateAndValueTuple[] = [];

  if (sessions) {
    sessionDateTuples = getDateSessionTuplesInRange(sessions, dateSeries);
    metrics.push({
      name: name ?? 'Sessions',
      type: 'SESSIONS',
      dateValueTuples: sessionDateTuples,
      isTarget: false,
      colour: '#8884d8',
    });
  }

  if (sessionsTarget) {
    metrics.push({
      name: 'Sessions Target',
      type: 'SESSIONS',
      dateValueTuples: dateSeries.map(d => [d, sessionsTarget]),
      isTarget: true,
      colour: '#8884d8',
    });
  }

  if (units) {
    const unitDateTuples = getDateUnitTuplesInRange(units, dateSeries);
    metrics.push({
      name: name ?? 'Units',
      type: 'UNITS',
      dateValueTuples: unitDateTuples,
      isTarget: false,
      colour: '#82ca9d',
    });
  }

  if (unitsTarget) {
    metrics.push({
      name: 'Units Target',
      type: 'UNITS',
      dateValueTuples: dateSeries.map(d => [d, unitsTarget]),
      isTarget: true,
      colour: '#82ca9d',
    });
  }

  if (completions) {
    const completionDateTuples = getCompletionDateTuplesInRange(
      completions,
      dateSeries
    );
    metrics.push({
      name: name ?? 'Completions',
      type: 'COMPLETIONS',
      dateValueTuples: completionDateTuples,
      isTarget: false,
      colour: '#82ca9d',
    });
  }

  if (completionDailyTarget) {
    metrics.push({
      name: 'Units Target',
      type: 'UNITS',
      dateValueTuples: dateSeries.map(d => [d, completionDailyTarget]),
      isTarget: true,
      colour: '#82ca9d',
    });
  }

  return {
    metrics,
    dateSeries,
    title,
  };
}

export function getYScaleForSessions(
  sessionData: ISessionData,
  yMax: number
): ScaleLinear<number, number, never> | undefined {
  if (sessionData) {
    const { sessions } = sessionData;
    const maxSessions = Math.max(
      d3.max(sessions?.map(session => session[1])) || 0
    );
    const yScaleForSessions = scaleLinear({
      domain: [0, maxSessions!],
      range: [yMax, 0],
      nice: true,
    });
    return yScaleForSessions;
  }
}

export function getYScaleForHabitCompletions(
  habitCompletionData: IHabitCompletionData,
  yMax: number
): ScaleLinear<number, number, never> | undefined {
  if (habitCompletionData) {
    const { habitCompletions } = habitCompletionData;
    const maxCompletions = Math.max(
      d3.max(habitCompletions?.map(habitCompletion => habitCompletion[1])) || 0
    );
    const yScaleForCompletions = scaleLinear({
      domain: [0, maxCompletions!],
      range: [yMax, 0],
      nice: true,
    });
    return yScaleForCompletions;
  }
}

export function getYScaleForAllSessions(
  sessionDataArr: ISessionData[],
  yMax: number
): ScaleLinear<number, number, never> | undefined {
  if (sessionDataArr) {
    const maxSessions = Math.max(
      ...sessionDataArr.map(sessionData => {
        const { sessions } = sessionData;
        return Math.max(d3.max(sessions?.map(session => session[1])) || 0);
      })
    );
    const yScaleForAllSessions = scaleLinear({
      domain: [0, maxSessions!],
      range: [yMax, 0],
      nice: true,
    });
    return yScaleForAllSessions;
  }
}

export function getYScaleForUnits(
  unitData: IUnitData,
  yMax: number
): ScaleLinear<number, number, never> | undefined {
  if (unitData) {
    const { units } = unitData;
    const maxUnits = Math.max(d3.max(units?.map(unit => unit[1]))!) + 5; // TODO: fix exclam
    const yScaleForUnits = scaleLinear({
      domain: [0, maxUnits!],
      range: [yMax, 0],
      nice: true,
    });
    return yScaleForUnits;
  }
}

export function getYScaleForUnitAndTarget(
  unitData: IUnitData,
  unitTarget: number,
  yMax: number
): ScaleLinear<number, number, never> | undefined {
  const { units } = unitData;
  const maxUnits =
    Math.max(d3.max(units?.map(unit => unit[1]))!, unitTarget) + 5; // TODO: fix exclam
  const yScaleForUnits = scaleLinear({
    domain: [0, maxUnits!],
    range: [yMax, 0],
    nice: true,
  });
  return yScaleForUnits;
}
