import { DateTime } from 'luxon';
import { HabitCadenceUnit } from './types/Habit';
import { IHabitConsistencyData, ITask, TaskType } from './types/Task';

/**
 * For a given habit task, returns the level of consistency with which the user has stuck to it.
 *
 * This function makes the assumption that you want the consistency of the habit from the start date until now
 * or the end date (if it exists and is earlier than now)
 */
export function getHabitConsistency(task: ITask): number {
  const { habit } = task;
  if ((task.type && task.type !== TaskType.HABIT) || !habit) {
    return 0;
  }
  const { cadence, completions, startDate, endDate } = habit;
  if (completions.length === 0) {
    return 0;
  }
  let trueEndDateString = '';
  const now = DateTime.now();
  if (endDate && DateTime.fromISO(endDate).toMillis() < now.toMillis()) {
    trueEndDateString = endDate;
  } else {
    trueEndDateString = now.toISO();
  }
  const trueEndDate = DateTime.fromISO(trueEndDateString);
  const trueStartDate = DateTime.fromISO(startDate);
  if (trueStartDate.toMillis() > trueEndDate.toMillis()) {
    return 0;
  }
  const millisecondsBetween = trueEndDate.diff(
    trueStartDate,
    'milliseconds'
  ).milliseconds;
  let targetCompletionsPerMillisecond = 0;

  if (cadence.unit === HabitCadenceUnit.MINUTE) {
    targetCompletionsPerMillisecond = cadence.regularity / (60 * 1000);
  } else if (cadence.unit === HabitCadenceUnit.HOUR) {
    targetCompletionsPerMillisecond = cadence.regularity / (60 * 60 * 1000);
  } else if (cadence.unit === HabitCadenceUnit.DAY) {
    targetCompletionsPerMillisecond =
      cadence.regularity / (24 * 60 * 60 * 1000);
  } else if (cadence.unit === HabitCadenceUnit.WEEK) {
    targetCompletionsPerMillisecond =
      cadence.regularity / (7 * 24 * 60 * 60 * 1000);
  } else if (cadence.unit === HabitCadenceUnit.MONTH) {
    targetCompletionsPerMillisecond =
      cadence.regularity / (30 * 24 * 60 * 60 * 1000);
  } else if (cadence.unit === HabitCadenceUnit.YEAR) {
    targetCompletionsPerMillisecond =
      cadence.regularity / (365 * 24 * 60 * 60 * 1000);
  }

  const actualCompletionsPerMillisecond =
    completions.length / millisecondsBetween;

  const consistency =
    actualCompletionsPerMillisecond / targetCompletionsPerMillisecond;
  return consistency;
}

export function getHabitConsistencyForMultipleTasks(tasks: ITask[]): number {
  const consistencySum = tasks
    .map(task => getHabitConsistency(task))
    .reduce((acc, curr) => {
      return acc + curr;
    });
  return consistencySum / tasks.length;
}

export function getHabitConsistencyData(task: ITask): IHabitConsistencyData {
  const { habit } = task;
  if ((task.type && task.type !== TaskType.HABIT) || !habit) {
    return {
      consistency: 0,
      targetCadence: {
        unit: HabitCadenceUnit.DAY,
        regularity: 1,
      },
      actualCadence: {
        unit: HabitCadenceUnit.DAY,
        regularity: 0,
      },
    };
  }
  const consistency = getHabitConsistency(task);
  return {
    consistency,
    targetCadence: {
      unit: habit.cadence.unit,
      regularity: habit.cadence.regularity,
    },
    actualCadence: {
      unit: habit.cadence.unit,
      regularity: habit.cadence.regularity * consistency,
    },
  };
}

export function getHabitConsistencyDataForMultipleTasks(
  task: ITask[]
): IHabitConsistencyData[] {
  return task.map(task => getHabitConsistencyData(task));
}
