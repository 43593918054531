import React from 'react';
import {
  dynamicWhatNeedsToBeDoneForAllProjects,
  dynamicWhatNeedsToBeDoneForAllTasks,
  dynamicWhatNeedsToBeDoneForHabit,
  dynamicWhatNeedsToBeDoneForMultipleHabits,
  getWhatNeedsToBeDoneForAllHabits,
} from '../../func/textChunks';
import { IProject, ISimplifiedProject } from '../../common-src/types/Project';
import { TrackingPeriod } from '../../common-src/types/User';
import { ITextChunk } from '../../common-src/types/UiTypes';
import {
  IButlerAssessment,
  IHabitSummary,
  IProjectSummary,
  IStatusOfAllHabits,
} from '../../common-src/types/Reporting';
import { renderTextChunks } from '../../func/ui-utils';
import { IHabit } from '../../common-src/types/Habit';

interface Props {
  type:
    | 'PROJECT'
    | 'MULTIPLE_PROJECTS'
    | 'HABIT'
    | 'MULTIPLE_HABITS'
    | 'OVERALL';
  butlerAssessment?: IButlerAssessment;
  statusOfAllHabits?: IStatusOfAllHabits;
  habit?: IHabit;
  habitSummary?: IHabitSummary;
  project?: ISimplifiedProject | IProject;
  projectSummary?: IProjectSummary;
  trackingPeriod: TrackingPeriod;
  customTrackingPeriodInDays?: number;
}

const WhatNeedsToBeDone: React.FunctionComponent<Props> = (props: Props) => {
  const {
    type,
    project,
    projectSummary,
    trackingPeriod,
    butlerAssessment,
    customTrackingPeriodInDays,
    statusOfAllHabits,
    habit,
    habitSummary,
  } = props;

  if (
    (type === 'HABIT' && !habitSummary) ||
    (type === 'MULTIPLE_HABITS' && !statusOfAllHabits) ||
    (type === 'OVERALL' && !butlerAssessment)
  ) {
    return null;
  }

  let whatNeedsToBeDoneStringArray: ITextChunk[] = [];

  if (type === 'OVERALL' && butlerAssessment) {
    const projectsAdvice = dynamicWhatNeedsToBeDoneForAllProjects(
      butlerAssessment.projectsAdvice,
      butlerAssessment.projectsStatus
    );
    const tasksAdvice = dynamicWhatNeedsToBeDoneForAllTasks(
      butlerAssessment.tasksAdvice,
      butlerAssessment.tasksStatus
    );
    const habitsAdvice = getWhatNeedsToBeDoneForAllHabits(
      butlerAssessment.habitsAdvice,
      butlerAssessment.habitsStatus,
      trackingPeriod,
      customTrackingPeriodInDays
    );
    whatNeedsToBeDoneStringArray = [
      ...projectsAdvice,
      { text: '', beginOnNewLine: true },
      ...tasksAdvice,
      { text: '', beginOnNewLine: true },
      ...habitsAdvice,
      { text: '', beginOnNewLine: true },
      { text: '', beginOnNewLine: true },
    ];
  } else if (type === 'PROJECT' && project && projectSummary) {
    // do nothing for now
  } else if (type === 'MULTIPLE_PROJECTS' && statusOfAllHabits) {
    whatNeedsToBeDoneStringArray =
      dynamicWhatNeedsToBeDoneForMultipleHabits(statusOfAllHabits);
  } else if (type === 'HABIT' && habitSummary && habit) {
    whatNeedsToBeDoneStringArray = dynamicWhatNeedsToBeDoneForHabit(
      habit,
      habitSummary,
      trackingPeriod,
      customTrackingPeriodInDays
    );
  } else if (type === 'MULTIPLE_HABITS' && statusOfAllHabits) {
    whatNeedsToBeDoneStringArray =
      dynamicWhatNeedsToBeDoneForMultipleHabits(statusOfAllHabits);
  }

  return <p>{renderTextChunks(whatNeedsToBeDoneStringArray)}</p>;
};

export { WhatNeedsToBeDone };
