import React, { useContext, useState } from 'react';
import { Dialog, Button, ButtonGroup, Intent } from '@blueprintjs/core';
import { ITask } from '../../common-src/types/Task';
import { AlertsContext } from '../../state/AlertsContext';
import { ModalContext } from '../../state/ModalContext';
import { ProjectsListContext } from '../../state/ProjectsListContext';
import { useModalStyles } from '../../style/components/genericStyles';
import { WindowContext } from '../../state/WindowContext';

interface Props {
  onRequestClose: () => void;
  modalIsOpen: boolean;
  deleteTask: (task: ITask) => Promise<void>;
}

const DeleteTaskModal: React.FunctionComponent<Props> = (props: Props) => {
  const alertsContext = useContext(AlertsContext);
  const modalContext = useContext(ModalContext);
  const projectContext = useContext(ProjectsListContext);
  const windowContext = useContext(WindowContext);
  const modalStyles = useModalStyles();

  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  if (!modalContext || !projectContext || !alertsContext) {
    return null;
  }

  const handleDeleteTask = async event => {
    event.preventDefault();
    setIsDeleting(true);
    const { taskToDelete } = modalContext;
    const { deleteTask } = props;

    if (!taskToDelete) {
      return;
    }

    try {
      await deleteTask(taskToDelete);
      alertsContext.addAlert(
        `Deleted task "${taskToDelete!.name}"`,
        Intent.SUCCESS
      );
    } catch (err) {
      alertsContext.addAlert(
        `Error deleting task "${taskToDelete!.name}"`,
        Intent.DANGER
      );
    } finally {
      setIsDeleting(false);
      handleClose();
    }
  };

  const handleClose = () => {
    props.onRequestClose();
  };

  const { taskToDelete } = modalContext!;

  return (
    <Dialog
      isOpen={props.modalIsOpen}
      onClose={props.onRequestClose}
      title={taskToDelete && `Delete task "${taskToDelete.name}"`}
      canOutsideClickClose={false}
      style={windowContext?.isMobile ? { width: '50vh' } : {}}
    >
      <div className={`bp4-dialog-body ${modalStyles.body}`}>
        <p>Are you sure you want to delete this task?</p>
      </div>
      <div className={`bp4-dialog-footer ${modalStyles.footer}`}>
        <ButtonGroup>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={handleDeleteTask}
            intent={Intent.DANGER}
            loading={isDeleting}
          >
            Do it!
          </Button>
        </ButtonGroup>
      </div>
    </Dialog>
  );
};

export { DeleteTaskModal };
