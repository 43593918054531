import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../state/UserContext';
import { useLocation } from 'react-router-dom';
import { WindowContext } from '../../state/WindowContext';
import { getStartAndEndDatesForTrackingPeriod } from '../../common-src/productivity';
import { useAllPageStyles } from '../../style/components/pageStyles';
import { useLayoutStyles } from '../../style/components/layoutStyles';
import { HabitsContext } from '../../state/HabitsContext';
import { ProductivityContext } from '../../state/ProductivityContext';
import { ComponentType, LoadingSkeleton } from '../common/LoadingSkeleton';
import { Button } from '@blueprintjs/core';
import { ModalContext } from '../../state/ModalContext';
import { HabitSummary } from './HabitSummary';
import { AllHabitsSummary } from './AllHabitsSummary';
import { ProjectsListContext } from '../../state/ProjectsListContext';
import { IHabit } from '../../common-src/types/Habit';
import { TasksContext } from '../../state/TasksContext';
import { getHabitScoreText } from '../../func/textChunks';
import { renderTextChunks } from '../../func/ui-utils';
import { IStatusOfAllHabits } from '../../common-src/types/Reporting';
import { HabitsPageOnboarding } from '../common/onboarding/HabitsPageOnboarding';

const HabitsPage: React.FunctionComponent = () => {
  const userContext = useContext(UserContext);
  const habitsContext = useContext(HabitsContext);
  const windowContext = useContext(WindowContext);
  const productivityContext = useContext(ProductivityContext);
  const projectListContext = useContext(ProjectsListContext);
  const tasksContext = useContext(TasksContext);
  const modalContext = useContext(ModalContext);

  const location = useLocation();
  const allPageStyles = useAllPageStyles();
  const layoutStyles = useLayoutStyles();

  const [habitsToShow, setHabitsToShow] = useState<IHabit[]>([]);
  const [statusOfAllHabitsToShow, setStatusOfAllHabitsToShow] = useState<
    IStatusOfAllHabits | undefined
  >(undefined);

  useEffect(() => {
    if (habits) {
      setHabitsToShow(habits);
    }
  }, [habitsContext?.habits]);

  useEffect(() => {
    if (butlerAssessment) {
      setStatusOfAllHabitsToShow(butlerAssessment.habitsStatus);
    }
  }, [productivityContext?.butlerAssessment]);

  useEffect(() => {
    window.scrollTo(0, 0);
    document?.getElementById('main-scrollable')?.scrollTo(0, 0);
  }, [location]);

  if (
    !userContext ||
    !habitsContext ||
    !windowContext ||
    !productivityContext ||
    !projectListContext ||
    !tasksContext ||
    !modalContext
  ) {
    return null;
  }

  const { trackingPeriod, customTrackingPeriod } = userContext;
  const { habits, isFetchingHabits } = habitsContext;
  const { butlerAssessment, isFetchingButlerAssessment } = productivityContext;
  const {
    openAddHabitModal,
    openEditHabitModal,
    openEditCompletionHabitModal,
    openDeleteHabitModal,
    openAddSessionModal,
  } = modalContext;

  const habitScoreText = trackingPeriod
    ? getHabitScoreText(trackingPeriod, customTrackingPeriod)
    : [];

  const startAndEndDates = trackingPeriod
    ? getStartAndEndDatesForTrackingPeriod(trackingPeriod, customTrackingPeriod)
    : [undefined, undefined];
  const start = startAndEndDates[0];
  const end = startAndEndDates[1];

  const shouldShowOverallProductivityChart =
    habitsToShow?.filter(habit => habit.isActive).length &&
    habitsToShow?.length > 0;

  return (
    <div className={allPageStyles.genericPage}>
      <h1>Habits</h1>
      {isFetchingHabits || isFetchingButlerAssessment ? (
        <>
          <div>
            <LoadingSkeleton
              type={ComponentType.DashboardSummary}
              minHeight={364}
              maxHeight={364}
            />
          </div>
          <div
            className={`${layoutStyles.grid_3_col} ${layoutStyles.spaced_col}`}
          >
            <LoadingSkeleton
              type={ComponentType.GoalSummary}
              minHeight={240}
              maxHeight={240}
            />
            <LoadingSkeleton
              type={ComponentType.GoalSummary}
              minHeight={240}
              maxHeight={240}
            />
            <LoadingSkeleton
              type={ComponentType.GoalSummary}
              minHeight={240}
              maxHeight={240}
            />
            <LoadingSkeleton
              type={ComponentType.GoalSummary}
              minHeight={240}
              maxHeight={240}
            />
          </div>
        </>
      ) : (
        <>
          <div className={layoutStyles.toolbar}>
            <Button icon="plus" minimal onClick={openAddHabitModal}>
              Add habit
            </Button>
          </div>
          <>
            {shouldShowOverallProductivityChart && butlerAssessment ? (
              <AllHabitsSummary
                allHabitsSummaryString={renderTextChunks(habitScoreText)}
                statusOfAllHabits={butlerAssessment.habitsStatus}
              />
            ) : (
              <div>You don't currently have any habits.</div>
            )}
          </>
          <>
            <div
              className={`${layoutStyles.grid_3_col} ${layoutStyles.spaced_col}`}
            >
              {habitsToShow
                ?.sort((a, b) => a.name.localeCompare(b.name))
                .map(habit => {
                  const habitSummary =
                    butlerAssessment?.habitsStatus?.summaries?.find(
                      summary => summary.habitId === habit.id
                    );
                  return (
                    start &&
                    end && (
                      <div key={habit.id}>
                        <HabitSummary
                          habit={habit}
                          habitSummary={habitSummary}
                          startDate={start}
                          endDate={end}
                          openEditHabitModal={openEditHabitModal}
                          openEditCompletionHabitModal={
                            openEditCompletionHabitModal
                          }
                          openDeleteGoalModal={openDeleteHabitModal}
                          openAddSessionModal={openAddSessionModal}
                        />
                      </div>
                    )
                  );
                })}
              <HabitsPageOnboarding />
            </div>
          </>
        </>
      )}
    </div>
  );
};

export { HabitsPage };
