import { DateTime } from 'luxon';
import { IApiHabit, IHabit } from './Habit';
import { IApiSession, ISession } from './Session';
import { IApiTask, ITask } from './Task';

export interface IProject {
  id: string;
  name: string;
  notes: string;
  deleted: boolean;
  archived: boolean;
  phases: IPhase[];
  tasks: ITask[];
  standardPomodoroBreak: number;
  standardPomodoroTime: number;
  targetMinutes: number;
  customTarget: number;
  trackTime: boolean;
  trackUnits: boolean;
  unitName?: string;
  unitTypeId?: string;
  goals?: IHabit[];
  sessions?: ISession[];
  startDate?: DateTime;
  endDate?: DateTime;
  taskCompletion?: number;
  expectedTaskCompletion?: number;
  expectedNumberOfCompleteTasks?: number;
  projectCompletionScore?: number;
}

export interface IApiProject {
  id: string;
  name: string;
  notes: string;
  deleted: boolean;
  archived: boolean;
  standard_session_time: number;
  standard_session_break: number;
  is_tracking_sessions: boolean;
  is_tracking_units: boolean;
  minutes_per_day: number;
  units_per_day: number;
  unit_name: string;
  unit_type_id?: string;
  phases: IApiPhase[];
  tasks: IApiTask[];
  goals?: IApiHabit[];
  sessions?: IApiSession[];
  start_date?: string;
  end_date?: string;
  task_completion?: number;
  expected_task_completion?: number;
  expected_number_of_complete_tasks?: number;
  project_completion_score?: number;
}

export interface ISimplifiedProject {
  id: string;
  name: string;
  deleted: boolean;
  archived: boolean;
  remainingTaskCount: number;
  phases?: ISimplifiedPhase[];
}

export interface IApiSimplifiedProject {
  id: string;
  name: string;
  deleted: boolean;
  archived: boolean;
  remaining_task_count: number;
  phases?: IApiSimplifiedPhase[];
}

export interface ISimplifiedPhase {
  id: string;
  name: string;
  orderedTaskIds?: string[];
  estimatedTimeToCompleteAllTasks?: number;
}

export interface IApiSimplifiedPhase {
  id: string;
  name: string;
  ordered_task_ids?: string[];
  estinated_time_to_complete_all_tasks?: number;
}

export interface IPhase {
  id: string;
  name: string;
  tasks: ITask[];
  estimatedTimeToCompleteAllTasks?: number;
}

export function isPhase(item: any): item is IPhase {
  return (
    typeof item.id === 'string' &&
    typeof item.name === 'string' &&
    Array.isArray(item.tasks)
  );
}

export interface IApiPhase {
  id: string;
  name: string;
  tasks: IApiTask[];
  estimated_time_to_complete_all_tasks?: number;
}

export interface IApiCreateProjectRequest {
  name: string;
}

export interface IApiUpdateProjectRequest {
  name: string;
  notes: string;
  deleted: boolean;
  archived?: boolean;
  phases?: IApiPhaseInRequest[];
  standard_session_break: number;
  standard_session_time: number;
  unit_type_name?: string;
  units_per_day?: number;
  minutes_per_day?: number;
  is_tracking_units?: boolean;
  is_tracking_sessions?: boolean;
  start_date?: string | null;
  end_date?: string | null;
}

export interface IApiGetProjectsResponse {
  projects: IApiSimplifiedProject[];
}

export interface IApiCreatePhaseRequest {
  name: string;
  is_user_level: boolean;
  project_id?: string;
  ordered_task_ids?: string[];
}

export interface IApiUpdatePhaseRequest {
  id: string;
  name: string;
  ordered_task_ids?: string[];
}

export enum PhasesUpdateType {
  MoveTask = 'MoveTask',
  MovePhase = 'MovePhase',
}

export interface IApiUpdatePhasesRequest {
  phases_update_type: PhasesUpdateType;
  task_move?: {
    task_id: string;
    source_phase_id: string;
    destination_phase_id: string;
    destination_index: number;
  };
  phase_move?: {
    phase_id: string;
    source_index: number;
    destination_index: number;
  };
}

export interface IApiPhaseInRequest {
  id: string;
  name: string;
  ordered_task_ids?: string[];
}

export interface IApiGetPhasesResponse {
  phases: IApiPhase[];
}

export function isValidPhase(phase: any): phase is IPhase {
  return Boolean(!!phase?.id && !!phase?.name);
}
