import React, { useContext, useState } from 'react';
import { Dialog, Button, ButtonGroup, Intent } from '@blueprintjs/core';
import { AlertsContext } from '../../state/AlertsContext';
import { MarkdownTextEditor } from '../common/MarkdownTextEditor';
import { ModalContext } from '../../state/ModalContext';
import { useModalStyles } from '../../style/components/genericStyles';
import { DateTime } from 'luxon';
import { WindowContext } from '../../state/WindowContext';

interface Props {
  onRequestClose: () => void;
  modalIsOpen: boolean;
  saveLog: (
    logId: string,
    projectId: string,
    logDate: Date,
    logText: string
  ) => Promise<void>;
}

const EditLogModal: React.FunctionComponent<Props> = (props: Props) => {
  const alertsContext = useContext(AlertsContext);
  const modalContext = useContext(ModalContext);
  const windowContext = useContext(WindowContext);
  const modalStyles = useModalStyles();

  const { logToEdit } = modalContext!;
  const [logText, setLogText] = useState(logToEdit!.note);

  const [isSaving, setIsSaving] = useState(false);

  const handleSaveLog = async event => {
    event.preventDefault();
    setIsSaving(true);
    try {
      const { saveLog } = props;
      const { projectId, date } = logToEdit!;
      await saveLog(
        logToEdit?.id!,
        projectId ? projectId?.toString() : '',
        new Date(date),
        logText
      );
      alertsContext!.addAlert(`Saved log entry`, Intent.SUCCESS);
    } catch (err) {
      alertsContext!.addAlert('Error saving log entry', Intent.DANGER);
    } finally {
      setIsSaving(false);
      handleClose();
    }
  };

  const handleClose = () => {
    props.onRequestClose();
  };

  const handleLogTextChange = value => {
    setLogText(value);
  };

  const readableDate = DateTime.fromISO(logToEdit!.date).toLocaleString(
    DateTime.DATETIME_MED_WITH_WEEKDAY
  );

  return (
    <Dialog
      isOpen={props.modalIsOpen}
      onClose={props.onRequestClose}
      title={logToEdit && `Edit log for ${readableDate}`}
      canOutsideClickClose={false}
      style={windowContext?.isMobile ? { width: '50vh' } : {}}
    >
      <div className={`bp4-dialog-body ${modalStyles.body}`}>
        <label className="bp4-label">
          Enter the updated log below:
          <MarkdownTextEditor
            initialValue={logText}
            onTextChange={handleLogTextChange}
          />
        </label>
      </div>

      <div className={`bp4-dialog-footer ${modalStyles.footer}`}>
        <ButtonGroup>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={handleSaveLog}
            intent={Intent.PRIMARY}
            loading={isSaving}
          >
            Save
          </Button>
        </ButtonGroup>
      </div>
    </Dialog>
  );
};

export { EditLogModal };
