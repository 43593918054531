import React, { useContext } from 'react';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { Menu, MenuDivider, MenuItem } from '@blueprintjs/core';

import { ProjectsListContext } from '../../../state/ProjectsListContext';
import { TempMobileMenuContext } from '../../../state/TempMobileMenuContext';

import NavBarItem from './NavBarItem';
import NavBarProjectList from './NavBarProjectList';
import { useNavbarMobileStyles } from '../../../style/components/navbarStyles';

interface Props {
  isOpen: boolean;
  openAddProjectModal: () => void;
}

const NavBarMobile: React.FunctionComponent<Props> = (props: Props) => {
  const projectsListContext = useContext(ProjectsListContext);
  const navbarMobileStyles = useNavbarMobileStyles();

  if (!projectsListContext) {
    return null;
  }

  return (
    <TempMobileMenuContext.Consumer>
      {mobileMenuContext => (
        <SwipeableDrawer
          open={props.isOpen}
          onOpen={() => mobileMenuContext!.toggleMobileMenu()}
          onClose={() => mobileMenuContext!.toggleMobileMenu()}
          anchor="right"
          classes={{ paper: navbarMobileStyles.navbarMobile }}
        >
          <Menu
            style={{
              height: '100%',
              backgroundColor: 'rgba(0,0,0,0)',
              color: 'white',
              maxWidth: '80vw',
            }}
          >
            <NavBarItem to="/" text="Dashboard" isMobile />
            <NavBarItem
              to="/tasks"
              text="Tasks"
              isMobile
              id="tasks-page-button"
            />
            <NavBarItem
              to="/habits"
              text="Habits"
              isMobile
              id="habits-page-button"
            />
            <NavBarItem to="/log" text="Log" isMobile id="log-page-button" />
            <NavBarItem to="/tags" text="Tags" isMobile id="tag-page-button" />
            <NavBarItem
              to="/unit_types"
              text="Unit types"
              isMobile
              id="unit-types-page-button"
            />
            <NavBarItem
              to="/sessions"
              text="Sessions"
              isMobile
              id="sessions-page-button"
            />
            <MenuDivider />
            <MenuItem
              text="Add project"
              icon="plus"
              onClick={props.openAddProjectModal}
              id="add-project-button"
            />
            <MenuDivider />
            <NavBarProjectList isMobile />
          </Menu>
        </SwipeableDrawer>
      )}
    </TempMobileMenuContext.Consumer>
  );
};

export { NavBarMobile };
