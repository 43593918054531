import { makeMinutesReadable } from './readability-utils';
import { TrackingPeriod } from '../common-src/types/User';
import { ITextChunk } from '../common-src/types/UiTypes';
import {
  IHabitsAdvice,
  IHabitSummary,
  IProjectsAdvice,
  IStatusOfAllHabits,
  IStatusOfAllProjects,
  IStatusOfAllTasks,
  ITasksAdvice,
} from '../common-src/types/Reporting';
import { getOverTextForTrackingPeriod } from '../common-src/productivity';
import { HabitType, IHabit } from '../common-src/types/Habit';
import { generateColourFromPercentage } from './ui-utils';

export function getWhatNeedsToBeDoneForAllHabits(
  habitsAdvice: IHabitsAdvice,
  statusOfAllHabits: IStatusOfAllHabits,
  trackingPeriod: TrackingPeriod,
  customTrackingPeriod?: number
): ITextChunk[] {
  let whatNeedsToBeDoneStringArray: ITextChunk[] = [];
  const overText = getOverTextForTrackingPeriod(
    trackingPeriod,
    customTrackingPeriod
  );
  const scoreColour = generateColourFromPercentage(statusOfAllHabits.score);
  whatNeedsToBeDoneStringArray.push({
    text: 'Habits',
    strong: true,
    beginOnNewLine: true,
  });
  whatNeedsToBeDoneStringArray.push({
    text: `Completion score ${overText}: `,
    beginOnNewLine: true,
  });
  whatNeedsToBeDoneStringArray.push({
    text: `${Math.round(statusOfAllHabits.score * 100)}%`,
    strong: true,
    colour: scoreColour,
  });
  whatNeedsToBeDoneStringArray.push({
    text: `You may want to work on: `,
    beginOnNewLine: true,
  });
  whatNeedsToBeDoneStringArray.push({
    text: habitsAdvice.workOnNext,
    strong: true,
  });
  return whatNeedsToBeDoneStringArray;
}

export function dynamicWhatNeedsToBeDoneForAllProjects(
  projectsAdvice: IProjectsAdvice,
  statusOfAllProjects: IStatusOfAllProjects
): ITextChunk[] {
  let whatNeedsToBeDoneStringArray: ITextChunk[] = [];
  const scoreColour = generateColourFromPercentage(statusOfAllProjects.score);
  whatNeedsToBeDoneStringArray.push({
    text: 'Projects',
    strong: true,
    beginOnNewLine: true,
  });
  whatNeedsToBeDoneStringArray.push({
    text: `Completion score: `,
    beginOnNewLine: true,
  });
  whatNeedsToBeDoneStringArray.push({
    text: `${Math.round(statusOfAllProjects.score * 100)}%`,
    strong: true,
    colour: scoreColour,
  });
  whatNeedsToBeDoneStringArray.push({
    text: `This looks at projects that you're tracking, and reflects where you are versus where you should be.`,
    beginOnNewLine: true,
  });
  whatNeedsToBeDoneStringArray.push({
    text: `Based on amount of tasks completed, you should work on: `,
    beginOnNewLine: true,
  });
  whatNeedsToBeDoneStringArray.push({
    text: projectsAdvice.workOnNext,
    strong: true,
  });
  const trackedProjects = statusOfAllProjects.summaries.filter(
    projectSummary => projectSummary.isTracked
  );
  if (trackedProjects.length > 0) {
    whatNeedsToBeDoneStringArray.push({
      text: ``,
      beginOnNewLine: true,
    });
    whatNeedsToBeDoneStringArray.push({
      text: `You're tracking the following ${trackedProjects.length} projects:`,
      beginOnNewLine: true,
      italic: true,
    });
  }
  trackedProjects.forEach(projectSummary => {
    if (projectSummary.isTracked) {
      const projectScoreColour = generateColourFromPercentage(
        projectSummary.projectScore
      );
      whatNeedsToBeDoneStringArray.push({
        text: `* ${projectSummary.projectName} → `,
        strong: true,
        beginOnNewLine: true,
      });
      whatNeedsToBeDoneStringArray.push({
        text: `${Math.round(projectSummary.taskCompletion * 100)}%`,
        colour: projectScoreColour,
      });
    }
  });
  return whatNeedsToBeDoneStringArray;
}

export function dynamicWhatNeedsToBeDoneForAllTasks(
  tasksAdvice: ITasksAdvice,
  statusOfAllTasks: IStatusOfAllTasks
): ITextChunk[] {
  let whatNeedsToBeDoneStringArray: ITextChunk[] = [];
  const scoreColour = generateColourFromPercentage(statusOfAllTasks.score);
  whatNeedsToBeDoneStringArray.push({
    text: 'Tasks',
    strong: true,
    beginOnNewLine: true,
  });
  whatNeedsToBeDoneStringArray.push({
    text: `Completion score: `,
    beginOnNewLine: true,
  });
  whatNeedsToBeDoneStringArray.push({
    text: `${Math.round(statusOfAllTasks.score * 100)}%`,
    strong: true,
    colour: scoreColour,
  });

  whatNeedsToBeDoneStringArray.push({
    text: `This looks at tasks with due dates and tasks that are in your todo list.`,
    beginOnNewLine: true,
  });

  whatNeedsToBeDoneStringArray.push({
    text: `You might want to work on: `,
    beginOnNewLine: true,
  });
  whatNeedsToBeDoneStringArray.push({
    text: tasksAdvice.workOnNext,
    strong: true,
  });
  return whatNeedsToBeDoneStringArray;
}

export function getSuperProductiveString(
  trackingPeriod: TrackingPeriod,
  customTrackingPeriod?: number
): string {
  let superProductiveString = '';
  switch (trackingPeriod) {
    case TrackingPeriod.CUSTOM:
      if (customTrackingPeriod) {
        superProductiveString = `You have been super productive over the last ${customTrackingPeriod} days! Why not put your feet up for a bit?`;
      } else {
        superProductiveString = '';
      }
      break;
    case TrackingPeriod.TODAY:
      superProductiveString =
        "You're super-productive today! Why not put your feet up for a bit?";
      break;
    case TrackingPeriod.LAST_7_DAYS:
      superProductiveString =
        "You've been super-productive for the last 7 days! Why not put your feet up for a bit?";
      break;
    case TrackingPeriod.THIS_WEEK:
      superProductiveString =
        "You've been super-productive this week! Why not put your feet up for a bit?";
      break;
    case TrackingPeriod.THIS_MONTH:
      superProductiveString =
        "You've been super-productive this month! Why not put your feet up for a bit?";
      break;
    case TrackingPeriod.THIS_QUARTER:
      superProductiveString =
        "You've been super-productive this quarter! Why not put your feet up for a bit?";
      break;
    case TrackingPeriod.THIS_YEAR:
      superProductiveString =
        "You've been super-productive this year! Why not put your feet up for a bit?";
      break;
    case TrackingPeriod.ALL_TIME:
      superProductiveString =
        "You've been super-productive for a long time! Why not put your feet up for a bit?";
      break;
    default:
      superProductiveString = '';
  }
  return superProductiveString;
}

/**
 * Determines the amount of work (in units or minutes) that needs to be done for a given
 * habit in order to be "perfectly" productive in the defined tracking period.
 */
export function dynamicWhatNeedsToBeDoneForHabit(
  habit: IHabit,
  habitSummary: IHabitSummary,
  trackingPeriod: TrackingPeriod,
  customTrackingPeriod?: number
): ITextChunk[] {
  let whatNeedsToBeDoneStringArray: ITextChunk[] = [];

  const superProductiveString = getSuperProductiveString(
    trackingPeriod,
    customTrackingPeriod
  );

  const targetMinutes = Math.round(habitSummary.targetMinutes!);
  const catchupMinutes = habitSummary.catchupMinutes;
  const targetUnits = Math.round(habitSummary.customTarget!);
  const catchupUnits = habitSummary.catchupUnits;

  if (habit.type === HabitType.SESSION && habitSummary.sessions) {
    whatNeedsToBeDoneStringArray.push({
      text: 'Target output: ',
      beginOnNewLine: true,
    });
    whatNeedsToBeDoneStringArray.push({
      text: `${makeMinutesReadable(targetMinutes)} per day`,
      strong: true,
    });
    whatNeedsToBeDoneStringArray.push({ text: '. ' });
    if (catchupMinutes === 0 && habitSummary.habitScore === 0) {
      // don't show anything if the user is not expected to have done anything yet
      whatNeedsToBeDoneStringArray.push({ text: '' });
    } else if (catchupMinutes && catchupMinutes > 0) {
      whatNeedsToBeDoneStringArray.push({ text: ' You need to work ' });
      whatNeedsToBeDoneStringArray.push({
        text: makeMinutesReadable(+catchupMinutes),
        strong: true,
      });
      whatNeedsToBeDoneStringArray.push({ text: ' to be on track. ' });
    } else {
      whatNeedsToBeDoneStringArray.push({ text: superProductiveString });
    }
  } else if (habit.type === HabitType.UNIT && habitSummary.units) {
    whatNeedsToBeDoneStringArray.push({
      text: 'Target output: ',
      beginOnNewLine: true,
    });
    whatNeedsToBeDoneStringArray.push({
      text: `${targetUnits} ${habitSummary.unitName} per day`,
      strong: true,
    });
    whatNeedsToBeDoneStringArray.push({ text: '. ' });

    if (catchupUnits === 0 && habitSummary.habitScore === 0) {
      // don't show anything if the user is not expected to have done anything yet
      whatNeedsToBeDoneStringArray.push({ text: '' });
    } else if (catchupUnits && catchupUnits > 0) {
      whatNeedsToBeDoneStringArray.push({ text: 'You need to do ' });
      whatNeedsToBeDoneStringArray.push({
        text: `${+catchupUnits} more ${habitSummary.unitName}`,
        strong: true,
      });
      whatNeedsToBeDoneStringArray.push({ text: ' to be on track. ' });
    } else {
      whatNeedsToBeDoneStringArray.push({
        text: superProductiveString,
        beginOnNewLine: true,
      });
    }
  } else if (habit.type === HabitType.HABIT) {
    // <div>
    //     You aim to do this {habitGoal.cadence.regularity} time(s) per{' '}
    //     {habitGoal.cadence.unit.toLocaleLowerCase()}
    //   </div>

    whatNeedsToBeDoneStringArray.push({
      text: `You aim to do this ${
        habit.cadence.regularity
      } time(s) per ${habit.cadence.unit.toLocaleLowerCase()}.`,
      beginOnNewLine: true,
    });
  }
  return whatNeedsToBeDoneStringArray;
}

/**
 * Determines the amount of work (in units, minutes, or both) that needs to be done for a given
 * set of habits in order to be "perfectly" productive.
 */
export function dynamicWhatNeedsToBeDoneForMultipleHabits(
  statusOfAllHabits: IStatusOfAllHabits
): ITextChunk[] {
  let whatNeedsToBeDoneStringArray: ITextChunk[] = [];

  const unitsToBeDone: { unitName?: string; unitAmount?: number }[] = [];

  const catchupMinutes = statusOfAllHabits.catchupMinutes;

  statusOfAllHabits.summaries.forEach(habitSummary => {
    if (habitSummary.habitType === HabitType.UNIT && habitSummary.units) {
      unitsToBeDone.push({
        unitName: habitSummary.unitName,
        unitAmount: habitSummary.catchupUnits,
      });
    }
  });

  const totalUnitOutputTarget = statusOfAllHabits.summaries
    .filter(goalProductivity => goalProductivity.habitType === HabitType.UNIT)
    .reduce((previousValue, currentValue) => {
      if (currentValue.catchupUnits) {
        return previousValue + currentValue.catchupUnits;
      } else {
        return previousValue;
      }
    }, 0);

  if (statusOfAllHabits.totalTimeTarget || totalUnitOutputTarget) {
    whatNeedsToBeDoneStringArray.push({
      text: 'In total you need to do ',
      beginOnNewLine: true,
    });
    whatNeedsToBeDoneStringArray.push({
      text: makeMinutesReadable(catchupMinutes ?? 0),
      strong: true,
    });
    whatNeedsToBeDoneStringArray.push({ text: ' of work' });

    if (unitsToBeDone.length > 2) {
      whatNeedsToBeDoneStringArray.push({ text: ' plus ' });
      for (let i = 0; i < unitsToBeDone.length; i++) {
        if (i === unitsToBeDone.length - 1) {
          whatNeedsToBeDoneStringArray.push({ text: 'and ' });
          whatNeedsToBeDoneStringArray.push({
            text: `${unitsToBeDone[i].unitAmount} ${unitsToBeDone[i].unitName}`,
            strong: true,
          });
          whatNeedsToBeDoneStringArray.push({ text: '. ' });
        } else {
          whatNeedsToBeDoneStringArray.push({
            text: `${unitsToBeDone[i].unitAmount} ${unitsToBeDone[i].unitName}`,
            strong: true,
          });
          whatNeedsToBeDoneStringArray.push({ text: ', ' });
        }
      }
    } else if (unitsToBeDone.length === 2) {
      whatNeedsToBeDoneStringArray.push({ text: ', plus ' });
      for (let i = 0; i < unitsToBeDone.length; i++) {
        if (i === unitsToBeDone.length - 1) {
          whatNeedsToBeDoneStringArray.push({ text: ' and ' });
          whatNeedsToBeDoneStringArray.push({
            text: `${unitsToBeDone[i].unitAmount} ${unitsToBeDone[i].unitName}`,
            strong: true,
          });
          whatNeedsToBeDoneStringArray.push({ text: '. ' });
        } else {
          whatNeedsToBeDoneStringArray.push({
            text: `${unitsToBeDone[i].unitAmount} ${unitsToBeDone[i].unitName}`,
            strong: true,
          });
        }
      }
    } else if (unitsToBeDone.length === 1) {
      whatNeedsToBeDoneStringArray.push({ text: ', plus ' });
      whatNeedsToBeDoneStringArray.push({
        text: `${unitsToBeDone[0].unitAmount} ${unitsToBeDone[0].unitName}`,
        strong: true,
      });
    } else {
      whatNeedsToBeDoneStringArray.push({ text: '. ' });
    }
  }
  return whatNeedsToBeDoneStringArray;
}

export function dynamicWhatNeedsToBeDoneForAllHabits(
  habitsAdvice: IHabitsAdvice,
  statusOfAllHabits: IStatusOfAllHabits,
  trackingPeriod: TrackingPeriod,
  customTrackingPeriod?: number
): ITextChunk[] {
  let whatNeedsToBeDoneStringArray: ITextChunk[] = [];
  const overText = getOverTextForTrackingPeriod(
    trackingPeriod,
    customTrackingPeriod
  );
  const scoreColour = generateColourFromPercentage(statusOfAllHabits.score);
  whatNeedsToBeDoneStringArray.push({
    text: 'Habits',
    strong: true,
  });
  whatNeedsToBeDoneStringArray.push({
    text: `Completion score ${overText}: `,
    beginOnNewLine: true,
  });
  whatNeedsToBeDoneStringArray.push({
    text: `${Math.round(statusOfAllHabits.score * 100)}%`,
    strong: true,
    colour: scoreColour,
  });
  whatNeedsToBeDoneStringArray.push({
    text: `You may want to work on: `,
    beginOnNewLine: true,
  });
  whatNeedsToBeDoneStringArray.push({
    text: habitsAdvice.workOnNext,
    strong: true,
  });
  return whatNeedsToBeDoneStringArray;
}

export function getDashboardSummaryTooltip(
  trackingPeriod: TrackingPeriod,
  customTrackingPeriod?: number
): ITextChunk[] {
  let tooltip: ITextChunk[] = [];
  const overTextForTrackingPeriod = getOverTextForTrackingPeriod(
    trackingPeriod,
    customTrackingPeriod
  );

  tooltip.push({
    text: `An estimate of how productive you've been ${overTextForTrackingPeriod}.`,
  });
  tooltip.push({
    text: `This is calculated by taking into account the progress you've made in your projects, tasks, goals, and habits, if you're tracking them.`,
    beginOnNewLine: true,
  });

  return tooltip;
}

export function getHabitScoreText(
  trackingPeriod: TrackingPeriod,
  customTrackingPeriod?: number
): ITextChunk[] {
  let habitScoreText: ITextChunk[] = [];
  const overText = getOverTextForTrackingPeriod(
    trackingPeriod,
    customTrackingPeriod
  );
  habitScoreText.push({ text: `Your habit score ${overText}` });
  return habitScoreText;
}

export function getProjectCompletionTooltipForProjectInPieChart(
  projectCompletion: number,
  taskCompletion: number,
  completeTasks: number,
  totalTasks: number,
  hasDueDate: boolean,
  expectedTaskCompletion?: number,
  expectedNumberOfCompleteTasks?: number
): ITextChunk[] {
  let tooltip: ITextChunk[] = [];

  tooltip.push({
    text: `You've completed`,
    beginOnNewLine: true,
  });
  tooltip.push({
    text: ` ${(taskCompletion * 100).toFixed(2)}%`,
    strong: true,
  });
  tooltip.push({
    text: ` of all tasks (${completeTasks} of ${totalTasks})`,
  });

  if (projectCompletion > 1) {
    if (hasDueDate) {
      tooltip.push({
        text: `, which is ahead of schedule.`,
      });
    } else {
      tooltip.push({
        text: `.`,
      });
    }
  } else if (projectCompletion < 1) {
    if (hasDueDate) {
      tooltip.push({
        text: `, which is behind schedule.`,
      });
    } else {
      tooltip.push({
        text: `.`,
      });
    }
  }

  if (expectedTaskCompletion) {
    tooltip.push({
      text: ``,
      beginOnNewLine: true,
    });
    tooltip.push({
      text: `To be on track, you need to have completed `,
      beginOnNewLine: true,
    });
    tooltip.push({
      text: ` ${(expectedTaskCompletion * 100).toFixed(2)}% `,
      strong: true,
    });
    tooltip.push({
      text: `of all tasks in the project by now.`,
    });
    if (expectedNumberOfCompleteTasks) {
      const numberOfTasksRounded = Math.round(expectedNumberOfCompleteTasks);
      tooltip.push({
        text: ` This means about `,
      });
      tooltip.push({
        text: ` ${numberOfTasksRounded.toFixed(0)} `,
        strong: true,
      });
      if (numberOfTasksRounded === 1) {
        tooltip.push({
          text: `task.`,
        });
      } else {
        tooltip.push({
          text: `tasks.`,
        });
      }
    }
  }

  return tooltip;
}
