import React, { useState, useContext } from 'react';

import { ProjectsListContext } from '../../state/ProjectsListContext';
import { Button, Dialog, Intent } from '@blueprintjs/core';
import { AlertsContext } from '../../state/AlertsContext';
import { useModalStyles } from '../../style/components/genericStyles';
import { WindowContext } from '../../state/WindowContext';

interface Props {
  onRequestClose: () => void;
  modalIsOpen: boolean;
}

const AddProjectModal: React.FunctionComponent<Props> = (props: Props) => {
  const projectsContext = useContext(ProjectsListContext);
  const alertsContext = useContext(AlertsContext);
  const windowContext = useContext(WindowContext);
  const modalStyles = useModalStyles();

  const [projectName, setProjectName] = useState('');
  const [isSaving, setIsSaving] = useState(false);

  if (!projectsContext) {
    return null;
  }

  const handleTextChange = event => {
    setProjectName(event.target.value);
  };

  const handleAddProject = async event => {
    event.preventDefault();
    const name = projectName;
    if (name !== '') {
      setIsSaving(true);
      try {
        const newProject = {
          name,
        };
        await projectsContext.addProject(newProject);
        alertsContext!.addAlert(
          `Successfully added project: "${name}"`,
          Intent.SUCCESS
        );
      } catch (err) {
        alertsContext!.addAlert(
          `Error adding project: "${name}"`,
          Intent.DANGER
        );
      } finally {
        setIsSaving(false);
        handleClose();
      }
    }
  };

  const handleClose = () => {
    setProjectName('');
    props.onRequestClose();
  };

  return (
    <Dialog
      title="Add a project"
      isOpen={props.modalIsOpen}
      onClose={props.onRequestClose}
      canOutsideClickClose={false}
      style={windowContext?.isMobile ? { width: '50vh' } : {}}
    >
      <div className={`bp4-dialog-body ${modalStyles.body}`}>
        <div>
          <label className="bp4-label">
            Enter project name here:
            <input
              className="bp4-input bp4-fill"
              type="text"
              onChange={handleTextChange}
              autoFocus
            />
          </label>
        </div>
      </div>

      <div className={`bp4-dialog-footer ${modalStyles.footer}`}>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          onClick={handleAddProject}
          intent={Intent.PRIMARY}
          loading={isSaving}
        >
          Add
        </Button>
      </div>
    </Dialog>
  );
};

export { AddProjectModal };
