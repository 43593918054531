import React, { FunctionComponent, useContext, useState } from 'react';
import { Dialog, Button, ButtonGroup, Intent } from '@blueprintjs/core';
import { IProject } from '../../common-src/types/Project';
import { AlertsContext } from '../../state/AlertsContext';
import { ModalContext } from '../../state/ModalContext';
import { useModalStyles } from '../../style/components/genericStyles';
import { WindowContext } from '../../state/WindowContext';

interface Props {
  onRequestClose: () => void;
  modalIsOpen: boolean;
  deleteProject: (project: IProject) => Promise<void>;
}

const DeleteProjectModal: FunctionComponent<Props> = props => {
  const alertsContext = useContext(AlertsContext);
  const modalContext = useContext(ModalContext);
  const windowContext = useContext(WindowContext);
  const modalStyles = useModalStyles();

  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const { projectToDelete } = modalContext!;

  const handleDeleteProject = async event => {
    event.preventDefault();
    setIsDeleting(true);

    const { deleteProject } = props;
    try {
      await deleteProject(projectToDelete!);
      alertsContext!.addAlert(
        `Successfully deleted project "${projectToDelete!.name}"`,
        Intent.SUCCESS
      );
    } catch (err) {
      alertsContext!.addAlert(
        `Error deleting project "${projectToDelete!.name}"`,
        Intent.DANGER
      );
    } finally {
      setIsDeleting(false);
      handleClose();
    }
  };

  const handleClose = () => {
    props.onRequestClose();
  };

  return (
    <Dialog
      isOpen={props.modalIsOpen}
      onClose={props.onRequestClose}
      title={projectToDelete && `Delete project "${projectToDelete.name}"`}
      canOutsideClickClose={false}
      style={windowContext?.isMobile ? { width: '50vh' } : {}}
    >
      <div className={`bp4-dialog-body ${modalStyles.body}`}>
        <p>
          Are you sure you want to delete this project? This will delete all
          associated tasks.
        </p>
      </div>
      <div className={`bp4-dialog-footer ${modalStyles.footer}`}>
        <ButtonGroup>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={handleDeleteProject}
            intent={Intent.DANGER}
            loading={isDeleting}
          >
            Do it!
          </Button>
        </ButtonGroup>
      </div>
    </Dialog>
  );
};

export default DeleteProjectModal;
