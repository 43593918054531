import React, { useContext, useEffect, useState } from 'react';
import {
  Dialog,
  ButtonGroup,
  Button,
  Intent,
  Divider,
  NumericInput,
} from '@blueprintjs/core';
import { AlertsContext } from '../../state/AlertsContext';
import { useModalStyles } from '../../style/components/genericStyles';
import { HabitsContext } from '../../state/HabitsContext';
import {
  HabitCadenceUnit,
  HabitType,
  IApiUpdateHabitRequest,
  IHabit,
} from '../../common-src/types/Habit';
import { DateTime } from 'luxon';
import {
  DatetimeFormat,
  DatetimePicker,
  DatetimePickerType,
} from '../common/DatetimePicker/DatetimePicker';
import { ISimplifiedProject } from '../../common-src/types/Project';
import { ILabourType } from '../../common-src/types/LabourType';
import { IUnitType } from '../../common-src/types/UnitOfLabour';
import { WindowContext } from '../../state/WindowContext';
import { HabitCompletions } from '../common/tasks/HabitCompletions';
import { IHabitSummary } from '../../common-src/types/Reporting';

interface Props {
  modalIsOpen: boolean;
  habitToEdit: IHabit | undefined;
  habitSummary: IHabitSummary;
  onRequestClose: () => any;
  simplifiedProjectList?: ISimplifiedProject[];
  labourTypes: ILabourType[];
  unitTypes: IUnitType[];
}

const EditHabitModal: React.FunctionComponent<Props> = (props: Props) => {
  const {
    habitToEdit,
    habitSummary,
    simplifiedProjectList,
    labourTypes,
    unitTypes,
  } = props;
  const habitsContext = useContext(HabitsContext);
  const alertsContext = useContext(AlertsContext);
  const windowContext = useContext(WindowContext);
  const modalStyles = useModalStyles();

  const { updateHabit } = habitsContext!;

  const [habitName, setHabitName] = useState<string>('');
  const [habitType, setHabitType] = useState<HabitType>();
  const [habitStartDate, setHabitStartDate] = useState<string | undefined>();
  const [habitEndDate, setHabitEndDate] = useState<string | undefined>();
  const [habitLabourTypeId, setHabitLabourTypeId] = useState<
    string | undefined
  >();
  const [habitMinutesPerDay, setHabitMinutesPerDay] = useState<
    number | undefined
  >();
  const [habitUnitTypeId, setHabitUnitTypeId] = useState<string | undefined>();
  const [habitUnitsPerDay, setHabitUnitsPerDay] = useState<
    number | undefined
  >();
  const [habitIsEnabled, setHabitIsEnabled] = useState<boolean>(true);

  // cadences
  const [cadenceUnit, setCadenceUnit] = useState<HabitCadenceUnit | undefined>(
    undefined
  );
  const [cadenceRegularity, setCadenceRegularity] = useState<
    number | undefined
  >(undefined);

  // completions
  const [completions, setCompletions] = useState<string[]>([]);
  const [inAddCompletionMode, setInAddCompletionMode] = useState(false);
  const [inEditCompletionMode, setInEditCompletionMode] = useState(false);
  const [completionDateToAdd, setCompletionDateToAdd] =
    useState<DateTime | null>(DateTime.now());
  const [completionDateToEdit, setCompletionDateToEdit] = useState<string>();

  const [isSaving, setIsSaving] = useState(false);

  const [isHabitStartDateFocused, setIsHabitStartDateFocused] = useState(false);
  const [isHabitEndDateFocused, setIsHabitEndDateFocused] = useState(false);

  useEffect(() => {
    if (habitToEdit) {
      setHabitName(habitToEdit.name);
      setHabitType(habitToEdit.type);
      setHabitLabourTypeId(habitToEdit.labourTypeId);
      setHabitMinutesPerDay(habitToEdit.minutesPerDay);
      setHabitUnitTypeId(habitToEdit.unitTypeId);
      setHabitUnitsPerDay(habitToEdit.unitsPerDay);
      setHabitIsEnabled(habitToEdit.isActive);

      if (habitToEdit.completions) {
        setCompletions(habitToEdit.completions);
      }
      if (habitToEdit.cadence) {
        setCadenceUnit(habitToEdit.cadence.unit);
        setCadenceRegularity(+habitToEdit.cadence.regularity);
      }
      if (habitToEdit.startDate) {
        setHabitStartDate(habitToEdit.startDate);
      }
      if (habitToEdit.endDate) {
        setHabitEndDate(habitToEdit.endDate);
      }
      if (habitToEdit.name) {
        setHabitName(habitToEdit.name);
      }
    }
  }, [habitToEdit]);

  const handleUpdateHabit = async event => {
    event.preventDefault();
    setIsSaving(true);

    if (habitName !== '' && habitType) {
      try {
        if (habitType === HabitType.SESSION || habitType === HabitType.UNIT) {
          const updateHabitRequest: IApiUpdateHabitRequest = {
            name: habitName,
            habit_type: habitType,
            start_date: habitStartDate,
            end_date: habitEndDate,
            minutes_per_day: habitMinutesPerDay,
            labour_type_id: habitLabourTypeId,
            unit_type_id: habitUnitTypeId,
            units_per_day: habitUnitsPerDay,
            is_active: habitIsEnabled,
          };
          await updateHabit(habitToEdit!.id, updateHabitRequest);
        } else if (
          habitType === HabitType.HABIT &&
          cadenceRegularity &&
          cadenceUnit
        ) {
          const updateHabitRequest: IApiUpdateHabitRequest = {
            name: habitName,
            start_date: habitStartDate,
            end_date: habitEndDate,
            habit_type: habitType,
            cadence: {
              regularity: cadenceRegularity,
              unit: cadenceUnit,
            },
            completions,
          };
          await updateHabit(habitToEdit!.id, updateHabitRequest);
        }

        alertsContext!.addAlert(`Updated habit "${habitName}"`, Intent.SUCCESS);
      } catch (err) {
        alertsContext!.addAlert(
          `Error updating habit "${habitName}"`,
          Intent.DANGER
        );
      } finally {
        setIsSaving(false);
        handleClose();
      }
    }
  };

  const handleHabitNameChange = event => {
    setHabitName(event.target.value);
  };

  const handleHabitTypeChange = event => {
    setHabitType(event.target.value);
  };

  const handleStartDateChange = (date?: DateTime) => {
    setHabitStartDate(date?.toISO());
  };

  const handleStartDateError = (date: Date) => {
    setHabitStartDate(undefined);
  };

  const handleStartDateFocusChange = (arg: { focused: boolean }) => {
    setIsHabitStartDateFocused(arg.focused);
  };

  const handleEndDateChange = (date?: DateTime) => {
    setHabitEndDate(date?.toISO());
  };

  const handleEndDateFocusChange = (arg: { focused: boolean }) => {
    setIsHabitEndDateFocused(arg.focused);
  };

  const handleLabourTypeChange = event => {
    setHabitLabourTypeId(event.target.value);
  };

  const handleMinutesPerDayChange = event => {
    setHabitMinutesPerDay(event.target.value);
  };

  const handleUnitTypeChange = event => {
    setHabitUnitTypeId(event.target.value);
  };

  const handleUnitsPerDayChange = event => {
    setHabitUnitsPerDay(event.target.value);
  };

  const handleCadenceUnitChange = event => {
    setCadenceUnit(event.target.value);
  };

  const handleRegularityChange = value => {
    setCadenceRegularity(value);
  };

  const handleEnterAddCompletionMode = () => {
    setInAddCompletionMode(true);
  };

  const handleEnterEditCompletionMode = (dateString: string) => {
    setInEditCompletionMode(true);
    setCompletionDateToEdit(dateString);
  };

  const handleNewCompletionChange = (newCompletion?: DateTime) => {
    setCompletionDateToAdd(newCompletion ?? null);
  };

  const handleNewCompletionError = () => {
    setCompletionDateToAdd(null);
  };

  const handleAddNewCompletion = () => {
    if (completionDateToAdd) {
      const newCompletionString = completionDateToAdd.toUTC().toISO();
      const newCompletions = completions.slice();
      newCompletions.push(newCompletionString);
      setCompletions(newCompletions.slice());
      setInAddCompletionMode(false);
    }
  };

  const handleSaveEditedCompletion = (
    originalDateString: string,
    newDateString: string
  ) => {
    if (newDateString) {
      const newCompletions = completions.slice();
      const completionIndex = completions.findIndex((completion: string) =>
        DateTime.fromISO(completion).equals(
          DateTime.fromISO(originalDateString)
        )
      );

      if (completionIndex < 0) {
        return;
      }

      newCompletions[completionIndex] = newDateString;
      setCompletions(newCompletions.slice());
      setInEditCompletionMode(false);
    }
  };

  const handleDismissEditingCompletion = () => {
    setInEditCompletionMode(false);
  };

  const handleIsEnabledChange = event => {
    setHabitIsEnabled(event.target.checked);
  };

  const handleClose = () => {
    setHabitName('');
    props.onRequestClose();
  };

  const isDisabled =
    !habitName || (habitType === HabitType.UNIT && !habitUnitTypeId);

  const projectsToShow: JSX.Element[] = [];

  projectsToShow.push(
    <option value="" key="None">
      None
    </option>
  );

  simplifiedProjectList?.forEach((project: ISimplifiedProject) => {
    projectsToShow.push(
      <option value={project.id} key={project.id}>
        {project.name}
      </option>
    );
  });

  const labourTypesToShow: JSX.Element[] = [];

  labourTypesToShow.push(
    <option value="" key="None">
      None
    </option>
  );

  labourTypes.forEach((labourType: ILabourType) => {
    labourTypesToShow.push(
      <option value={labourType.id} key={labourType.id}>
        {labourType.name}
      </option>
    );
  });

  const unitTypesToShow: JSX.Element[] = [];

  unitTypesToShow.push(
    <option value="" key="None">
      None
    </option>
  );

  unitTypes.forEach((unitType: IUnitType) => {
    unitTypesToShow.push(
      <option value={unitType.id} key={unitType.id}>
        {unitType.name}
      </option>
    );
  });

  const title =
    habitToEdit?.type === HabitType.SESSION
      ? 'Edit session habit'
      : habitToEdit?.type === HabitType.UNIT
      ? 'Edit unit habit'
      : 'Edit habit';

  const actualRegularity = cadenceRegularity
    ? habitSummary.habitScore * cadenceRegularity
    : 0;

  return (
    <Dialog
      isOpen={props.modalIsOpen}
      onClose={props.onRequestClose}
      title={title}
      canOutsideClickClose={false}
      style={windowContext?.isMobile ? { width: '50vh' } : {}}
    >
      <div className={`bp4-dialog-body ${modalStyles.body}`}>
        <label className="bp4-label">
          Name
          <input
            className="bp4-input bp4-fill"
            type="text"
            onChange={handleHabitNameChange}
            value={habitName}
          />
        </label>
        <label className="bp4-label">
          Habit type
          <div className="bp4-select">
            <select value={habitType} onChange={handleHabitTypeChange} disabled>
              <option value={HabitType.SESSION} key={HabitType.SESSION}>
                Session
              </option>
              <option value={HabitType.UNIT} key={HabitType.UNIT}>
                Unit
              </option>
              <option value={HabitType.HABIT} key={HabitType.HABIT}>
                Habit
              </option>
            </select>
          </div>
        </label>
        <DatetimePicker
          id="habit_start_date_picker"
          type={DatetimePickerType.date}
          label="Start date"
          placeholder="Enter the start date here (optional)"
          datetime={
            habitStartDate ? DateTime.fromISO(habitStartDate) : undefined
          }
          onDatetimeChange={handleStartDateChange}
          handleFocusChange={handleStartDateFocusChange}
          isFocused={isHabitStartDateFocused}
          datetimeFormat={DatetimeFormat.DATE}
          canClear={false}
        />
        <DatetimePicker
          id="habit_end_date_picker"
          type={DatetimePickerType.date}
          label="End date"
          placeholder="Enter the end date here (optional)"
          datetime={habitEndDate ? DateTime.fromISO(habitEndDate) : undefined}
          onDatetimeChange={handleEndDateChange}
          handleFocusChange={handleEndDateFocusChange}
          isFocused={isHabitEndDateFocused}
          datetimeFormat={DatetimeFormat.DATE}
          canClear={false}
        />

        {habitType === HabitType.SESSION ? (
          <div>
            <label className="bp4-label">
              Choose target daily minutes:
              <input
                className="bp4-input bp4-fill"
                type="text"
                onChange={handleMinutesPerDayChange}
                value={habitMinutesPerDay}
              />
            </label>
          </div>
        ) : null}
        {habitType === HabitType.UNIT ? (
          <div>
            {unitTypes && unitTypes.length > 0 && (
              <label className="bp4-label">
                Associated unit type
                <div className="bp4-select">
                  <select
                    value={habitUnitTypeId ?? ''}
                    onChange={handleUnitTypeChange}
                  >
                    {unitTypesToShow}
                  </select>
                </div>
              </label>
            )}
            <label className="bp4-label">
              Choose target daily units:
              <input
                className="bp4-input bp4-fill"
                type="text"
                onChange={handleUnitsPerDayChange}
                value={habitUnitsPerDay}
              />
            </label>
          </div>
        ) : null}
        {habitType === HabitType.HABIT && cadenceUnit && cadenceRegularity ? (
          <>
            <Divider />
            <div>
              <strong>Habit info</strong>
            </div>
            {cadenceUnit && (
              <div>
                <label className="bp4-label">
                  Cadence:
                  <div className="bp4-select">
                    <select
                      value={cadenceUnit}
                      onChange={handleCadenceUnitChange}
                    >
                      <option
                        value={HabitCadenceUnit.MINUTE}
                        key={HabitCadenceUnit.MINUTE}
                      >
                        Minute-by-minute
                      </option>
                      <option
                        value={HabitCadenceUnit.HOUR}
                        key={HabitCadenceUnit.HOUR}
                      >
                        Hourly
                      </option>
                      <option
                        value={HabitCadenceUnit.DAY}
                        key={HabitCadenceUnit.DAY}
                      >
                        Daily
                      </option>
                      <option
                        value={HabitCadenceUnit.WEEK}
                        key={HabitCadenceUnit.WEEK}
                      >
                        Weekly
                      </option>
                      <option
                        value={HabitCadenceUnit.MONTH}
                        key={HabitCadenceUnit.MONTH}
                      >
                        Monthly
                      </option>
                      <option
                        value={HabitCadenceUnit.YEAR}
                        key={HabitCadenceUnit.YEAR}
                      >
                        Annually
                      </option>
                    </select>
                  </div>
                </label>
                <NumericInput
                  min={0}
                  value={cadenceRegularity}
                  fill
                  onValueChange={handleRegularityChange}
                />
                <p>{`You plan on doing this task ${cadenceRegularity} time(s) per ${cadenceUnit.toLowerCase()}.`}</p>
              </div>
            )}
            <HabitCompletions
              completions={completions}
              onEnterAddCompletionMode={handleEnterAddCompletionMode}
              onEnterEditCompletionMode={handleEnterEditCompletionMode}
              onNewCompletionChange={handleNewCompletionChange}
              onNewCompletionError={handleNewCompletionError}
              onEditCompletionError={() => {}}
              onAddNewCompletion={handleAddNewCompletion}
              onSaveEditedCompletion={handleSaveEditedCompletion}
              inAddCompletionMode={inAddCompletionMode}
              inEditCompletionMode={inEditCompletionMode}
              completionDateToAdd={completionDateToAdd ?? undefined}
              completionDateToEdit={completionDateToEdit}
              onDismissEditingCompletion={handleDismissEditingCompletion}
            />
            <Divider />
          </>
        ) : null}
        <label className="bp4-control bp4-checkbox">
          <input
            type="checkbox"
            checked={habitIsEnabled}
            onChange={handleIsEnabledChange}
          />
          <span className="bp4-control-indicator" />
          Enabled
        </label>
      </div>
      <div className={`bp4-dialog-footer ${modalStyles.footer}`}>
        <ButtonGroup>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={handleUpdateHabit}
            intent={Intent.PRIMARY}
            loading={isSaving}
            disabled={isDisabled}
          >
            Save
          </Button>
        </ButtonGroup>
      </div>
    </Dialog>
  );
};

export { EditHabitModal };
