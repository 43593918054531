import { DateTime } from 'luxon';
import React from 'react';
import { IAreaChartData } from '../../../common-src/types/ChartTypes';
import { useGenericStyles } from '../../../style/components/genericStyles';
import { useLayoutStyles } from '../../../style/components/layoutStyles';
import { AreaChartPanel } from './AreaChartPanel';
import { AreaChart } from './AreaChart';

interface IProps {
  areaChartData: IAreaChartData;
  handleEditDateChange: (newDate: DateTime) => void;
  startDate: DateTime;
  endDate: DateTime;
  handleStartDateChange: (datetime?: DateTime) => void;
  handleEndDateChange: (datetime?: DateTime) => void;
  width: number;
  height: number;
}

const AreaChartWithPanel: React.FunctionComponent<IProps> = (props: IProps) => {
  const genericStyles = useGenericStyles();
  const layoutStyles = useLayoutStyles();
  const {
    areaChartData,
    startDate,
    endDate,
    handleStartDateChange,
    handleEndDateChange,
    width,
    height,
    handleEditDateChange,
  } = props;

  return (
    <div className={`${genericStyles.genericBox} ${layoutStyles.col}`}>
      <div className={layoutStyles.col_cell}>
        <AreaChart
          areaChartData={areaChartData}
          handleEditDateChange={handleEditDateChange}
          startDate={startDate}
          endDate={endDate}
          width={width}
          height={height}
        />
      </div>
      <div className={layoutStyles.col_cell}>
        <AreaChartPanel
          startDate={startDate}
          endDate={endDate}
          handleStartDateChange={handleStartDateChange}
          handleEndDateChange={handleEndDateChange}
        />
      </div>
    </div>
  );
};

export { AreaChartWithPanel };
